export const BRODCAST_OFFLINESCREEN_CHANNEL_NAME = "offlineScreen";

export type TStrikeType = null | 'HI' |'H' | 'I' | 'M' | 'K' |  'HH' | 'cancel';

export type TStrike = {
    strike: TStrikeType,
    circle: boolean,
    number: number | null
}
export const roundStatusList = ['ikiwake', 'rightwin', 'leftwin' , 'none'] as const;
export type TRoundStatus = 'ikiwake' | 'rightwin' | 'leftwin' | 'none';

export interface TOfflineRound {
    fighter1: string;
    fighter2: string;
    fighter1Strikes: Array<TStrike>,
    fighter2Strikes: Array<TStrike>,
    roundStatus?: TRoundStatus
}

export interface TOfflineScreen {
    shortname: string,
    description: string,
    rounds: Array<TOfflineRound>,
    backUrl?: string,
    backUrlScreen?: string,
    action?: 'GO_BACK',
    whiteFirst?: boolean,
    screenRatio: number, //100 based
    scoreKeeperRatio: number //100 based
}

export function getStoredData() {
    let stored = localStorage.getItem("offlineScreenData");
    let data: TOfflineScreen = Object.assign(
        {
            shortname: "A",
            rounds: [],
            description: "",
            screenRatio: 100,
            scoreKeeperRatio: 100
        }
        , stored ? JSON.parse(stored) as TOfflineScreen : {});
    return data;
}

export const ratioList = [-30, -20, -10, 0, 10, 20, 30];

export function storeData(data: TOfflineScreen) {
    localStorage.setItem("offlineScreenData", JSON.stringify(data));
}