import { Cancel, Delete, EmojiEvents, Mail, People, Redo, Undo } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PolylineIcon from '@mui/icons-material/Polyline';
import { Button, ButtonGroup, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { patchCompetition, patchCompetitionsStatus, updateCompetitionStatus } from '../../Backend/ApiCompetition';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { ConfirmModal } from '../../components/Modal/ConfirmModal';
import { deleteApiObject, getApiObject } from '../../components/Operation/GenericOperation';
import { useAnnouncementDialog } from '../Announcement/announcement';
import { IEventNotification } from '../Util/ErrorMessage';
import { TEventStatus, eventStatusList } from './EventStatus';

export type TCreateCompetition = (params: false | {
    type: "competition" | "group",
    parentId?: number
}) => void;
const handlePreviousClick = (event: any, t: (message: string) => string, onConfirm: () => void) => {
    event.stopPropagation();
    const options = {
        title: t('message.confirm.previousTitle'),
        message: t('message.confirm.previousMessage'),
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    onConfirm();
                }
            },
            {
                label: 'No',
                onClick: () => {

                }
            }
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypressEscape: () => { },
        overlayClassName: "overlay-custom-class-name"
    };
    confirmAlert(options);
};

const handleConfirmClick = (event: any, message: string, onConfirm: () => void) => {
    event.stopPropagation();
    const options = {
        message: message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    onConfirm();
                }
            },
            {
                label: 'No',
                onClick: () => {

                }
            }
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypressEscape: () => { },
        overlayClassName: "overlay-custom-class-name"
    };
    confirmAlert(options);
};

export function EventProcessGroup(props: {
    competition: IApiCompetitionModel,
    setCreateCompetition: TCreateCompetition,
    currentStatus: TEventStatus,
    event: IApiCompetitionModel,
    onChange: () => void,
    dispatchNotification: React.Dispatch<IEventNotification>,
    small: boolean
}) {
    const { t, i18n } = useTranslation();
    return <>
        <Button color="secondary" variant="contained" size="small" onClick={(e) => {
            props.setCreateCompetition({
                type: "competition",
                parentId: props.competition.id
            })
            e.stopPropagation();
        }}>{t("action.group.addCompetition")}</Button>
        {/* <Button color="secondary" variant="contained" size="small" onClick={(e) => {
            alert("not implemented yet")
        }}>{t("action.group.addGrade")}</Button> */}
    </>
}

export function EventProcess(props: {
    currentStatus: TEventStatus,
    event: IApiCompetitionModel,
    onChange: () => void,
    dispatchNotification: React.Dispatch<IEventNotification>,
    small: boolean
}) {
    const { t, i18n } = useTranslation();
    const [setOpen, annoncementDialog] = useAnnouncementDialog({ competition: props.event });

    let link: Array<{
        link: string,
        label: string,
        icon: JSX.Element
    }> = [];

    let nextLabel: string | undefined = t('action.event.next');
    let previousLabel: string | undefined = t('action.event.previous');
    let confirmMessage: string | undefined = undefined;
    let nextErrorAction: undefined | (() => void)  = undefined;
    let nextSuccessAction: undefined | (() => void) = undefined;
    switch (props.currentStatus) {
        case "Created":
            previousLabel = undefined;
            nextSuccessAction = () => confirmAlert({
                message: `Les inscriptions sont ouvertes à cette adresse : ${(window as any)['INSCRIPTION_UI_URL'] + '/?competition=' + props.event.id}`,
                buttons: [
                    {
                        label: 'Ok'
                    }]
            });
            nextLabel = t('action.event.openRegistration');
            break;
        case "RegistrationOpen":
            link.push({
                //http://service-inscription-ui.cnkdrdev.kendofrance.fr/?competition=-1
                link: (window as any)['INSCRIPTION_UI_URL'] + '/?competition=' + props.event.id,
                label: 'Inscriptions',
                icon: <HistoryEduIcon />
            });
            link.push({
                link: "/teams?eventId=" + props.event.id,//(window as any)['COMBATTANTS_UI_URL'] + '/?event=' + props.event.id,
                label: 'Fighters',
                icon: <People />
            });

            previousLabel = t('action.event.cancelRegistration');
            nextLabel = t('action.event.closeRegistration');
            break;
        case "RegistrationClosed":
            link.push({
                link: "/teams?eventId=" + props.event.id,//(window as any)['COMBATTANTS_UI_URL'] + '/?event=' + props.event.id,
                label: 'Fighters',
                icon: <People />
            });
            link.push({
                //link: (window as any)['TIRAGES_UI_URL'] + `/index.php?action=${props.event.rules === 'DirectDrawing' ? 'gettableau' : 'getpoule'}&id=` + props.event.id,
                link: "/drawing?eventId=" + props.event.id,
                label: t('action.event.createDraw'),
                icon: <AccountTreeIcon />
            });
            /*
            link.push({
                //link: (window as any)['TIRAGES_UI_URL'] + `/index.php?action=validetirage&id=` + props.event.id,
                link: "/drawing",
                label: "Valid tirage",
                icon: <PlaylistAddCheckIcon />
            });*/

            previousLabel = t('action.event.reopenRegistration');
            nextLabel = undefined;
            break;
        case "DrawingsDone":
            link.push({
                link: "/teams?eventId=" + props.event.id,/*(window as any)['COMBATTANTS_UI_URL'] + '/?event=' + props.event.id*/
                label: 'Fighters',
                icon: <HistoryEduIcon />
            });
            link.push({
                link: "/drawing?eventId=" + props.event.id,//link: (window as any)['TIRAGES_UI_URL'] + `/index.php?action=${props.event.rules === 'DirectDrawing' ? 'gettableau' : 'getpoule'}&id=` + props.event.id,
                label: "Edit Tirage",
                icon: <AccountTreeIcon />
            });


            previousLabel = t('action.event.cancelDrawing');
            nextLabel = t('action.event.publishDrawing');
            const options = {
                title: t('message.title.errorDisablePublish'),
                message: t('message.confirm.errorDisablePublish'),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            patchCompetition(props.dispatchNotification, {
                                id: props.event.id,
                                publish: 0
                            }).then((r) => {
                               if (r) {
                                nextAction(undefined);
                               }
                            })
                            
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
        
                        }
                    }
                ],
                childrenElement: () => <div />,
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => { },
                afterClose: () => { },
                onClickOutside: () => { },
                onKeypressEscape: () => { },
                overlayClassName: "overlay-custom-class-name"
            };
            nextErrorAction= (() => confirmAlert(options));
            break;
        case "DrawingsPublished":

            /*link.push({
                link: (window as any)['TABLES_UI_URL'],
                label: "Tables",
                icon: <FlagIcon />
            });*/
            link.push({
                link: "/teams?eventId=" + props.event.id,/*(window as any)['COMBATTANTS_UI_URL'] + '/?event=' + props.event.id*/
                label: 'Fighters',
                icon: <HistoryEduIcon />
            });
            link.push({
                link: "/drawing?eventId=" + props.event.id,//link: (window as any)['TIRAGES_UI_URL'] + `/index.php?action=${props.event.rules === 'DirectDrawing' ? 'gettableau' : 'getpoule'}&id=` + props.event.id,
                label: "Edit Tirage",
                icon: <AccountTreeIcon />
            });
            nextLabel = t("action.event.run");
            confirmMessage = t("message.confirm.beforeRun")
            break;
        case "Ongoing":
            /*link.push({
                link: (window as any)['TABLES_UI_URL'],
                label: 'Tables',
                icon: <FlagIcon />
            });*/
            link.push({
                link: "/tree?eventId=" + props.event.id,
                label: "Saisie des résultats",
                icon: <EmojiEvents />
            });
            nextLabel = undefined;//
            previousLabel = t('action.event.cancelRun');
            break;
        case "Finished":
            previousLabel = t('action.event.cancelFinish');
            nextLabel = undefined;//
            break;
    }

    const nextAction = (e: any) => {
        let action = () => {
            updateCompetitionStatus(props.dispatchNotification, props.event.id, eventStatusList[eventStatusList.findIndex(v => v === props.currentStatus) + 1])
                .then(
                    (r) => {
                        if (r) {
                            if (nextSuccessAction) nextSuccessAction();
                        }
                        else {
                            if (nextErrorAction) nextErrorAction();
                        }
                        props.onChange()
                    });
        };
        if (confirmMessage) {
            handleConfirmClick(e, confirmMessage, () => {
                action();

            });
        }
        else {
            action();
        }
    };

    const openDraw = <Tooltip title={t("action.competition.openDraw")}>
        <PolylineIcon
            color="primary"
            onClick={() => {
                document.location.href = `${(window as any)['REACT_APP_UI_DRAW_URL']}index.php?action=getpoule&id=${props.event.id}`;
            }}
        />
    </Tooltip>;

    return <>
        {annoncementDialog}
        <ButtonGroup>


            {(props.event.status === 'Created') && <>

            </>}
            {(props.event.status === 'RegistrationClosed') && <>

            </>}
            {/* {(props.event.status === 'RegistrationClosed') && <>
                <Tooltip title={t("action.fighter.open")}>
                    <Link href={`/teams`}>
                        <HistoryEduIcon />
                    </Link>
                </Tooltip>
                <Tooltip title={t("action. props.event.createDraw")}>
                    <Link href={`${(window as any)['REACT_APP_UI_DRAW_URL']}index.php?action=maketirage&id=${props.event.id}`}>
                        <AccountTreeIcon />
                    </Link>
                </Tooltip>

            </>} */}
            {/* {(props.event.status === 'DrawingsDone') && <>
                {openDraw}
                <Tooltip title={t("action.competition.cancelDraw")}>
                    <Link href={`${(window as any)['REACT_APP_UI_DRAW_URL']}index.php?action=invalidetirage&id=${props.event.id}`}>
                        <ShapeLineIcon
                            color="warning"

                        />
                    </Link>
                </Tooltip>

                <Tooltip color="success" title={t('action.competition.publish')}>
                    <MarkEmailReadIcon
                        onClick={() => {
                            ////setShowAction(false);(false);
                            patchCompetitionsStatus(props.dispatchNotification, props.event.id, "Next").then((res: any) => {
                                //force reload
                                props.onChange();
                                ////setShowAction(false);(true);
                            })
                        }}
                    />
                </Tooltip>
            </>} */}
            {/* {(props.event.status === 'DrawingsPublished') && <>
                {openDraw}
                <Tooltip title={t("action.competition.cancelDraw")}>
                    <Link href={`${(window as any)['REACT_APP_UI_DRAW_URL']}index.php?action=invalidetirage&id=${props.event.id}`}>
                        <ShapeLineIcon
                            color="warning"
                        />
                    </Link>
                </Tooltip>
                <Tooltip title={t("action.competition.cancelPublish")}>
                    <UnsubscribeIcon
                        color="warning"
                        onClick={() => {
                            //setShowAction(false);(false);
                            patchCompetitionsStatus(props.dispatchNotification, props.event.id, "DrawingsDone").then((res: any) => {
                                //force reload
                                props.onChange();
                                //setShowAction(false);(true);
                            })
                        }
                        }
                    />
                </Tooltip>
                <Tooltip color="success" title={t('action.competition.rePublishDraw')}>
                    <MarkEmailReadIcon
                        onClick={() => {
                            //setShowAction(false);(false);
                            postApiObject<IApiResultStatus>("/publishTirage",
                                {
                                    "idCompetition": props.event.id,
                                    "format": "P"
                                }, {
                                succeed: false,
                                status: 400
                            }, props.dispatchNotification, (window as any)['REACT_APP_API_DRAW_SERVICE']).then(e => {
                                //setShowAction(false);(true);
                            });
                        }}
                    />
                </Tooltip>
                <Tooltip title={t('action.competition.launch')}>
                    <FlagIcon
                        color="success"
                        onClick={() => {
                            //setShowAction(false);(false);
                            patchCompetitionsStatus(props.dispatchNotification, props.event.id, "Next").then((res: any) => {
                                //force reload
                                props.onChange();
                                //setShowAction(false);(true);
                            })
                        }
                        }
                    />
                </Tooltip>
            </>
            } */}
            

            {previousLabel && <>
                {props.small && <Tooltip title={previousLabel}>
                    <IconButton color="primary" aria-label={previousLabel}
                        onClick={(e) => {
                            handlePreviousClick(e, t, () => {
                                updateCompetitionStatus(props.dispatchNotification, props.event.id, eventStatusList[eventStatusList.findIndex(v => v === props.currentStatus) - 1]).then(() => props.onChange())
                            });
                        }}
                    >
                        <Undo />
                    </IconButton>
                </Tooltip>}
                {props.small === false &&
                    <Button color="warning" variant="contained" size="small" onClick={(e) => {
                        handlePreviousClick(e, t, () => {
                            updateCompetitionStatus(props.dispatchNotification, props.event.id, eventStatusList[eventStatusList.findIndex(v => v === props.currentStatus) - 1]).then(() => props.onChange())
                        });
                    }}>{previousLabel}</Button>
                }
            </>}

            {link.map((link, key) => {
                if (props.small) {
                    return <Tooltip key={key} title={link.label}>
                        <IconButton key={link.link} color="primary" aria-label={link.label}
                            href={link.link}
                        >
                            {link.icon}
                        </IconButton>
                    </Tooltip>
                }
                else {
                    return <React.Fragment key={link.link} >
                        <Button color="success" href={link.link} variant="contained" size="small">{link.label}</Button>
                    </React.Fragment>
                }
            })}
            {nextLabel && <>
                {props.small && <Tooltip title={nextLabel}>
                    <IconButton color="primary" aria-label={nextLabel}
                        onClick={(e) => {
                            nextAction(e)
                        }}
                    >
                        <Redo />
                    </IconButton>
                </Tooltip>
                }
                {props.small === false && <Button color="primary" variant="contained" size="small" onClick={(e) => {
                    nextAction(e);
                }}>{nextLabel}
                </Button>}
            </>}<br />
            {(props.event.status === "Ongoing") && <>
                {props.small && <Tooltip title={t("action.competition.resolveall")}>
                    <HowToRegIcon style={{ marginTop: '6px' }} onClick={() => {
                        //setShowAction(false);(false);
                        getApiObject<{ succeed: boolean }>(`/fightgroups/${props.event.id}/resolveall`, null, {
                            succeed: false
                        }, props.dispatchNotification).then((res: { succeed: boolean }) => {
                            //setShowAction(false);(true);
                        });
                    }} />
                </Tooltip>}

                <ConfirmModal
                    label={props.small ? <Tooltip title={t("action.competition.cancel")}><Cancel style={{ marginTop: '6px' }} color='error' /></Tooltip> : t("action.competition.cancel")}
                    message={t("confirmation.competition.cancel")} action={() => {
                        //setShowAction(false);(false);

                        patchCompetitionsStatus(props.dispatchNotification, props.event.id, "DrawingsPublished").then((res: any) => {
                            //force reload
                            props.onChange();
                            //setShowAction(false);(true);
                        })
                    }} />
                <ConfirmModal

                    label={props.small ? <Tooltip title={t("action.shiaijoService.clearCompetition")}><Delete style={{ marginTop: '6px' }} color='error' /></Tooltip> : t("action.shiaijoService.clearCompetition")}
                    message={t("confirmation.shiaijoService.clearCompetition")} action={() => {
                        //setShowAction(false);(false);
                        return deleteApiObject<{ succeed: boolean }>(`/competitions`, props.event.id, {
                            succeed: false
                        }, props.dispatchNotification).then((res: { succeed: boolean }) => {
                            props.onChange();
                            //setShowAction(false);(true);
                        });
                    }} />
            </>
            }
            {props.small && <Tooltip title={t("action.announcement.announcement")}>
                <IconButton color="primary" aria-label={t("action.announcement.announcement")}
                    onClick={(e) => {
                        setOpen(true)
                    }}
                >
                    <Mail />
                </IconButton>
            </Tooltip>}
            {props.small === false && <Button color="primary" variant="contained" size="small" onClick={(e) => {
                setOpen(true)
            }}>{t("action.announcement.announcement")}</Button>}
        </ButtonGroup>
    </>

    // return <>

}

export function renderEventProcessCell(
    setCreateCompetition: TCreateCompetition
    , dispatchNotification: React.Dispatch<IEventNotification>
    , onChange: () => void

    , small: boolean = true
    , t: (value: string) => string
): (params: any) => JSX.Element {

    let r = (params: any) => {
        if (params.row.type === "group") {
            return <EventProcessGroup
                competition={params.row}
                setCreateCompetition={setCreateCompetition}
                currentStatus={params.value}
                event={params.row}
                dispatchNotification={dispatchNotification}
                onChange={onChange}
                small={small}
            />
        }
        else {
            const showAction = true;
            const competition = params.row;




            return <EventProcess
                currentStatus={params.value}
                event={params.row}
                dispatchNotification={dispatchNotification}
                onChange={onChange}
                small={small}
            />;


        }
    };
    return r;
}