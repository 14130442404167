import { deleteApiObject, getApiObject, patchApiObject, postApiObject } from "../components/Operation/GenericOperation";
import { IApiFighterFighter, nonFighterFighter, nonFighterFightTeam } from "../components/Team/LazyFightTeam";
import { IServiceTableNotification } from "../components/Util/ErrorMessage";
import { TMartialArt } from "../Model/ApiCompetitionModel";
import { IApiLicencieModel } from "../Model/ApiLicencieModel";
import { IApiRegisterModel } from "../Model/ApiRegisterModel";
import { IApiFighterFightTeam, IApiFighterFightTeamCreate } from "../Model/ApiTeamModel";

/**
 * @Deprecated
 * @param dispatchNotification 
 * @param competitionId 
 * @param host 
 * @param token 
 * @returns 
 */
export async function getFightersTeams(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<Array<IApiFighterFightTeam>> {
    return postApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/team/list", { competition: competitionId }, {
        succeed: false,
        data: { data: "" },
        status: 400
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], token).then((result) => {
        if (result.succeed) {
            
            let ndata = JSON.parse(result.data.data) as Array<IApiFighterFightTeam>;
            for (const d of ndata) {
                //@ts-ignore erreur de format de l'api
                d.valide = d.valide === "1" ? 1 : 0;
            }
            return ndata;
        }
        else {
            return [];
        }

    });
}

export async function getFightersTeam(dispatchNotification: React.Dispatch<IServiceTableNotification>, teamId: number, host?: string, token?: string): Promise<IApiFighterFightTeam> {
    return getApiObject<{
        succeed: boolean,
        data: { data: string }
    }>("/team/read", teamId, {
        succeed: false,
        data: { data: JSON.stringify(nonFighterFightTeam) }
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], token).then((result) => {
        let ndata: IApiFighterFightTeam = nonFighterFightTeam;
        try {
            ndata = JSON.parse(result.data.data);
         }catch(e){
            console.log(e);
        }
        return ndata;
    });
}

export async function getFightersFighter(dispatchNotification: React.Dispatch<IServiceTableNotification>, fighterId: number, host?: string, token?: string): Promise<IApiFighterFighter> {
    return getApiObject<{
        succeed: boolean,
        data: { data: string }
    }>("/fighter/read", fighterId, {
        succeed: false,
        data: { data: JSON.stringify(nonFighterFighter) }
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], token).then((result) => {
        let ndata: IApiFighterFighter = nonFighterFighter;
        try {
            ndata = JSON.parse(result.data.data);
         }catch(e){
            console.log(e);
        }
        return ndata;
    });
}


export async function deleteFightersTeam(dispatchNotification: React.Dispatch<IServiceTableNotification>, teamId: number, host?: string, token?: string): Promise<boolean> {

    return deleteApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/team/delete", teamId,
        {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            return result.succeed;
        });
}

export async function deleteFightersFighter(dispatchNotification: React.Dispatch<IServiceTableNotification>, fighterId: number, host?: string, token?: string): Promise<boolean> {

    return deleteApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/fighter/delete", fighterId,
        {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            return result.succeed;
        });
}

export async function truncateFightersTeam(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<boolean> {

    return deleteApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/team/truncate", competitionId,
        {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            return result.succeed && result.status === 200;
        });
}

export async function truncateFightersFighter(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<boolean> {

    return deleteApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/fighter/truncate", competitionId,
        {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            return result.succeed && result.status === 200;
        });
}

export async function createFightersTeams(dispatchNotification: React.Dispatch<IServiceTableNotification>, team: IApiFighterFightTeamCreate, host?: string, token?: string): Promise<{ data: number }> {
    return postApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/team/create", team, {
        succeed: false,
        data: { data: "" },
        status: 400
    }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
        console.log(result);
        return { data: Number(result.data.data) };
    });
}

export async function updateFightersTeams(dispatchNotification: React.Dispatch<IServiceTableNotification>, team: IApiFighterFightTeam, host?: string, token?: string): Promise<boolean> {
    if (!(team.id && team.id > 0)) return false;
    return postApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/team/update/" + team.id
        , team
        , {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            console.log(result);
            return result.succeed;
        });
}

export async function updateFightersFighter(dispatchNotification: React.Dispatch<IServiceTableNotification>, fighter: IApiFighterFighter, host?: string, token?: string): Promise<boolean> {
    if (!(fighter.id && fighter.id > 0)) return false;
    return postApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/fighter/update/" + fighter.id
        , fighter
        , {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            console.log(result);
            return result.succeed;
        });
}

export async function renameFightersFighter(
    fighterExternalId: number,
    data: {firstname:string, lastname: string},
    dispatchNotification: React.Dispatch<IServiceTableNotification>, host?: string, token?: string): Promise<IApiFighterFighter| undefined> {
        return postApiObject<{
            succeed: boolean,
            data: { data: string |undefined },
            status: number
        }>("/fighter/rename/" + fighterExternalId
            , data
            , {
                succeed: false,
                data: {data: undefined},
                status: 400
            }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
                console.log(result);
                return result.succeed ? JSON.parse(result.data.data ?? "{}") : undefined;
            });
}

export async function getFightersFighters(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<Array<IApiFighterFighter>> {
    return postApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/fighter/list", { competition: competitionId }, {
        succeed: false,
        data: { data: "" },
        status: 400
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
        let ndata: Array<IApiFighterFighter> = [];
        try {
            ndata = JSON.parse(result.data.data);
         }catch(e){
            console.log(e);
        }
        for (const d of ndata) {
            //@ts-ignore erreur de format de l'api
            d.valide = d.valide === "1" ? 1 : 0;
        }
        return ndata;
        
    });
}

export async function getFightersTeamFighters(dispatchNotification: React.Dispatch<IServiceTableNotification>, teamId: number, host?: string, token?: string): Promise<Array<IApiFighterFighter>> {
    return getApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/team/contain", teamId, {
        succeed: false,
        data: { data: "" },
        status: 400
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
        let ndata: Array<IApiFighterFighter> = [];
        try {
            ndata = JSON.parse(result.data.data);
         }catch(e){
            console.log(e);
        }
        
        for (const d of ndata) {
            //@ts-ignore erreur de format de l'api
            d.valide = d.valide === "1" ? 1 : 0;
        }
        return ndata;
    });
}

export async function validFighter(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, fighterIds: Array<number>, valid: boolean, host?: string, token?: string)
    : Promise<boolean> {
    return patchApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>(`/fighter/valid`, {
        competition: competitionId,
        fighterIds: fighterIds,
        valid: valid
    },
        {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            return result.succeed;
        });
}

export async function validTeam(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, teamIds: Array<number>, valid: boolean, host?: string, token?: string)
    : Promise<boolean> {
    return patchApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>(`/team/valid`, {
        competition: competitionId,
        teamIds: teamIds,
        valid: valid
    },
        {
            succeed: false,
            data: { data: "" },
            status: 400
        }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
            return result.succeed;
        });
}


export async function deleteFightersClear(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<boolean> {
    return deleteApiObject<{
        succeed: boolean,
        data: any
    }>(`/competition/clear/${competitionId}`, null, {
        succeed: false,
        data: ""
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
        return result.succeed;
    });
}

export async function createFightersFighter(dispatchNotification: React.Dispatch<IServiceTableNotification>, fighter: IApiFighterFighter, host?: string, token?: string): Promise<{ data: number }> {
    return postApiObject<{
        succeed: boolean,
        data: { data: string },
        status: number
    }>("/fighter/create", fighter, {
        succeed: false,
        data: { data: "" },
        status: 400
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_COMBATTANTS_URL'], undefined, token).then((result) => {
        return { data: Number(result.data.data) };
    });
}

export interface ITeamWithFighters extends IApiFighterFightTeam {
    fighters: Array<IApiFighterFighter>;
}

export async function getTeamsWithFighters(competitionId: number, dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<Array<ITeamWithFighters>> {
    const tList = await getFightersTeams(dispatchNotification, competitionId);
    const teamsWithFighters = tList.map(t => { return { ...t, fighters: [] } as ITeamWithFighters });
    await getFightersFighters(dispatchNotification, competitionId).then(fList => {
        const cleaned = fList.map(f => {
            return {
                ...f,
                id: parseInt(f.id as any),
            }
        })
        for (const fighter of cleaned) {
            const team = teamsWithFighters.find(t => t.id === fighter.team);
            if (team) {
                team.fighters.push(fighter);
            }
        }
    })
    return teamsWithFighters;
}

export async function completeFromLicencie<T extends IApiFighterFighter>(dispatchNotification: React.Dispatch<IServiceTableNotification>, fighter: T, licence: string, discipline: TMartialArt, erase: boolean)
    : Promise<[T, boolean]> {
    let licencie = await getLicencieByNumAndDiscipline(licence, discipline, dispatchNotification);
    let fighterCompleted = { ...fighter };
    if (licencie) {
        if (fighterCompleted.firstname.length === 0 || erase) fighterCompleted.firstname = licencie.firstname;
        if (fighterCompleted.lastname.length === 0 || erase) fighterCompleted.lastname = licencie.lastname;
        if (fighterCompleted.birthdate.length === 0 || erase) fighterCompleted.birthdate = licencie.birthdate;
        if (fighterCompleted.club.length === 0 || erase) fighterCompleted.club = licencie.club;
        if ((fighterCompleted.region?.length ?? "") === 0 || erase) fighterCompleted.region = licencie.region;
        if ((fighterCompleted.nationality?.length ?? "") === 0 || erase) fighterCompleted.nationality = "FR";
        if (erase) fighterCompleted.sex = licencie.sexe === "M" ? 1 : 2;
        return [fighterCompleted, true];    
    }
    return [fighterCompleted, false];
}

export async function getLicencieByNumAndDiscipline(num: string, discipline: TMartialArt, dispatchNotification: React.Dispatch<IServiceTableNotification>, host?: string, token?: string)
    : Promise<IApiLicencieModel | undefined> {
    return getLicencieByNum(num, dispatchNotification, host, token).then(disList => {
        return disList ? disList.find(l => l.discipline.toLowerCase() === discipline.toLowerCase()) : undefined;
    });
}

export async function getLicencieByNum(num: string, dispatchNotification: React.Dispatch<IServiceTableNotification>, host?: string, token?: string): Promise<Array<IApiLicencieModel> | undefined> {
    return getApiObject<{
        succeed: boolean,
        data: Object | undefined,
    }
    >("/getLicencie/" + num, null, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_LICENCE_API_URL'], undefined, token).then((result) => {
        return Object.values(result.data ?? {});
    });
}


export async function getRegistered(
    competitionId: number,
    forExport: boolean,//csv export,
    group: boolean,//export registration of the group
    dispatchNotification: React.Dispatch<IServiceTableNotification>, host?: string, token?: string): Promise<Array<IApiRegisterModel> | undefined> {
    return getApiObject<{
        succeed: boolean,
        data: Array<IApiRegisterModel> | undefined,
    }
    >(`/event/${competitionId}${forExport ? '/export' : '/inscriptions'}${group ? '/group' : ''}`, null, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_INSCRIPTION_API_URL'], undefined, token).then((result) => {
        return result.data;
    });
}

export async function getRegisteredNotFighter(
    competitionId: number,
    forExport: boolean,//csv export,
    group: boolean,//export registration of the group
    dispatchNotification: React.Dispatch<IServiceTableNotification>, host?: string, token?: string): Promise<Array<IApiRegisterModel> | undefined> {
    return getApiObject<{
        succeed: boolean,
        data: Array<IApiRegisterModel> | undefined,
    }
    >(`/event/${competitionId}/exportNotFighter`, null, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_INSCRIPTION_API_URL'], undefined, token).then((result) => {
        return result.data;
    });
}

