import { Button, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { now } from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import { getFightersTeam } from '../../Backend/ApiFighter';
import { emptyCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiFighterFightTeam } from '../../Model/ApiTeamModel';
import styles from '../../css/mui.module.css';
import { TResult, deleteResult, deleteResults, patchCompetitionsStatus, postResult, resultList } from '../../util/ApiCompetition';
import { getFollowingCompetitionStatus, getTreeRank } from '../../util/ApiFollowing';
import { ERoundEventTypeModel } from '../../util/ApiModel/ApiEventModel';
import { useCompetitionLoader } from '../Competition/CompetitionLoader';
import { getFightgroupsData } from '../Operation/FightGroupOperation';
import { getApiObject, patchApiObject } from '../Operation/GenericOperation';
import { useSelectFighterOrTeam } from '../Summary/useSelectFighterOrTeam';
import { IApiShiajoScoredResult } from '../Table/SummaryFightGroupTable';
import { CompetitionTreeSvg, treeSvgEvent } from '../Tree/CompetitionTreeSvg';
import { ICompetitionEventsResult } from '../Tree/TreeGroupSvg';
import { useCompResult } from '../Use/UseCompResult';
import { useSelectCompetition } from '../Use/UseSelectCompetition';
import { useSseReport } from '../Use/UseSseReport';
import { getConfigurationSelection } from '../Util/Configuration';
import { useErrorMessage } from '../Util/ErrorMessage';
import { ICompetitionArchiveResult } from './MainTable';

const CSVDownload = (props: any) => {
    const btnRef = useRef(null)
    const [id, setId] = useState(0);

    useEffect(() => {
        
        if (btnRef && id !== props.id){
            //@ts-ignore
            btnRef.current?.click();
            setId(props.id);
        }
    }, [btnRef, id, props.id])


    return (
        <CSVLink {...props}>
            <span ref={btnRef} />
        </CSVLink>
    )
}

const emptyArchiveCompetition = {
    competitionId: -1,
    fightGroupTotal: -1,
    fightGroupsArchived: -1,
    fightGroupeArchiveLaunched: -1,
    fightGroupArchiveRemoteSync: -1,
    completed: false,
    loaded: false,
    competition: emptyCompetitionModel
    //interval: undefined
}

const JSONPrettyMon = require('react-json-pretty/dist/monikai');

const exportData = (data: Object) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";

    link.click();
};


interface IExportFightsModel {
    fight: string,
    date: string,
    fighter: string,
    strike: string,
    opponent: string
}


const rowsToExport = (data: IApiShiajoScoredResult, events: ICompetitionEventsResult): Array<IExportFightsModel> => {
    const res: Array<IExportFightsModel> =
        data.data.map(fg => {
            return events.data.fightgroups.filter(fg2 => fg.fightGroupNodeId === fg2.fightGroupNodeId)?.map(efg => {
                return efg.events
                    .filter(e => (treeSvgEvent(e.type) || e.type === ERoundEventTypeModel.HANSOKU) && e.fighter)
                    .map(e => {
                        let fight = fg.fights.find(f => f.id === e.fight);
                        if (fight) {
                            let fighter = [...fight.redFightTeam.fighters, ...fight.whiteFightTeam.fighters].find(fi => fi.id === e.fighter as number);
                            let opponent = [...fight.redFightTeam.fighters, ...fight.whiteFightTeam.fighters].find(fi => fi.id === e.opponent as number);
                            let date = new Date(e.date.toString());
                            if (fighter) {
                                return {
                                    fight: fg.label,
                                    date: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                                    fighter: fighter?.firstName + ' ' + fighter?.lastName + ' ' + fighter?.nationality,
                                    strike: e.type,
                                    opponent: opponent ? opponent.firstName + ' ' + opponent.lastName + ' ' + opponent?.nationality : ''
                                } as IExportFightsModel
                            }
                        }
                        return null;
                    })
                    .filter(line => line !== null);
                // .sort((r1:, r2) => {
                //     if (r1?.fight !== r2?.fight){
                //         return r1.date > r2.date ? 1 : (r1.date < r2.date ? -1 : 0)
                //     }
                //     else {
                //         return r1.date > r2.date ? 1 : (r1.date < r2.date ? -1 : 0)
                //     }
                // })
            })


        }).reduce((previous, current) => {
            for (const iterator of current) {
                for (const iterator2 of iterator) {
                    if (iterator2) previous.push(iterator2);
                }
            }
            return previous;
        }, [] as Array<IExportFightsModel>) as Array<IExportFightsModel>;
    return res;
    /*return Object.values(repartition).map((r: IApiDrawingRepartition) => {
        return {
            numero: r.numero,
            position: r.position,
            combattant: r.idFighter,
            name: r.name,
            club: r.club,
            nationality: r.nationality
        }
    });*/
};


export function CompetitionEnd(props: {

}) {

    const { t, i18n } = useTranslation();
    const [dispatchNotification, errorComponent] = useErrorMessage();
    const [selectCompetition, competitionSummaryId, fightFinished] = useSelectCompetition({ dispatchNotification: dispatchNotification });
    const [compResults, loadResults] = useCompResult({
        competitionId: competitionSummaryId,
        dispatchNotification: dispatchNotification
    });
    const [selectedResult, setSelectedResult] = useState<undefined | TResult>(undefined);
    const [followingRank, setFollowingRank] = useState<Array<IApiFighterFightTeam | undefined>>([]);
    const [archiveCompetition, setArchiveCompetition] = useState<ICompetitionArchiveResult>(emptyArchiveCompetition);
    const [selectedFighterOrTeam, selectedFighterOrTeamComp] = useSelectFighterOrTeam({
        competitionId: competitionSummaryId,
        dispatchNotification: dispatchNotification,
        allowTeam: selectedResult !== "fighting spirit",
        allowFighter: selectedResult === "fighting spirit",
    });
    const [repport, clearReport, setExpectedEventNumber] = useSseReport((window as any)['REACT_APP_API_SSE_NOTIFICATION'] + `/register/sse/fights/stream`, "", (data: string) => {
        try {
            let j = JSON.parse(data);
            let j2 = JSON.parse(j);
            return t('message.archive.fightSync') + j2.id;
        }
        catch (e) {
            return t("error.archive.convertFight")
        }
    });
    const [refreshParam, setRefreshParam] = useState(0);
    const [exportResult, setExportResult] = useState<string | any>(undefined);
    const [exportCsvResult, setExportCsvResult] = useState<{
        data: Array<IExportFightsModel>,
        id: number
    } | undefined>(undefined);

    const [competition, loadCompetitionId] = useCompetitionLoader(dispatchNotification, competitionSummaryId, true);

    useEffect(() => {
        loadCompetitionId(competitionSummaryId);
    }, [competitionSummaryId, loadCompetitionId])

    const competitionSvgParams = useMemo(() => {
        return {
            svgParam: {
                displayType: 1,
                displayLevel: false,
                displayIppons: false,
                displayPools: false,
                countryFlag: false,
                resizeHeight: false
            },
            svgTreeConf: {
                fullscreen: false,
                replaceFighter: true,
                replaceIppons: true,
                width: window.innerWidth / 2,
                showResult: true
            }
        }
    }, [refreshParam]);



    useEffect(() => {
        loadResults();
        if (competitionSummaryId !== undefined) {
            getFollowingCompetitionStatus(competitionSummaryId, dispatchNotification).then(status => {
                if (status === "COMPLETED") {
                    getTreeRank(competitionSummaryId, dispatchNotification).then(async (followingRankNumber) => {
                        let followingRankDraft: Array<IApiFighterFightTeam | undefined> = [];
                        for (let index = 0; index < 4; index++) {
                            if (followingRankNumber[index]) {
                                followingRankDraft[index] = await getFightersTeam(dispatchNotification, followingRankNumber[index]);
                            } else {
                                followingRankDraft[index] = undefined;
                            }
                        }
                        setFollowingRank(followingRankDraft);
                    });
                }
            })

        } else {
            setFollowingRank([]);
        }
    }, [competitionSummaryId, dispatchNotification, loadResults]);

    useEffect(() => {
        if (((!archiveCompetition.loaded) || archiveCompetition.competitionId !== competitionSummaryId) && competitionSummaryId && competitionSummaryId >= 0) {
            getApiObject<{ succeed: boolean, data: ICompetitionArchiveResult }>(`/competitions/${competitionSummaryId}/archive`, null, {
                succeed: false,
                data: {
                    competitionId: competitionSummaryId,
                    fightGroupTotal: -1,
                    fightGroupsArchived: -1,
                    fightGroupeArchiveLaunched: -1,
                    fightGroupArchiveRemoteSync: -1,
                    completed: false,
                    loaded: false,
                    competition: emptyCompetitionModel
                    //interval: undefined
                }
            }, dispatchNotification).then((res: { succeed: boolean, data: ICompetitionArchiveResult }) => {
                if (res.succeed) {
                    setTimeout(() => {
                        setArchiveCompetition(Object.assign({}, archiveCompetition, { competitionId: competitionSummaryId, loaded: false }));
                    }, 30000);
                    //if (archiveCompetition.interval) clearInterval(archiveCompetition.interval);
                    setArchiveCompetition(Object.assign(res.data, { competitionId: competitionSummaryId, loaded: true }));
                    if (res.data.fightGroupTotal > 0 && res.data.fightGroupsArchived === res.data.fightGroupTotal && res.data.competition.status === "Ongoing") {
                        patchCompetitionsStatus(dispatchNotification, res.data.competition.id, "Finished").then((res: any) => {

                        })
                    }
                }
            })
        }
    }, [archiveCompetition.loaded, competitionSummaryId, dispatchNotification, fightFinished])

    return <Paper>
        {errorComponent}
        {selectCompetition}

        <Grid container>
            <Grid container item xs={12} gap={1}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant='h4'>
                        Classement competition
                    </Typography>
                </Grid>
                <Paper elevation={3} className={styles.paperfullwidth}>
                    <Grid item xs={12}>
                        Resultats : <br />
                        {compResults.map((res, k) => {
                            return <React.Fragment key={k}>{res.fightTeam ? res.fightTeam.name : (res.fighter?.firstname + " " + res.fighter?.lastname)} : {res.result}
                                <Button variant='contained' color="error" onClick={() => {
                                    deleteResult(dispatchNotification, res.id).then(() => {
                                        loadResults();
                                        setRefreshParam(Date.now())//a corriger
                                    })
                                }}>Delete</Button><br /></React.Fragment>
                        })}
                    </Grid>
                </Paper>

                <Paper elevation={3} className={styles.paperfullwidth}>
                    <Grid container item xs={12} gap={1}>
                        <Grid item xs={12}>
                            Ajout de resultat
                        </Grid>
                        <Grid item xs={4}>
                            Resultat :<select value={selectedResult} onChange={(e) => setSelectedResult(e.target.value as (TResult | undefined))}>
                                <option value={undefined}></option>
                                {resultList.map((o, k) => {
                                    return <option key={o} value={o}>{o}</option>
                                })}
                            </select><br />


                            Equipe ou combattant :{selectedFighterOrTeamComp}
                            <Button variant='contained' color="success" onClick={() => {
                                console.log(selectedResult);
                                console.log(selectedFighterOrTeam);
                                if (selectedResult && selectedFighterOrTeam) {
                                    if (competitionSummaryId !== undefined) {
                                        postResult(dispatchNotification, competitionSummaryId
                                            , selectedResult ?? "first"
                                            , selectedFighterOrTeam.team?.id
                                            , selectedFighterOrTeam.fighter?.id).then(() => {
                                                loadResults();
                                                setRefreshParam(Date.now())//a corriger
                                            })
                                    }
                                    else {

                                    }
                                }
                            }}>Ajouter un résultat</Button>
                        </Grid>
                        <Grid md={3}>
                            <Typography variant='h4'>Classement calculé</Typography>
                            {followingRank.map((rank, k) => {
                                let level = k + 1;
                                if (level === 4) level = 3;
                                return <React.Fragment key={k}>{level} : {rank?.name ?? undefined}<br /></React.Fragment>
                            })}


                            <Button variant='contained' color="warning" onClick={() => {
                                const m = async () => {
                                    if (competitionSummaryId !== undefined) {
                                        await deleteResults(dispatchNotification, competitionSummaryId);
                                        for (let index = 0; index < Math.min(followingRank.length, 4); index++) {

                                            let rank: TResult = "first";
                                            switch (index) {
                                                case 0:
                                                    rank = "first";
                                                    break;
                                                case 1:
                                                    rank = "second";
                                                    break;
                                                case 2:
                                                case 3:
                                                    rank = "third";
                                                    break;
                                            }
                                            if (followingRank[index]) {
                                                await postResult(dispatchNotification, competitionSummaryId
                                                    , rank
                                                    , followingRank[index]?.id ?? 0
                                                    , undefined).then(() => {
                                                        loadResults();
                                                        setRefreshParam(Date.now())//a corriger;
                                                    })
                                            }
                                        }
                                    }
                                    await loadResults()
                                };
                                m();
                            }}>Ecraser avec le classement calculés</Button><br />

                            <Button variant="contained" color='error' onClick={() => {
                                if (competitionSummaryId !== undefined) {
                                    deleteResults(dispatchNotification, competitionSummaryId).then(() => {
                                        loadResults();
                                        setRefreshParam(Date.now())//a corriger
                                    })
                                }
                                else {

                                }
                            }}>Remise à 0 des résultats</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item container md={6} >
                <Paper elevation={3} className={styles.paperfullwidth}>
                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <Typography variant='h5'>
                            Archivage
                        </Typography>

                        <Typography variant='h4'>{t(`label.word.competition`)} {t(`label.competition.status.${archiveCompetition.competition.status.toLowerCase()}`)}</Typography>
                        {archiveCompetition.competition.status === 'Ongoing' && <Button variant='contained' color={fightFinished ? "primary" : "secondary"} disabled={
                            fightFinished !== true
                            && archiveCompetition.competition.status !== 'Ongoing'} onClick={() => {
                                if (fightFinished) {
                                    let force = false;
                                    clearReport();
                                    if (competitionSummaryId !== undefined) {
                                        patchApiObject<{ succeed: boolean, data: ICompetitionArchiveResult }>(
                                            `/competitions/${competitionSummaryId}/archive${force ? '?force=true' : ''}`, {}, {
                                            succeed: false,
                                            data: {
                                                competitionId: competitionSummaryId,
                                                fightGroupTotal: -1,
                                                fightGroupsArchived: -1,
                                                fightGroupeArchiveLaunched: -1,
                                                completed: false,
                                                loaded: false,
                                                // interval: undefined,
                                                fightGroupArchiveRemoteSync: -1,
                                                competition: emptyCompetitionModel
                                            }
                                        }, dispatchNotification).then((res: { succeed: boolean, data: ICompetitionArchiveResult }) => {
                                            if (res.succeed) {
                                                /*if (archiveCompetition.interval) clearInterval(archiveCompetition.interval);*/
                                                clearReport();
                                                setExpectedEventNumber(res.data.fightGroupeArchiveLaunched);
                                                setArchiveCompetition(Object.assign({ loaded: true }, res.data
                                        /*{, 
                                        interval: interval
                                    }*/));
                                            }
                                        })
                                    }
                                } else {
                                    dispatchNotification({
                                        operation: "update",
                                        type: "error", message: "error.fightgroup.stillUnfinishedFight"
                                    });
                                }

                            }}>{t("action.competition.close")}</Button>}


                    </Grid>

                    {
                        archiveCompetition.fightGroupTotal > 0 && <Grid md={4}>
                            {t('label.archive.fightGroupTotal')} : {archiveCompetition.fightGroupTotal}<br />
                            {t('label.archive.fightGroupeArchiveLaunched')} : {archiveCompetition.fightGroupeArchiveLaunched}<br />
                            {t('label.archive.fightGroupsArchived')} : {archiveCompetition.fightGroupsArchived}<br />
                            {fightFinished && archiveCompetition.fightGroupTotal > 0 &&
                                <><LinearProgress
                                    variant="determinate"
                                    value={100 * archiveCompetition.fightGroupsArchived / archiveCompetition.fightGroupTotal}
                                />{`${archiveCompetition.fightGroupsArchived}/${archiveCompetition.fightGroupTotal}`}</>}
                        </Grid>
                    }
                    {
                        archiveCompetition.fightGroupTotal > 0 && <Grid md={6}>
                            {t("label.archive.remoteSync")}
                            {repport}
                        </Grid>
                    }
                </Paper>
            </Grid>

            <Grid container item md={6}>
                <Paper elevation={3} className={styles.paperfullwidth}>
                    <Grid item style={{ textAlign: 'center' }}>
                        <Typography variant='h5'>
                            Export
                        </Typography>
                    </Grid>
                    {competitionSummaryId && <Grid item>
                        <Button variant='contained' color={fightFinished ? "primary" : "secondary"} onClick={() => {
                            getApiObject<{ succeed: boolean, data: any }>(`/competitions/${competitionSummaryId}/export`, null, { succeed: false, data: null }
                                , dispatchNotification).then((res: { succeed: boolean, data: any }) => {
                                    setExportResult(res.data);
                                    exportData(res.data);
                                })
                        }}>
                            Export
                        </Button>
                        <Button variant='contained' color={fightFinished ? "primary" : "secondary"} onClick={() => {
                            getFightgroupsData(competitionSummaryId, dispatchNotification, true)
                                .then((fightgroups) => {
                                    if (fightgroups.succeed) {
                                        getApiObject<ICompetitionEventsResult>(`/competitions/${competitionSummaryId}/events`, null, {
                                            succeed: false,
                                            data: {
                                                fightgroups: []
                                            }
                                        }, dispatchNotification).then(events => {
                                            if (events.succeed) {
                                                setExportCsvResult(
                                                    {
                                                        data: rowsToExport(fightgroups, events),
                                                        id: now()
                                                    });
                                            }
                                        })
                                    }

                                })

                        }}>
                            Export Csv
                        </Button>
                        {exportCsvResult && <CSVDownload
                            filename={`${competition?.fullname}.csv`}
                            separator=';'
                            onLoaded={() => {
                                /*setExportCsvResult(undefined);*/
                            }}
                            id={exportCsvResult.id}
                            data={exportCsvResult.data}
                            target="_blank" />}
                    </Grid>}
                    <Grid item>
                        {
                            exportResult && <Grid>
                                <JSONPretty id="json-pretty" data={exportResult} theme={JSONPrettyMon}></JSONPretty>
                            </Grid >
                        }
                    </Grid>
                </Paper>
            </Grid >
        </Grid >

        <Grid>
            <Grid>
                {competitionSummaryId && <CompetitionTreeSvg
                    competitionId={competitionSummaryId}
                    dispatchNotification={dispatchNotification}
                    svgParam={competitionSvgParams.svgParam}
                    svgTreeConf={competitionSvgParams.svgTreeConf}
                    showResult={true}
                    init={getConfigurationSelection()?.selectedCompetition}
                />}
            </Grid>
        </Grid>

    </Paper >
}