import { ArrowDropDown, ArrowRight, Remove } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, ButtonGroup, Checkbox, FormControlLabel, Grid, IconButton, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridComparatorFn, GridRenderCellParams, GridSortCellParams, GridSortItem } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import produce from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { deleteCompetition, getCompetitions } from '../../Backend/ApiCompetition';
import { renderEventProcessCell } from '../../EventComponent/Event/EventProcess';
import { IApiCompetitionModel, IServiceCompetitionAdvancedOrdonancementRules, IServiceCompetitionAdvancedTableRules } from '../../Model/ApiCompetitionModel';
import { getCompetitionsAdvancedTable } from '../../util/ApiCompetition';
import { IAuthentication } from '../Auth/auth';
import { useConfiguration } from '../Competition/CompetitionConfiguration';
import { useCompetitionModal } from '../Competition/CompetitionModal';
import { IConfiguration, storeConfiguration } from '../Util/Configuration';
import { useErrorMessage } from '../Util/ErrorMessage';
import { LoadingIndicator } from '../Util/LoadingIndicator';
import { useCompetitionEdit } from './CompetitionEdit';
import { CompetitionEditTable } from './EditTableCompetitions';
import { useCompetitionTable } from './SelectionTableCompetitions';

var debug = require('debug')('ServiceTablesMinimalFrontend:configuration')

type TGridSortItemReloadeable = {
    reload: number,
    sortItems: Array<GridSortItem>
}

export interface ITableCompetitionFilter {
    pastEvent: boolean,
    closedEvent: boolean,
    selectedGroup?: number
}


const theader = 'label.header.competition';
export function TableCompetitions(props: {
    authentication: IAuthentication
}
) {
    //sessionStorage.setItem("module", "competition");
    const { t, i18n } = useTranslation();
    const [dispatchNotification, errorComponent] = useErrorMessage();

    const [configuration, setConfiguration] = useConfiguration(/*{
        dispatchNotification: dispatchNotification,
        authentication: props.authentication
    }*/);//Competition configuration module

    //TABLE CONFIGURATION
    const [competitionFilter, setCompetitionFilter] = useState<ITableCompetitionFilter>({
        pastEvent: false,
        closedEvent: false,
        ...(JSON.parse(localStorage.getItem("competitionFilter") ?? "{}"))
    });//Competition Filter rules for the table display

    const [pageSize, setPageSize] = React.useState(parseInt(localStorage.getItem("pageSize") ?? "10")); //Table page size
    const [sortModel, setSortModel] = React.useState<TGridSortItemReloadeable>({
        reload: Date.now(),
        sortItems:
            [{
                field: 'id',
                sort: 'asc',
            }]
    }
    );//Table sort model
    const [competitionSelectionGridRows, setCompetitionSelectionGridRows] = useState<IApiCompetitionModel[]>([]);//Filtered selected competition list

    const [competitionList, setCompetitionList] = useState<IApiCompetitionModel[]>([]);

    const [displayOptions, setDisplayOptions] = useState({
        smallButtons: true,
        oldTables: false,
    });

    const competitionEdit = useCompetitionEdit
        (configuration, competitionFilter, dispatchNotification,
            (c) => {
                if (c) {
                    loadCompetitions(true);
                    setConfiguration(s => ({
                        ...s,
                        selectedCompetitionIds: s.selectedCompetitionIds.concat([c.id])
                    }))
                }
            },
            (c) => {
                if (c) {
                    setCompetitionList(s => produce(s, (draft) => {
                        const index = draft.findIndex(c2 => c2.id === c.id);
                        if (index >= 0) {
                            //@ts-ignore
                            draft[index] = {
                                ...draft[index],
                                ...c
                            } as IApiCompetitionModel;
                        }
                    }))
                }
                else {
                    //full reload
                    loadCompetitions(true);
                }
            }


            , props.authentication);// Edit modal



    const [competitionTable, competitionTableSelection] = useCompetitionTable({
        competitionList: competitionList,
        initSelection: configuration.selectedCompetitionIds
    });

    const [competitionModal, setCompetitionModalId] = useCompetitionModal(dispatchNotification);

    const loadCompetitions = useCallback((display: boolean) => {
        (async () => {
            let data = await getCompetitions(dispatchNotification, true
                , {
                    type: "competitionANDgroup",
                    fromDate: configuration.filter.dateFrom,
                    username: (props.authentication.scopes.includes("cnkdr_admin")
                        || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name
                }
            );
            const missings = configuration.selectedCompetitionIds.filter(c => !data.find(c2 => c2.id === c));
            if (missings.length > 0) {
                const data2 = await getCompetitions(dispatchNotification, true
                    , {
                        type: "competitionANDgroup",
                        username: (props.authentication.scopes.includes("cnkdr_admin")
                            || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name,
                        competitionIds: missings
                    });
                if (data2) {
                    data = data.concat(data2);
                }
            }
            if (display) {
                setCompetitionList(data);
            }
        })()
    }, [configuration.filter.dateFrom, dispatchNotification, props.authentication.name, props.authentication.scopes]);

    useEffect(() => {
        let display = true;
        loadCompetitions(display);
        return () => { display = false }
    }, [loadCompetitions])


    const defaultCompetitionFilter = configuration.filter;//Default filter
    /*
    if (selectedCompetiton.type === "group") {
                                    const childs = await getCompetitions(props.dispatchNotification, false, {
                                        parent: selectedCompetiton.id
                                    });
                                    for (const child of childs) {
                                        if (!newCompetitionList.find(u => u.id === child.id)) {
                                            newCompetitionList.push(child);
                                        }
                                    }
                                }
    */

    useEffect(() => {

        if (competitionTableSelection) {
            setConfiguration((c) => {
                const existingListHash = [...c.selectedCompetitionIds].sort().join(';');
                const tableSelectionList = competitionTableSelection;
                const newListHash = tableSelectionList?.selection.map(c => c.id).sort().join(';');
                if (existingListHash !== newListHash) {
                    //Configuration par défaut
                    const newSelection:Array<number> = [];
                    const selectionSource = [...c.selectedCompetitionIds];
                    //remove unselected elements
                    for (const [index, cid] of selectionSource.entries()) {
                        //if it's in selecteable list
                        if (tableSelectionList?.competitionGroupList.find(c => c.id === cid)) {
                            if (tableSelectionList?.selection.find(c2 => c2.id === cid)) {
                                newSelection.push(cid);
                            }
                        }
                    }

                    //add selected elements missing
                    for (const c of tableSelectionList?.selection) {
                        if (!newSelection.find(c2id => c2id === c.id)) {
                            newSelection.push(c.id)
                        }
                    }

                    return {
                        ...c,
                        selectedCompetitionIds: newSelection
                    }
                }
                else {
                    return c
                }
            });
            //clearNamedSelections();
            //storeSelection(newSelection);
            /*debug(JSON.stringify(newSelection));
            if (display) {
                competitionConfiguration.setSelection(newSelection);
            }*/

        }
    }, [competitionList, competitionTableSelection, setConfiguration])

    /*useEffect(() => {
        storeSelection({ selectedCompetition: configuration.selectedCompetitionIds });
    }, [configuration.selectedCompetitionIds])*/
    /*
    Reorganize selection rows with groups for tree
        useEffect(() => {
            let mounted = true;
            const filteredSelectedCompCall: () => Promise<IApiCompetitionModel[]> = async () => {
                return await (competitionConfiguration.competitionList ?? [])
                    .filter(c =>
                        competitionConfiguration.selection.selectedCompetition.find(c2 => c2.id === c.id)
                        || (c.parentId && competitionConfiguration.selection.selectedCompetition.find(c2 => c2.id === c.parentId))
                    )
                    .reduce(async (accp, c) => {
                        const acc = await accp;
                        acc.push(c);
                        if (c.type === "group") {
                            const childs = await getCompetitions(dispatchNotification, false, {
                                parent: c.id
                            });
                            for (const child of childs) {
                                if (!acc.find(u => u.id === child.id)) {
                                    acc.push(child);
                                }
                            }
                        }
                        return acc;
                    }, Promise.resolve([] as IApiCompetitionModel[])); //Selected competition list
            };
            filteredSelectedCompCall().then((filteredSelectedComp) => {
                if (mounted) {
                    setCompetitionSelectionGridRows(filteredSelectedComp);
                }
            })
            return () => {
                mounted = false;
            }
        }, [competitionConfiguration.competitionList, dispatchNotification, competitionConfiguration.selection]);*/

    useEffect(() => {
        setCompetitionSelectionGridRows(
            competitionList.filter(c => configuration.selectedCompetitionIds.includes(c.id)) ?? []
        );
    }, [competitionList, configuration.selectedCompetitionIds]);

    const filteredNotSelectedComp = (sort: boolean = false, selectedIfParentIs = true): IApiCompetitionModel[] => {
        if (competitionList) {

            const list = competitionList.filter(c => {
                let valid = true;

                if (configuration.selectedCompetitionIds.find(c2id => c2id === c.id)
                    || (c.parentId && configuration.selectedCompetitionIds.find(c2id => c2id === c.parentId))) {
                    valid = (!selectedIfParentIs);
                }
                else {

                    if (competitionFilter.selectedGroup) valid = valid && ((!c.parentId) || c.parentId === competitionFilter.selectedGroup)
                    else valid = valid && (!c.parentId)

                    valid = (defaultCompetitionFilter.date === "" || defaultCompetitionFilter.date === c.competitionDate)
                        && (defaultCompetitionFilter.place === "" || defaultCompetitionFilter.place === c.place)
                    /*&& (filter.dateFrom === undefined ||
                        Math.floor((new Date(c.competitionDate)).getTime() / (1000 * 3600 * 24)) >= Math.floor(new Date().getTime() / (1000 * 3600 * 24))
     
                    )*/
                }

                return valid;
            });
            if (sort) {
                return list.sort((c1, c2) => {
                    const c1f = configuration.selectedCompetitionIds.find(item => item === c1.id);
                    const c2f = configuration.selectedCompetitionIds.find(item => item === c2.id);
                    if ((c1f === undefined) === (c2f === undefined)) {
                        return c1.id - c2.id;
                    }
                    else return c1f ? -1 : 1;
                });
            } else {
                return list;
            }
        } else {
            return []
        }
    };

    /*const selectCompetition = async (competitionId: number, selected: boolean) => {
        if (competitionConfiguration.selection) {
            let newSelection = {
                ...competitionConfiguration.selection,
                //update selection 
                selectCompetition: filteredNotSelectedComp(false, false).filter(c => competitionConfiguration.selection.selectedCompetition.find(c2 => c2 === c.id))
            };
            let i = newSelection.selectedCompetition.findIndex(c => c === competitionId);
            debug(`Select is ${selected} for competition ${i}`)
            if (!selected) {
                if (i >= 0) {
                    debug(`Unselect competition ${i}`)
                    newSelection.selectedCompetition = newSelection.selectCompetition.filter(c => !(c.id === competitionId || c.parentId === competitionId)).map(c => c.id);
    
                }
            } else {
                let cs = competitionConfiguration.competitionList?.filter(c => c.id === competitionId || c.parentId === competitionId) ?? []
                cs = cs?.concat(competitionSelectionGridRows?.filter(c => (c.id === competitionId || c.parentId === competitionId) && !cs?.find(c2 => c2.id === c.id)))
                for (const c of cs) {
                    debug(`Select competition ${c.id}`)
                    const advancedTables = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedTableRules>(undefined, c.id, "ServicesTables");
                    c.advancedTables = advancedTables.current;
                    c.advancedTablesParent = advancedTables.parent;
                    const advancedOrdonancement = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedOrdonancementRules>(undefined, c.id, "ServiceOrdonancement")
                    c.advancedOrdonancement = advancedOrdonancement.current;
                    c.advancedOrdonancementParent = advancedOrdonancement.parent;
                    newSelection.selectedCompetition.push(c.id);
                }
            }
            clearNamedSelections();
            storeSelection(newSelection);
            debug(JSON.stringify(newSelection));
            competitionConfiguration.setSelection(newSelection);
        }
    };*/

    //id={`competition-text-${competition.id}-advanced-${configName}`}
    //placeholder={(competition.advanced && competition.advanced[configName]) ? "" + competition.advanced[configName] : "undefined"}

    //const competitionWidth = competitionConfiguration.competitionList ? competitionConfiguration.competitionList.reduce((e, c) => Math.min(500, Math.max(e, c.fullname?.length * 9)), 200) : 200;
    //const maxcompetitionWidth = 400;

    const childrenSortComparator: (field: keyof IApiCompetitionModel) => GridComparatorFn = (field: keyof IApiCompetitionModel) => {
        return (a: any, b: any, cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) => {

            const arow = cellParams1.api.getRow(cellParams1.id);
            const brow = cellParams2.api.getRow(cellParams2.id);

            //if parent is selected and not the otherone
            //let aparentselected = arow.parentId ? (selection.selectedCompetition.find(c => c.id === arow.parentId) ? 1 : 0) : 0;
            //let bparentselected = brow.parentId ?  (selection.selectedCompetition.find(c => c.id === brow.parentId) ? 1 : 0): 0;

            //if (arow.parentId && brow.parentId && aparentselected !== bparentselected) return bparentselected - aparentselected;

            const aselected = configuration.selectedCompetitionIds.find(c => c === arow.id || c === arow.parentId) ? 1 : 0;
            const bselected = configuration.selectedCompetitionIds.find(c => c === brow.id || c === brow.parentId) ? 1 : 0;
            if (aselected !== bselected) return bselected - aselected;
            let aparentId = cellParams1.id;
            let bparentId = cellParams2.id;
            let sort = cellParams1.api.getSortModel().find((s: any) => s.field === cellParams1.field)?.sort ?? 'asc';

            if (arow.parentId) {
                let aparent = cellParams1.api.getRow(arow.parentId);
                if (aparent) {

                    a = aparent[field];
                    aparentId = arow.parentId;
                    if (cellParams2.id === aparentId) {
                        return sort === 'desc' ? -1 : 1
                    }
                }
            }

            if (brow.parentId) {
                const bparent = cellParams2.api.getRow(brow.parentId);
                if (bparent) {
                    b = bparent[field];
                    bparentId = brow.parentId;
                    if (cellParams1.id === bparentId) {
                        return sort === 'desc' ? 1 : -1
                    }
                }
            }
            if (a === b) {
                a = aparentId;
                b = bparentId;
            }
            if (a === b) {
                a = cellParams1.id;
                b = cellParams2.id;
            }

            if (typeof a === "number") return a - b;
            else return a.localeCompare(b);
        }
    }


    const edit = async (competitionId: number) => {
        competitionEdit.createCompetition(false);
        const competition = competitionList.find(c => c.id === competitionId);
        if (competition?.type === "group") {
            setCompetitionFilter({ ...competitionFilter, selectedGroup: competition.id })
        }

        if (competition) {
            (async () => {
                const c = { ...competition };
                const advancedTables = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedTableRules>(undefined, c.id, "ServicesTables");
                c.advancedTables = advancedTables.current;
                c.advancedTablesParent = advancedTables.parent;
                const advancedOrdonancement = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedOrdonancementRules>(undefined, c.id, "ServiceOrdonancement")
                c.advancedOrdonancement = advancedOrdonancement.current;
                c.advancedOrdonancementParent = advancedOrdonancement.parent;


                competitionEdit.selectCompetition(c);

            })();
        }
    };

    const handleDeleteClick = async (competitionId: number, onDelete?: () => void) => {

        const options = {
            title: 'Competition delete confirmation',
            message: 'Do you confirm competition deletion ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let competitionIndex = competitionList?.findIndex(c => c.id === competitionId);
                        if (competitionIndex) {
                            deleteCompetition(dispatchNotification, competitionList[competitionIndex]).then((data) => {
                                const newList = [...competitionList].splice(competitionIndex, 1);
                                setCompetitionList(newList);
                                if (onDelete) onDelete();
                            })
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypressEscape: () => { },
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options);

    };

    const actionCell = (props: any) => {
        const { api, id } = props;



        return <div >
            <SettingsIcon onClick={() => edit(parseInt(id))}
            />
            <IconButton
                onClick={(event: any) => {
                    event.stopPropagation();
                    handleDeleteClick(parseInt(id), () => {
                        api.updateRows([{ id, _action: 'delete' }]);
                        loadCompetitions(true);
                    })
                }}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </div>

    };

    const columns: GridColDef[] = [
        {
            field: 'select', headerName: 'select', width: 100,
            renderCell: (c: GridRenderCellParams) => {
                const competition = c.row;
                const checked: number | undefined = configuration.selectedCompetitionIds
                    .find(item => item === competition.id);
                return <Checkbox
                    checked={checked ? true : false}
                    id={`competition-checkbox-${competition.id}`}
                    name={`competition-checkbox-${competition.id}`}
                    onChange={(e: any) => {
                        setConfiguration((conf: IConfiguration) => {
                            return produce(conf, draft => {
                                draft.selectedCompetitionIds.push(competition.id);
                            });
                        })
                    }
                    }
                />
            }
        },
        {
            field: 'id', headerName: 'id', width: 100
            , sortComparator: childrenSortComparator('id')
        },
        {
            field: '',
            headerName: '',
            renderCell: actionCell,
            sortable: false,
            width: 75,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true,

        },
        {
            field: 'type', minWidth: 150, editable: true
            , sortComparator: childrenSortComparator('type')
            , renderCell: (c) => {
                const competitionRow = c.row;
                const competition = configuration.selectedCompetitionIds.find(c => c === competitionRow.id);
                return <>To Redo</>
                /*const isAdvancedDefined = competition && (!!competition.advancedTables?.configurationDone);
                if (competition !== undefined) {
                    return <>
                        {competition['type']}
                        <Tooltip title={t('action.configuration.configurationRun')}>
                            <SettingsIcon onClick={() => {
    
                                if (competition) {
                                    //Configuration has been seen once
                                    if (!competition.advancedTables?.configurationDone) {
                                        competitionEdit.updateCompetitionTableAdvanced(competition.id, "configurationDone", "true");
                                    }
                                    competitionConfiguration.showCompetitionConfiguration(competition.id)
                                }
                            }}
                                color={isAdvancedDefined ? "primary" : "warning"}
                            />
                        </Tooltip>
                        {(!isAdvancedDefined) &&
                            <Tooltip title={t('action.configuration.configurationRunTodo')}>
                                <WarningIcon color="warning" />
                            </Tooltip>
                        }
                    </>
    
                }
                else {
                    return <Button onClick={() => {
                        selectCompetition(competitionRow.id, true);
                    }}>{competitionRow['type']}</Button>
                }
            */
            }
        },
        {
            field: 'fullname', minWidth: 200, editable: true,
            renderCell: (params: GridRenderCellParams) => {
                if (params.row.parentId) {
                    return <><Remove />{params.value}</>
                }
                else if (params.row.type === "group") {
                    if (competitionFilter.selectedGroup === params.row.id) {
                        return <><ArrowDropDown />{params.value}</>
                    }
                    else {
                        return <><ArrowRight />{params.value}</>

                    }
                }
                else {
                    return <>{params.value}</>
                }
            }
            , sortComparator: childrenSortComparator('fullname')
        },
        {
            field: 'category', minWidth: 150, editable: true
            , sortComparator: childrenSortComparator('category')
            , renderCell: (params: GridRenderCellParams) => {
                const competitionRow = params.row;
                return <>{params.value} - {competitionRow['teamSize'] > 1 ? 'team' : 'indiv'}</>
            }
        },
        {
            field: 'martialArt',
            minWidth: 150,
            sortComparator: childrenSortComparator('martialArt')
        },
        {
            field: 'place',
            minWidth: 150, editable: true
        },
        {
            field: 'status', minWidth: 150, editable: true,
            renderCell: (c: any) => {
                const competition = c.row;
                return <>{t(`label.competition.status.${competition.status.toLowerCase()}`)}</>
                // {t(`label.competition.status.${competition.status.toLowerCase()}`)}
            }
            , sortComparator: childrenSortComparator('status')
        },
        {
            field: 'competitionDate', minWidth: 150, editable: true
            , type: 'date'
            , sortComparator: childrenSortComparator('competitionDate')
        },
        {
            field: 'process1',
            headerName: t(`${theader}.process`),
            valueGetter: (params: any) => params.getValue(params.id, 'status'),
            minWidth: 600,
            editable: false,
            renderCell: renderEventProcessCell((c) => {
                competitionEdit.selectCompetition(undefined);
                competitionEdit.createCompetition(c);
            }, dispatchNotification
                , () => loadCompetitions(true), /*competitionWidth >= maxcompetitionWidth*/true, t)
            , sortable: false,
            flex: 1,
        }
    ];


    let competitionSelectedGrid = undefined;
    let competitionSelectionGrid = undefined;
    if (competitionList) {

        competitionSelectedGrid = <DataGrid
            sortModel={sortModel.sortItems}
            onSortModelChange={(model) => {
                if (JSON.stringify(model) !== JSON.stringify(sortModel.sortItems)) {
                    setSortModel({
                        reload: Date.now(),
                        sortItems: model
                    });
                    //setSelectedCompetition(undefined);
                    setCompetitionFilter({ ...competitionFilter, selectedGroup: undefined })
                }

            }}
            rows={competitionSelectionGridRows}
            columns={columns}
            onSelectionModelChange={(competitionIds: Array<string | number>) => {
                const competition = competitionSelectionGridRows.find(c => c.id === competitionIds[0]);
                if (competition?.type === "group") {
                    setCompetitionFilter({ ...competitionFilter, selectedGroup: competition.id })
                }
            }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            autoHeight
            pagination
        />;
        competitionSelectionGrid = <>
            <DataGrid
                sortModel={sortModel.sortItems}
                onSortModelChange={(model) => {
                    if (JSON.stringify(model) !== JSON.stringify(sortModel.sortItems)) {
                        setSortModel({
                            reload: Date.now(),
                            sortItems: model
                        });
                        setCompetitionFilter({ ...competitionFilter, selectedGroup: undefined })
                    }
                }}
                rows={filteredNotSelectedComp(true)/*competitions.filter(competitionShowFilter)*/}
                columns={columns}
                onSelectionModelChange={(competitionIds: Array<string | number>) => {
                    const competition = filteredNotSelectedComp(false).find(c => c.id === competitionIds[0]);
                    if (competition?.type === "group") {
                        setCompetitionFilter({ ...competitionFilter, selectedGroup: competition.id })
                    }
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 50]}
                autoHeight
                pagination
            />
        </>;
    }


    //////////////////////////////////////////////////////////////////////////////////


    const selectFilter = competitionList ? <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                // label={configuration.filter.dateFrom?.toLocaleDateString() ?? ''}
                value={configuration.filter.dateFrom ?? null}
                onChange={(newValue: Date | null) => {
                    setConfiguration({
                        ...configuration,
                        filter: {
                            ...defaultCompetitionFilter,
                            date: "",
                            place: "",
                            dateFrom: newValue ?? undefined
                        }
                    })
                }}
                renderInput={(params: any) => <TextField {...params} />
                }
                inputFormat="dd-MM-yyyy"
            />
        </LocalizationProvider>
        {defaultCompetitionFilter.dateFrom?.toDateString()}
        {(new Date()).toDateString()}
        <FormControlLabel
            control={<Checkbox
                checked={defaultCompetitionFilter.dateFrom === undefined
                    || defaultCompetitionFilter.dateFrom?.toDateString() !== (new Date()).toDateString() ? true : false}
                onChange={(e, checked) => {
                    //overwrite filter
                    setConfiguration({
                        ...configuration,
                        filter: {
                            ...defaultCompetitionFilter,
                            date: "",
                            place: "",
                            dateFrom: checked ? undefined : new Date()
                        }
                    })
                }}
            />} label={t("label.competition.past")}
        />

        <br />Place : &nbsp;
        <select onChange={(e) => {
            setConfiguration({
                ...configuration,
                //overwrite filter
                filter: {
                    ...defaultCompetitionFilter,
                    place: e.target.value
                }
            })
        }} value={defaultCompetitionFilter.place}>
            <option value={""}></option>
            {competitionList.map(c => c.place).filter((c, k, a) => a.indexOf(c) === k).map((place, key) => {
                return <option value={place} key={key} >{place}</option>
            })}
        </select>
        &nbsp;&nbsp;Date de compétition : &nbsp;
        <select onChange={(e) => {
            storeConfiguration(Object.assign(
                {}
                , configuration,
                //overwrite filter
                {
                    filter: {
                        ...defaultCompetitionFilter,
                        date: e.target.value
                    }
                }
            ));
            setConfiguration({
                ...configuration,
                //overwrite filter
                filter: {
                    ...defaultCompetitionFilter,
                    date: e.target.value
                }
            })
        }}
            value={defaultCompetitionFilter.date}>
            <option value={""} ></option>
            {competitionList.map(c => c.competitionDate).filter((c, k, a) => a.indexOf(c) === k).map((date, key) => {
                return <option value={date} key={key} >{date}</option>
            })}
        </select>
    </> : undefined;



    ///////////////////////////////////////////////////////////////////////////////

    return <>
        {/* EDIT OR CREATE MODAL */}
        {competitionEdit.modal}
        <Grid container spacing={2}>
            {competitionModal}
            <Grid item md={12}>
                {errorComponent}
            </Grid>
            <Grid item md={12}>
                <h2>Compétitions selectionnées</h2>
            </Grid>

            <Grid item md={12}>
                <h2>Competitions</h2>
            </Grid>
            <Grid item xs={6}>
                <ButtonGroup variant="contained">
                    <Button style={{ margin: '2px' }} onClick={() => {
                        competitionEdit.selectCompetition(undefined);
                        competitionEdit.createCompetition({
                            type: "group"
                        });
                    }}>
                        {t('action.event.createCompetitionGroup')}
                    </Button>

                    <Button style={{ margin: '2px' }} onClick={() => {
                        competitionEdit.selectCompetition(undefined);
                        competitionEdit.createCompetition({
                            type: "competition"
                        });
                    }}>
                        {t('action.event.createCompetition')}
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item md={12}>
                <ButtonGroup className="buttons" variant="contained" aria-label="outlined primary button group">
                    <Button className="button is-info" onClick={() => {
                        setConfiguration((conf) => {
                            return {
                                ...conf,
                                selectedCompetitionIds: filteredNotSelectedComp().map(c => c.id)
                            }
                        })
                    }}>Select All</Button>
                    <Button className="button is-info" onClick={() => {
                        setConfiguration((conf) => {
                            return {
                                ...conf,
                                selectedCompetitionIds: conf.selectedCompetitionIds.filter(c => filteredNotSelectedComp().map(c2 => c2.id).includes(c) === false)
                            }
                        })

                    }}>Unselect filtered</Button>
                    <Button className="button is-info" onClick={() => {
                        setConfiguration((conf) => {
                            return {
                                ...conf,
                                selectedCompetitionIds: []
                            }
                        })
                    }}>Unselect All</Button>
                </ButtonGroup>
                <Checkbox
                    checked={displayOptions.smallButtons}
                    onClick={() => setDisplayOptions(d => ({ ...d, smallButtons: !d.smallButtons }))}
                /> Affichage simple
                <Checkbox
                    checked={displayOptions.oldTables}
                    onClick={() => setDisplayOptions(d => ({ ...d, oldTables: !d.oldTables }))}
                />Anciens tableaux
            </Grid>
            <Grid item md={12}>
                <LoadingIndicator area="tableconfiguration.competitions" />
                {/* Selection : {competitionSelectionGridRows.map(c => c.id).join(',')}<br /> */}
                {displayOptions.oldTables === false && <CompetitionEditTable
                    edit={edit}
                    delete={(id) => handleDeleteClick(id, () => loadCompetitions(true))}
                    competitionList={competitionSelectionGridRows}
                    dispatchNotification={dispatchNotification}
                    setCreateCompetition={(c) => {
                        competitionEdit.selectCompetition(undefined);
                        competitionEdit.createCompetition(c);
                    }}
                    onChange={() => loadCompetitions(true)}
                    small={displayOptions.smallButtons}
                />}
                {displayOptions.oldTables && competitionSelectedGrid}
                {selectFilter}
                {displayOptions.oldTables === false && competitionTable}
                {displayOptions.oldTables && competitionSelectionGrid}
            </Grid>

        </Grid >
    </>
}
