import { AddCircleRounded, Delete } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Button, Chip, Grid, Link, MenuItem, Select, Tab, Tabs, TextField } from "@mui/material";
import { Box } from '@mui/system';
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import produce from "immer";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { useTranslation } from "react-i18next";
import { getCompetition } from '../../Backend/ApiCompetition';
import { deleteTirage, readTirage } from '../../Backend/ApiDrawing';
import { ITeamWithFighters, completeFromLicencie, createFightersFighter, createFightersTeams, deleteFightersFighter, deleteFightersTeam, getFightersTeam, getFightersTeamFighters, getFightersTeams, getRegistered, getTeamsWithFighters, truncateFightersFighter, truncateFightersTeam, updateFightersFighter, updateFightersTeams, validFighter, validTeam } from "../../Backend/ApiFighter";
import { isEventStatus } from '../../EventComponent/Event/EventStatus';
import { TMartialArt } from "../../Model/ApiCompetitionModel";
import { IApiRegisterModel } from '../../Model/ApiRegisterModel';
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel";
import { refreshFighters } from '../../util/ApiDraw';
import { IAuthentication } from "../Auth/auth";
import { useCompetitionLoader } from '../Competition/CompetitionLoader';
import { ConfirmModal } from '../Modal/ConfirmModal';
import { getApiObject } from '../Operation/GenericOperation';
import { IApiFighterFighter, nonFighterFighter } from "../Team/LazyFightTeam";
import { useSelectCompetition } from "../Use/UseSelectCompetition";
import { useErrorMessage } from "../Util/ErrorMessage";
import { EditFighterModal, EditTeamModal } from './EditTeamModal';
import { SelectDiscipline } from './SelectDisicipline';

interface IExportModel {
    'License': string,
    'Firstname': string,
    'Lastname': string,
    'Nationality (eg:FR)': string,
    'Birthdate (eg YYYY-MM-DD)': string,
    'Club': string,
    'Region': string,
    'Grade': string,
    'Gender (1 Male/2 Female)': 1 | 2,
    'Valid (1 Yes/0 No)': 0 | 1,
    'Zekken number': string,
    'Team': string,
    'id-competition': string
}
/*
export interface IExportModel {
    'License': string,
    'Firstname': string,
    'Lastname': string,
    'Club': string,
    'Region': string,
    'Grade': string,
    'Valid (1 Yes/0 No)': 1 | 0,
    'Team': string,
    'id': number,
    'id-competition': string,
    'Zekken number': string,
    'Nationality (eg:FR)': string,
    'Birthdate (eg YYYY-MM-DD)': string,
    'Gender (1 Male/2 Female)': 1 | 2,
}
*/

interface IImportAction {
    type: 'clear' | 'check' | 'ready' | "error" | "message" | "lastMessage" | "finished",
    data?: Array<IImportRow | string>
}

interface IImportState {
    show: boolean,
    fighterToImport: Array<IImportRow>,
    fighterCreated: number,
    teamCreated: number,
    reportMessages: Array<string>,
    lastMessage: string,
    ready: boolean
}

interface IImportRow extends IApiFighterFighter {
    teamName: string
}

function importFighterReducer(state: IImportState, action: IImportAction): IImportState {
    switch (action.type) {
        case 'lastMessage':
            /*const newReportMessages = produce(state.reportMessages, draft => {
                const lastmessage = (action.data as Array<string>)[0] ?? "";
                if (draft.length > 0) {
                    draft.pop();
                }
                draft.push(lastmessage);
            });*/
            return {
                ...state,
                show: true,
                ready: true,
                lastMessage: (action.data as Array<string>)[0] ?? ""
            };
            break;
        case 'message':
        case 'error':
            return {
                ...state,
                show: true,
                ready: true,
                reportMessages: state.reportMessages.concat(action.data as Array<string>)
            };
            break;
        case 'clear':
            return {
                show: true,
                fighterCreated: 0,
                teamCreated: 0,
                reportMessages: [],
                fighterToImport: [],
                lastMessage: "",
                ready: false
            };
        case 'check':
            if (!action.data) {
                return {
                    ...state,
                    show: true,
                    reportMessages: ['Data required']
                };
            }
            else {
                return produce(state, draft => {
                    let dataChecked: Array<IImportRow> = [];
                    for (const data of action.data ?? []) {
                        //Check row
                        dataChecked.push(data as IImportRow);
                    }
                    draft.fighterToImport = draft.fighterToImport.concat(dataChecked);
                });
            }
        case 'ready':

            return {
                ...state,
                show: true,
                ready: true,
                reportMessages: state.reportMessages.concat(['Ready'])
            };
        case 'finished':

            return {
                ...state,
                show: true,
                ready: false,
                reportMessages: state.reportMessages.concat(['Finished'])
            };
        default:
            throw new Error();

    }
}

interface IImportOption {
    clear: "clearBeforeDownload" | "clearFighterBeforeDownload" | "dontClear"
    licencie: "" | TMartialArt,
    licencieAction: "none" | "complete" | "erase"
}


function ValidChip(props: {
    valid: boolean,
    onClick: () => void
}) {
    return <Chip onClick={props.onClick} size="small" label={props.valid ? "Valide" : "Non valide"} color={props.valid ? "primary" : "error"} />
}

export interface IITeamTableFilter {
    fighterName: string | undefined,
    teamName: string | undefined,
}

export interface ITeamWithFightersState extends ITeamWithFighters {
    status: "updating" | "none"
}

export function TeamTable(props: { authentication: IAuthentication }) {
    //sessionStorage.setItem("module", "team");
    const { t } = useTranslation();
    const [dispatchNotification, errorComponent] = useErrorMessage();
    const [importState, dispatchImport] = useReducer(importFighterReducer, {
        show: false,
        fighterCreated: 0,
        teamCreated: 0,
        reportMessages: [],
        fighterToImport: [],
        lastMessage: "",
        ready: false
    });
    const [selectionComponent, competitionId, _] = useSelectCompetition({
        dispatchNotification: dispatchNotification
    });

    const [rows, setRows] = useState<ITeamWithFightersState[]>([]);
    const [registeredRows, setRegisteredRows] = useState<IApiRegisterModel[]>([]);
    const [importOptions, setImportOptions] = useState<IImportOption>({
        clear: "clearBeforeDownload",
        licencie: "",
        licencieAction: "none"
    })
    const [editTeam, setEditTeam] = useState<ITeamWithFighters | undefined>(undefined);
    const [editFighter, setEditFighter] = useState<IApiFighterFighter | undefined>(undefined);
    const [filter, setFilter] = useState<IITeamTableFilter>({
        fighterName: undefined,
        teamName: undefined
    });

    const [selectedTab, setSelectedTab] = useState(1);
    const [competition, loadCompetition] = useCompetitionLoader(dispatchNotification, competitionId, false);

    useEffect(() => {
        loadCompetition(competitionId);
    }, [loadCompetition, competitionId])

    const calculTeamValidation = (fighterId: number, fighterValidation: number) => {
        setRows(produce(rows, draft => {
            //chercher l'équipe du combattant, et regarder si on doit la valider
            for (const row of draft) {
                const fighter = row.fighters.find(f2 => f2.id === fighterId);
                if (fighter) {
                    fighter.valide = (!fighterValidation) ? 1 : 0;

                    const teamSize = competition?.teamSize ?? 0;
                    const minSize = Math.floor((teamSize + 1) / 2);
                    const teamValid = row.fighters.filter(f => f.valide).length >= minSize;
                    row.valide = teamValid ? 1 : 0;
                }

            }
        }));
    }


    const fighterValidation = async (fighterId: number, valid: boolean, previouslyValidated: Array<number>, previouslyInValidated: Array<number>) => {
        if (competitionId) {

            const team = rows.find(t => t.fighters.find(f => f.id === fighterId));
            let teamValid = team?.valide == 1;
            if (!team) {
                console.error("Fighter not found");
                return false;
            }
            else {
                let r = await validFighter(dispatchNotification, competitionId, [fighterId], valid);
                if (r) {
                    const teamSize = competition?.teamSize ?? 0;
                    const minSize = Math.floor((teamSize + 1) / 2);

                    //check if all fight are valid
                    const teamValidCalculated = team.fighters.filter(f =>
                    (
                        //n'est pas le fighter en cours de validation
                        (
                            f.id !== fighterId &&
                            (
                                //est valide et n'est pas invalidé
                                (f.valide === 1 && previouslyInValidated.includes(f.id) === false)
                                //ou est precédément validé
                                || previouslyValidated.includes(f.id)
                            ))
                        //ou est le fighter en cours de validation
                        || (f.id === fighterId && valid)
                    )).length >= minSize
                    //if half fight is valid, valid the team
                    if (teamValid !== teamValidCalculated) {
                        await validTeam(dispatchNotification, competitionId, [team.id], teamValidCalculated);
                    }
                    return r;
                }
                else {
                    return r;
                }
            }
        }
        return false;
    }

    const teamValidation = async (teamId: number, valid: boolean) => {
        if (competitionId) {
            const team = rows.find(t => t.id === teamId);
            if (!team) {
                console.error("Fighter not found");
                return false;
            }
            else {
                let previouslyValidated = [];
                let previouslyInValidated = [];
                //un peu risqué à l'affichage si le traitement va trop vite , mais il y a un await :/
                //risque que le updating passe apres le none
                setRows(rowss => produce(rowss, draft => {
                    const rowIndex = draft.findIndex(t2 => t2.id === teamId);
                    if (rowIndex >= 0) {
                        draft[rowIndex].status = "updating";
                    }
                }));
                for (const fighter of team.fighters) {
                    if (await fighterValidation(fighter.id, valid, previouslyValidated, previouslyInValidated)) {
                        if (valid) previouslyValidated.push(fighter.id);
                        else previouslyInValidated.push(fighter.id);
                    }
                }
                const newTeam = await getFightersTeam(dispatchNotification, teamId);
                const fighters = await getFightersTeamFighters(dispatchNotification, teamId);
                setRows(rowss => produce(rowss, draft => {
                    const rowIndex = draft.findIndex(t2 => t2.id === teamId);
                    if (rowIndex >= 0) {
                        draft[rowIndex] = {
                            ...newTeam,
                            fighters: fighters,
                            status: "none"
                        }
                    }
                }));
                return true;

            }
        }
        return false;
    }


    const gridRegisterColumns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', width: 100
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'firstname', headerName: 'firstname', flex: 3
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'lastname', headerName: 'lastname', flex: 3
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'team_name', headerName: 'team_name', flex: 3
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'teamId', headerName: 'teamId', width: 100
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'email', headerName: 'email', flex: 3,
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'phone', headerName: 'phone', flex: 2
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'grade', headerName: 'grade', flex: 2
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'club', headerName: 'club'
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'region', headerName: 'region'
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'nationality', headerName: 'nationality'
            //, sortComparator: childrenSortComparator('id')
        },
    ]

    //grid definition
    const gridColumns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', width: 100
            //, sortComparator: childrenSortComparator('id')
        },
        {
            field: 'competition',
            headerName: 'Competition ID',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 2,
            renderCell: (params: GridRenderCellParams) => {
                return <>
                    {params.value}
                    <EditIcon
                        onClick={() => {
                            setEditTeam(params.row);
                        }} />

                    <DeleteIcon
                        color="error"
                        onClick={() => {
                            confirmAlert({
                                title: "Suppression de l'équipe",
                                message: `Voulez-vous supprimer l'équipe ${params.row.name} ?`,
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () => {
                                            if (competitionId) {
                                                //status de compétition
                                                getCompetition(dispatchNotification, competitionId).then(competition => {
                                                    if (!["Created", "RegistrationOpen", "RegistrationClosed", "DrawingsDone"].includes(competition.status)) {
                                                        confirmAlert({
                                                            title: "Suppression de l'équipe impossible",
                                                            message: `Vous ne pouvez pas supprimer une équipe une fois le tirage validé.`,
                                                            buttons: [
                                                                {
                                                                    label: 'Ok',
                                                                }
                                                            ]
                                                        });
                                                    }
                                                    else {
                                                        //status du tirage
                                                        readTirage(dispatchNotification, competitionId).then((tirage) => {
                                                            const deleteAction = () => deleteFightersTeam(dispatchNotification, params.row.id).then((r) => {
                                                                if (r) {
                                                                    setRows(produce(rows, draft => {
                                                                        return draft.filter(t => t.id !== params.row.id)
                                                                    }));
                                                                }
                                                            });
                                                            if (tirage?.id) {
                                                                confirmAlert({
                                                                    title: "Suppression du tirage",
                                                                    message: `Supprimer cette équipe va supprimer le tirage créé, confirmer ?`,
                                                                    buttons: [
                                                                        {
                                                                            label: 'Oui',
                                                                            onClick: () => {
                                                                                deleteTirage(dispatchNotification, competitionId).then((r) => {
                                                                                    deleteAction();
                                                                                });
                                                                            }
                                                                        },
                                                                        {
                                                                            label: 'Non',
                                                                        }
                                                                    ]
                                                                });
                                                            }
                                                            else {
                                                                deleteAction();
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                    },
                                    {
                                        label: 'No',
                                        onClick: () => {

                                        }
                                    }
                                ],
                            });
                        }}

                    />
                </>
            }
        },
        {
            field: 'valide',
            headerName: 'Validation',
            renderCell: (params: GridRenderCellParams) => {
                return <ValidChip
                    valid={params.value == 1}
                    onClick={() => {
                        teamValidation(params.row.id, params.value == 0);/*.then((r) => {
                            if (r) {
                                setRows(produce(rows, draft => {
                                    const row = draft.find(t2 => t2.id === params.row.id);
                                    if (row) {
                                        row.valide = (!params.value) ? 1 : 0;
                                    }

                                }));
                            }
                        });*/
                    }}
                />;
            }
        },
        {
            field: 'fighters',
            headerName: 'Combattants',
            minWidth: 300,
            flex: 2,
            renderCell: (params: GridRenderCellParams) => {
                const figthers = params.value as Array<IApiFighterFighter>;
                const status = params.row['status'];
                return <ul>
                    <li><AddCircleRounded color="primary" onClick={() => {
                        setEditFighter(
                            {
                                ...nonFighterFighter,
                                team: params.row.id
                            }
                        );
                    }} /></li>

                    {status === "updating" && "Updating..."}
                    {status !== "updating" && figthers.map((f) => {
                        return <li key={f.id}>
                            {f.firstname} {f.lastname}&nbsp;
                            <ValidChip valid={f.valide == 1} onClick={() => {
                                fighterValidation(f.id, !f.valide, [], []).then((r) => {
                                    if (r) {
                                        calculTeamValidation(f.id, f.valide)
                                    }
                                });
                            }} />
                            <EditIcon
                                color='primary'
                                onClick={() => {
                                    setEditFighter(f);
                                }} />
                            <Delete
                                color='error'
                                onClick={() => {
                                    confirmAlert({
                                        title: "Suppression du combattant",
                                        message: "Voulez-vous supprimer ce combattant ?",
                                        buttons: [
                                            {
                                                label: 'Yes',
                                                onClick: () => {
                                                    deleteFightersFighter(dispatchNotification, f.id).then((r) => {
                                                        if (r) {
                                                            setRows(produce(rows, draft => {
                                                                for (const row of draft) {
                                                                    if (row.id === f.team) {
                                                                        row.fighters = row.fighters.filter(f2 => f2.id !== f.id);
                                                                    }
                                                                }
                                                            }));
                                                        }
                                                    });
                                                }
                                            },
                                            {
                                                label: 'No',
                                                onClick: () => {

                                                }
                                            }
                                        ],
                                    });
                                    /*deleteFighter(f.id).then((r) => {
                                        if (r) {
                                            setRows(produce(rows, draft => {
                                                const row = draft.find(t2 => t2.id === params.row.id);
                                                if (row) {
                                                    row.fighters = row.fighters.filter(f2 => f2.id !== f.id);
                                                }
                                            }));
                                        }
                                    });*/
                                }}
                            />
                        </li>
                    })}
                </ul>;

            }
        },
        {
            field: 'club',
            headerName: 'Club',
            flex: 1,
        },
        {
            field: 'region',
            headerName: 'Région',
            flex: 1,
        },
        {
            field: 'nationality',
            headerName: 'Pays',

        }
    ];

    const registerRowsToExport = (rows: Array<IApiRegisterModel>): Array<IExportModel> => {
        return registeredRows.map(r => {
            return {
                'License': r.license ?? "",
                'Firstname': r.firstname ?? "",
                'Lastname': r.lastname ?? "",
                'Club': r.club ?? "",
                'Region': r.region ?? "",
                'Grade': r.grade ?? "",
                'Valid (1 Yes/0 No)': 1,
                'Team': r.team_name ?? "",
                'id': r.id ?? "",
                'id-competition': `${competitionId ?? ''}`,
                'Zekken number': "",
                'Nationality (eg:FR)': r.nationality ?? "",
                'Birthdate (eg YYYY-MM-DD)': r.birthdate ?? "",
                'Gender (1 Male/2 Female)': r.sex == "2" ? 2 : 1,
            }
        });
    };

    const rowsToExport = (rows: Array<ITeamWithFighters>): Array<IExportModel> => {
        return rows.reduce((flist, t) => {
            const tflist: Array<IExportModel> = t.fighters.map(f => {
                return {
                    'License': f.license ?? "",
                    'Firstname': f.firstname ?? "",
                    'Lastname': f.lastname ?? "",
                    'Club': f.club ?? "",
                    'Region': f.region ?? "",
                    'Grade': f.grade ?? "",
                    'Valid (1 Yes/0 No)': f.valide ? 1 : 0,
                    'Team': t.name ?? "",
                    'id': f.id ?? "",
                    'id-competition': `${competitionId ?? ''}`,
                    'Zekken number': f.zekken_number ?? "",
                    'Nationality (eg:FR)': f.nationality ?? "",
                    'Birthdate (eg YYYY-MM-DD)': f.birthdate ?? "",
                    'Gender (1 Male/2 Female)': f.sex === 2 ? 2 : 1,
                }
            });
            return flist.concat(tflist);
        }, [] as Array<IExportModel>);
    };

    const launchImport = async () => {
        if (competitionId && competitionId > 0) {

            if (importOptions.clear === "clearBeforeDownload") {
                dispatchImport({
                    type: 'message', data: [
                        `Deleting exiting teams for competition`,
                    ]
                });
                await truncateFightersTeam(dispatchNotification, competitionId);

            }
            else if (importOptions.clear === "clearFighterBeforeDownload") {
                dispatchImport({
                    type: 'message', data: [
                        `Deleting exiting fighters for competition`,
                    ]
                });
                await truncateFightersFighter(dispatchNotification, competitionId);
            }



            //load existing competition teams
            const existingTeams = await getFightersTeams(dispatchNotification, competitionId);
            //check encoding (needed ? to test)
            //if update from licencie, search licencie
            let fighterCreated = 0;
            let teamCreated = 0;
            let errors = 0;
            //check fighter
            for (const [rowId, fighterRow] of importState.fighterToImport.entries()) {
                let team: IApiFighterFightTeam | undefined = undefined;
                let fighterData: IImportRow = {
                    ...fighterRow,
                    lastname: fighterRow.lastname.toUpperCase(),
                    license: String(fighterRow.license.length > 0 ? fighterRow.license : Date.now()),
                    birthdate: fighterRow.birthdate.length > 0 ? fighterRow.birthdate : '2000-01-01',

                };
                if (importOptions.licencie.length > 0
                    && fighterRow.license.length > 0
                    && importOptions.licencieAction !== "none") {
                    let [fighterDataCompleted, completed] = await completeFromLicencie(dispatchNotification, fighterData, fighterData.license, importOptions.licencie as TMartialArt, importOptions.licencieAction === "erase");
                    if (completed) {
                        fighterData = fighterDataCompleted;
                    }
                    else {
                        dispatchImport({ type: 'error', data: [`Line ${rowId + 2} : licence not found : ${fighterData.license}`] });
                    }
                }


                if (fighterData.lastname.length === 0 || fighterData.firstname.length === 0) {
                    dispatchImport({ type: 'error', data: [`Line ${rowId + 2} : firstname and lastname are mandatory`] });
                    errors++;
                }
                else {
                    if (fighterData.teamName.length === 0) {
                        fighterData.teamName = `${fighterData.firstname} ${fighterData.lastname}`;
                    }

                    team = existingTeams.find(t => t.name === fighterData.teamName);
                    if (!team) {
                        const newTeamId = await createFightersTeams(dispatchNotification, {
                            name: fighterData.teamName,
                            club: fighterData.club,
                            competition: String(competitionId),
                            nationality: fighterData.nationality,
                            valide: 0,
                            region: fighterData.region ?? '',
                        });
                        if (newTeamId.data > 0) {
                            let newTeam = await getFightersTeam(dispatchNotification, newTeamId.data);
                            if (newTeam) {
                                existingTeams.push(newTeam);
                                team = newTeam;
                            }
                            teamCreated++;
                        }
                        else {
                            dispatchImport({ type: 'error', data: [`Line ${rowId + 2} : error while creating team for line `] });
                            errors++;
                        }
                    }
                }
                //if team is found or created, add fighter
                if (team) {
                    fighterData.team = team.id;
                    let newFighterId = await createFightersFighter(dispatchNotification, fighterData);
                    if (newFighterId.data > 0) {
                        fighterCreated++;
                    }
                    else {
                        dispatchImport({ type: 'error', data: [`Line ${rowId + 2} : error while creating fighter for line `] });
                        errors++;
                    }
                }
                else {
                    //await to enable refresh
                    await new Promise(f => setTimeout(f, 200));
                }
                dispatchImport({
                    type: 'lastMessage', data: [
                        `Fighters created : ${fighterCreated}/${importState.fighterToImport.length}, Teams created : ${teamCreated},Errors : ${errors}, Line ${rowId}/ ${importState.fighterToImport.length}`
                    ]
                });

            }
            //Rapport d'avancement
            dispatchImport({
                type: 'message', data: [
                    `Team validation calculation`
                ]
            });


            //reload
            const d = await getTeamsWithFighters(competitionId, dispatchNotification);
            for (const row of d) {
                const teamSize = competition?.teamSize ?? 0;
                const minSize = Math.floor((teamSize + 1) / 2);
                const teamValid = row.fighters.filter(f => f.valide).length >= minSize;
                if (row.valide !== (teamValid ? 1 : 0)) {
                    if (await validTeam(dispatchNotification, competitionId, [row.id], teamValid)) {
                        row.valide = teamValid ? 1 : 0;
                    }
                };
            }

            //Rapport d'avancement
            dispatchImport({
                type: 'lastMessage', data: [
                    `Fighters created : ${fighterCreated}/${importState.fighterToImport.length}`,
                    `Teams created : ${teamCreated}`,
                    `Errors : ${errors}`
                ]
            });
            dispatchImport({
                type: 'finished'
            });
            setRows(d.map(r => {
                return {
                    ...r,
                    status: "none"
                }
            }));
        }
    }

    const loadRows = useCallback(async (mounted: boolean) => {
        if (competitionId) {
            if (selectedTab === 1) {
                getTeamsWithFighters(competitionId, dispatchNotification).then(d => {
                    if (mounted) setRows(d.map(r => {
                        return {
                            ...r,
                            status: "none"
                        }
                    }));
                })
            }
            else {
                getRegistered(competitionId, false, false, dispatchNotification).then(d => {
                    if (mounted) {
                        d ? setRegisteredRows(d) : setRegisteredRows([]);
                    }
                });
            }
        }
        else {
            setRows([]);
        }

    }, [competitionId, dispatchNotification, selectedTab]);

    useEffect(() => {
        dispatchImport({
            type: 'clear',
        })
    }, [competitionId]);

    useEffect(() => {
        let mounted = true;
        loadRows(mounted);

        setImportOptions(o => {
            return {
                ...o,
                licencie: competition?.martialArt ?? ""
            }
        })

        return () => {
            mounted = false;
        }
    }, [competition?.martialArt, dispatchNotification, loadRows])



    const stats = {
        teamsCount: rows.length,
        fightersCount: rows.reduce((a, b) => a + b.fighters.length, 0),
        teamsValidatedCount: rows.filter(r => r.valide).length,
        teamsValidatedEmptyCount: rows.filter(r => r.valide && r.fighters.length === 0).length,
        teamsValidatedIncompleteCount: rows.filter(r => r.valide && r.fighters.filter(f => f.valide === 0).length > 0).length,
        validatedFightersCount: rows.reduce((a, b) => a + b.fighters.filter(f => f.valide).length, 0),

    }

    return <>
        {
            editFighter && <EditFighterModal
                dispatchNotifications={dispatchNotification}
                fighter={editFighter}
                discipline={competition?.martialArt ?? ""}
                onClose={(editedFighter) => {
                    setEditFighter(undefined);
                    if (editedFighter && editedFighter.id > 0) {
                        setRows(produce(rows, draft => {
                            const editTeam = draft.find(t => t.id === editedFighter.team);
                            if (editTeam) {
                                const fighterIndex = editTeam?.fighters.findIndex(f => f.id === editedFighter.id);
                                if (editedFighter) {
                                    if (fighterIndex !== undefined && fighterIndex >= 0) {
                                        editTeam.fighters[fighterIndex] = editedFighter;
                                    }
                                    else {
                                        editTeam.fighters.push(editedFighter);
                                    }
                                }
                            }
                        }));
                    }
                }}
                open={editFighter !== undefined}

            />
        }
        {editTeam && <EditTeamModal
            dispatchNotifications={dispatchNotification}
            discipline={competition?.martialArt ?? ""}
            open={editTeam !== undefined}
            team={editTeam}
            onClose={(editedTeam) => {
                if (editedTeam) {
                    (async () => {
                        let r = true;
                        let createdTeam = undefined;
                        if (editedTeam.id <= 0) {
                            createdTeam = await (createFightersTeams(dispatchNotification, editedTeam)).catch(() => {
                                r = false;
                            });
                            if (createdTeam && createdTeam.data > 0) {
                                editedTeam.id = createdTeam.data;
                            }
                            else {
                                r = false;
                            }
                        }
                        else if (editedTeam.unsaved) await updateFightersTeams(dispatchNotification, editedTeam);
                        if (r && editedTeam) {
                            //update drawing
                            if (competitionId) {
                                const tirage = await readTirage(dispatchNotification, competitionId)
                                if (tirage?.id) {
                                    await refreshFighters(dispatchNotification, competitionId);
                                }
                            }

                            //saving fighters
                            for (const key of editedTeam.fighters.keys()) {
                                //only if changed
                                if (editedTeam.fighters[key].unsaved) {
                                    r = r && await updateFightersFighter(dispatchNotification, editedTeam.fighters[key]);
                                }
                            }
                            //if all saved gone well
                            if (r) {
                                if (!createdTeam) {
                                    setRows(produce(rows, draft => {
                                        const newEditedTeam = produce(editedTeam, draft => {
                                            draft.unsaved = false;
                                            //saving fighters
                                            for (const key of draft.fighters.keys()) {
                                                draft.fighters[key].unsaved = false;
                                            }
                                        });
                                        let index = draft?.findIndex(t => t.id === newEditedTeam.id);
                                        draft[index] = {
                                            ...newEditedTeam,
                                            status: "none"
                                        };

                                    }));
                                }
                                else if (competitionId) {
                                    const d = await getTeamsWithFighters(competitionId, dispatchNotification);
                                    setRows(d.map(r => {
                                        return {
                                            ...r,
                                            status: "none"
                                        }
                                    }));
                                }

                            }

                            else if (r && createdTeam) {
                                if (competitionId) {
                                    const d = await getTeamsWithFighters(competitionId, dispatchNotification);
                                    setRows(d.map(r => {
                                        return {
                                            ...r,
                                            status: "none"
                                        }
                                    }));
                                }
                            }
                        }
                        if (r) {
                            setEditTeam(undefined);
                        }
                    })()


                }
                else {
                    setEditTeam(undefined);
                }

            }} />}
        <Grid container spacing={2}>
            <Grid item md={12}>
                {errorComponent}
                {selectionComponent}
            </Grid>
            <Grid item md={12}>
                <Link href={(window as any)['COMBATTANTS_UI_URL'] + '/?event=' + competitionId ?? 0}>Ancien écran des combattants</Link>

            </Grid>
            {(!competitionId) && <>
                <Alert color="info">
                    {t("label.competition.noSelection")}
                </Alert>
            </>}
            {(competitionId ?? -1) >= 0 && <>
                <Grid container item md={3}>
                    <Grid item md={12}>
                        {isEventStatus(competition?.status, "DrawingsDone") && <>
                            <Alert color="warning">{t(`label.draw.drawingDone`)} </Alert>
                        </>}

                        Composition<br />
                        <Chip color={stats.teamsCount > 2 ? "success" : "error"} label={`${stats.teamsCount} Équipes`} /><br />
                        <Chip color={stats.fightersCount > 2 ? "success" : "error"} label={`${stats.fightersCount} Combattants`} /><br />
                        <Chip color={stats.teamsValidatedCount === stats.teamsCount ? "success" : "warning"} label={`${stats.teamsValidatedCount} / ${stats.teamsCount} Equipes validées`} /><br />
                        <Chip color={stats.validatedFightersCount === stats.fightersCount ? "success" : "warning"} label={`${stats.validatedFightersCount} / ${stats.fightersCount} Combattants validées`} /><br />
                        <Chip color={stats.teamsValidatedEmptyCount === 0 ? "success" : "error"} label={`${stats.teamsValidatedEmptyCount} / ${stats.teamsValidatedCount} Equipes valides vides`} /><br />
                        <Chip color={stats.teamsValidatedIncompleteCount === 0 ? "success" : "warning"} label={`${stats.teamsValidatedIncompleteCount} / ${stats.teamsValidatedCount} Equipes valides incomplètes`} /><br />
                        {importState.show && <>
                            {importState.lastMessage.length > 0 && <Alert color="info">{importState.lastMessage}</Alert>}
                            {importState.reportMessages.map((m, i) => <Alert key={i} color={"info"}>{m}</Alert>)}
                        </>}
                        {selectedTab === 1 && <>
                            <Alert color="warning" style={{
                                fontWeight: "bold"
                            }}>
                                Le tirage et les tables ne sont pas immédiatement impactés par les changements d'équipes :<br />
                                - Une fois le tirage réalisé, les changements de nom d'équipe ne sont pas plus en compte<br />
                                - Après que le combat ait été envoyé sur un shiaijo, le changement de composition d'une équipe n'est plus pris en compte<br />
                            </Alert>
                        </>
                        }
                    </Grid>
                </Grid >
                <Grid container md={8} rowSpacing={2}>
                    <Grid item md={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectedTab} onChange={(event: React.SyntheticEvent, newValue: number) => {
                                setSelectedTab(newValue);
                            }} aria-label="basic tabs example">
                                <Tab value={1} label="Team" />
                                <Tab value={2} label="Registration" />
                                <Tab value={3} label="Import/Export" disabled={isEventStatus(competition?.status, "DrawingsDone")} />
                            </Tabs>
                        </Box>
                    </Grid>

                    {selectedTab === 3 && <>
                        <Grid item md={12}>
                            Import des combattants<br />
                            Vous pouvez importer les combattants par liste via un fichier excel du format ci dessous.
                            Seuls les noms et prénoms sont obligatoire.
                            Si vous avez le numéro de licence, vous pouvez n'indiquer que celui-ci, le système complétera avec les informations licencie de la catégorie (mais l'import sera un peu plus long)
                            Vérifiez bien le nombre de combattants et licenciés après import, ainsi que leur validation (indicateur de leur présence à la compétition)
                            <Alert color="info">
                                Import fighter(s) from csv file (separator "," or ";" and utf-8 encoding).
                                Example File; Example light file
                            </Alert>
                        </Grid>
                        <Grid item md={4}>
                            Clearing : <Select id="clearBeforeDownload" name="clearBeforeDownload" value={importOptions.clear}
                                onChange={(e) => setImportOptions({
                                    ...importOptions,
                                    clear: e.target.value as "dontClear" | "clearBeforeDownload" | "clearFighterBeforeDownload"
                                })}
                            >
                                <MenuItem value="dontClear">Don't clear</MenuItem>
                                <MenuItem value="clearBeforeDownload" >{t('label.import.clearBeforeDownload')}</MenuItem>
                                <MenuItem value="clearFighterBeforeDownload" >{t('label.import.clearFighterBeforeDownload')}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={8}>
                            <Alert color="warning">
                                {t(`label.import.${importOptions.clear}Info`)}
                            </Alert>
                        </Grid>
                        <Grid item md={4}>
                            Licencie Action :
                            <Select id="licencieAction" name="licencieAction" value={importOptions.licencieAction}
                                onChange={(e) => setImportOptions({
                                    ...importOptions,
                                    licencieAction: e.target.value as "none" | "complete" | "erase"
                                })}>
                                <MenuItem value="none">Aucune</MenuItem>
                                <MenuItem value="complete">Compléter</MenuItem>
                                <MenuItem value="erase">Remplacer</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={8}>
                            <Alert color={importOptions.licencieAction === 'none' ? 'info' : 'warning'}>
                                {t(`label.import.licencieImportAction${importOptions.licencieAction}Info`)}
                            </Alert>

                        </Grid>
                        <Grid item md={12}>
                            {importOptions.licencieAction !== 'none' && <>
                                Licencie Discipline : <SelectDiscipline
                                    value={importOptions.licencie}
                                    onChange={(e) => setImportOptions({
                                        ...importOptions,
                                        licencie: e.target.value as "" | TMartialArt
                                    })}
                                /><br />
                            </>}
                        </Grid>
                        <Grid item md={12}>
                            {importState.ready && <Alert color="warning">Import en cours, veuillez patienter</Alert>}
                            {importState.ready === false && <Importer

                                dataHandler={async (rows, { startIndex }) => {
                                    dispatchImport({
                                        type: "check",
                                        data: rows.map(r => {
                                            const newRow: IImportRow = {
                                                license: r.license as string,
                                                firstname: r.firstname as string,
                                                lastname: r.lastname as string,
                                                club: r.club as string,
                                                region: r.region as string,
                                                grade: r.grade as string,
                                                valide: parseInt(r.valide as string),
                                                teamName: r.team as string,

                                                birthdate: r.birthdate as string,
                                                competition: String(competitionId ?? 0),
                                                sex: parseInt(r.gender as string),
                                                zekken_number: r.zekken as string,
                                                nationality: r.nationality as string,
                                                id: 0,
                                                date_creation: "",
                                                tstamp: "",
                                                firstname_alt: "",
                                                lastname_alt: "",
                                                team: 0
                                            }
                                            return newRow;
                                        })
                                    })
                                }}
                                defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                                restartable={true} // optional, lets user choose to upload another file when import is complete

                                onStart={({ file, preview, fields, columnFields }) => {
                                    // optional, invoked when user has mapped columns and started import
                                    //prepMyAppForIncomingData();
                                    dispatchImport({
                                        type: "clear"
                                    });
                                }}
                                onComplete={({ file, preview, fields, columnFields }) => {
                                    dispatchImport({
                                        type: "ready"
                                    });
                                    dispatchImport({
                                        type: "message",
                                        data: ["Lancement de l'import"]
                                    });
                                    return launchImport();
                                }}
                                onClose={({ file, preview, fields, columnFields }) => {
                                    // optional, if this is specified the user will see a "Finish" button after import is done,
                                    // which will call this when clicked
                                    //goToMyAppNextPage();
                                }}


                            // CSV options passed directly to PapaParse if specified:
                            // delimiter={...}
                            // newline={...}
                            // quoteChar={...}
                            // escapeChar={...}
                            // comments={...}
                            // skipEmptyLines={...}
                            // delimitersToGuess={...}
                            // chunkSize={...} // defaults to 10000
                            // encoding={...} // defaults to utf-8, see FileReader API

                            >

                                <ImporterField name="license" label="license" optional={true} />
                                <ImporterField name="firstname" label="firstname" optional={true} />
                                <ImporterField name="lastname" label="lastname" optional={true} />
                                <ImporterField name="club" label="club " optional={true} />
                                <ImporterField name="region" label="region " optional={true} />
                                <ImporterField name="grade" label="grade" optional={true} />
                                <ImporterField name="valide" label="valid (1 yes/0 no)" optional={true} />
                                <ImporterField name="team" label="team" optional={true} />
                                <ImporterField name="id" label="id" optional={true} />
                                <ImporterField name="id-competition" label="id-competition" optional={true} />
                                <ImporterField name="zekken" label="Zekken number" optional={true} />
                                <ImporterField name="nationality" label="Nationality (eg:FR)" optional={true} />
                                <ImporterField name="birthdate" label="Birthdate (eg YYYY-MM-DD)" optional={true} />
                                <ImporterField name="gender" label="Gender (1 Male/2 Female)" optional={true} />

                            </Importer>
                            }
                        </Grid>
                    </>
                    }

                    {selectedTab === 2 && <>
                        <Grid item md={12}>
                            <CSVLink
                                style={{ backgroundColor: '#4CAF50', padding: "5px", marginLeft: "10px" }}
                                data={registerRowsToExport(registeredRows)}
                                className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedWarning MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-root MuiButton-contained MuiButton-containedWarning MuiButton-sizeSmall MuiButton-containedSizeSmall css-1edgfgr-MuiButtonBase-root-MuiButton-root'
                                separator=";"

                                filename={`registration_export_${competitionId}.csv`}
                            >Export des inscriptions</CSVLink>
                        </Grid>
                        <Grid item md={12}>
                            <DataGrid
                                autoHeight
                                pagination

                                columns={gridRegisterColumns}
                                rows={registeredRows.filter(r => {
                                    return true
                                        && (
                                            (
                                                filter.fighterName === undefined
                                                || (new RegExp(`^.*${filter.fighterName}.*$`, 'i')).test(r.firstname)
                                                || (new RegExp(`^.*${filter.fighterName}.*$`, 'i')).test(r.lastname))
                                        )
                                })}
                                getRowHeight={() => 'auto'}

                            />
                        </Grid>
                    </>}
                    {selectedTab === 1 && <>

                        {competitionId && <>
                            <Grid container md={12} item>
                                <Grid item md={3}>
                                    Filtres
                                </Grid>
                                <Grid item md={4}>
                                    Team <TextField
                                        size='small'
                                        value={filter.teamName}
                                        onChange={(e) => setFilter({
                                            ...filter,
                                            teamName: e.target.value
                                        })} />
                                </Grid>
                                <Grid item md={4}>
                                    Fighter <TextField
                                        size='small'
                                        value={filter.fighterName}
                                        onChange={(e) => setFilter({
                                            ...filter,
                                            fighterName: e.target.value
                                        })} />
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Button variant="contained" color="primary" size='small'
                                    disabled={isEventStatus(competition?.status, "DrawingsDone")}
                                    onClick={() => {
                                        setEditTeam({
                                            club: "",
                                            name: "",
                                            competition: competitionId.toString(),
                                            valide: 0,
                                            fighters: [],
                                            id: 0,
                                            date_creation: 0,
                                            nationality: ""
                                            , region: ""
                                        })
                                    }}>Add team</Button>
                                <ConfirmModal
                                    color="primary"
                                    label={t(`action.fighter.validallteam`)}
                                    disabled={isEventStatus(competition?.status, "DrawingsDone")}
                                    message={t(`confirmation.fighter.validallteam`)} action={() => {
                                        validTeam(dispatchNotification, competitionId, [], true).then(() => {
                                            loadRows(true);
                                        });
                                    }} />
                                <ConfirmModal
                                    color="warning"
                                    label={t(`action.fighter.invalidallteam`)}
                                    disabled={isEventStatus(competition?.status, "DrawingsDone")}
                                    message={t(`confirmation.fighter.invalidallteam`)} action={() => {
                                        validTeam(dispatchNotification, competitionId, [], false).then(() => {
                                            validFighter(dispatchNotification, competitionId, [], false).then(() => {
                                                loadRows(true);
                                            });
                                        });
                                    }} />

                                <CSVLink
                                    style={{
                                        backgroundColor: '#4CAF50', padding: "5px", marginLeft: "10px"

                                    }}
                                    className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedWarning MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-root MuiButton-contained MuiButton-containedWarning MuiButton-sizeSmall MuiButton-containedSizeSmall css-1edgfgr-MuiButtonBase-root-MuiButton-root'
                                    data={rowsToExport(rows)}
                                    separator=";"
                                    filename={`fighter_export_${competitionId}.csv`}
                                >Export des combattants</CSVLink>

                                <Button variant="contained"
                                    color='warning'
                                    onClick={() => {
                                        getApiObject<{
                                            succeed: boolean,
                                            data: undefined
                                        }>(`/fighter/swapname/${competitionId}`, null, {
                                            succeed: false,
                                            data: undefined
                                        }, dispatchNotification
                                            , (window as any)['REACT_APP_API_COMBATTANTS_URL']).then((res) => {
                                                if (res.succeed){
                                                    loadRows(true);
                                                    getApiObject<{
                                                        succeed: boolean,
                                                        data: undefined
                                                    }>(`/competitions/${competitionId}/updateTeams`, null, {
                                                        succeed: false,
                                                        data: undefined
                                                    }, dispatchNotification );
                                                }
                                            });
                                    }}>
                                    Swap names
                                </Button>
                                <Button variant="contained"
                                    color='warning'
                                    onClick={() => {
                                        getApiObject<{
                                            succeed: boolean,
                                            data: undefined
                                        }>(`/competitions/${competitionId}/updateTeams`, null, {
                                            succeed: false,
                                            data: undefined
                                        }, dispatchNotification
                                            , );
                                    }}>
                                    Synchroniser avec les tables
                                </Button>
                                {/* <Button variant="contained" onClick={() => {
                        validFighter(dispatchNotification, competitionId, [], true).then(() => {
                            loadRows(true);
                        })
                    }}>Valid all fighters</Button>
                    <Button variant="contained" onClick={() => {
                        validFighter(dispatchNotification, competitionId, [], false).then(() => {
                            loadRows(true);
                        });
                    }}>Invalid all fighters</Button> */}
                            </Grid>
                        </>
                        }


                        <Grid item md={12}>
                            <DataGrid
                                autoHeight
                                pagination
                                columns={gridColumns}
                                rows={rows.filter(r => {
                                    return true
                                        && (filter.teamName === undefined || (new RegExp(`^.*${filter.teamName}.*$`, 'i')).test(r.name))
                                        && (filter.fighterName === undefined || r.fighters.find(f => (
                                            (new RegExp(`^.*${filter.fighterName}.*$`, 'i')).test(f.firstname)
                                            || (new RegExp(`^.*${filter.fighterName}.*$`, 'i')).test(f.lastname)
                                        )))
                                })}
                                getRowHeight={() => 'auto'}

                            />
                        </Grid>
                    </>
                    }
                </Grid >
            </>}
        </Grid >

    </>
}