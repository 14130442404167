import { Alert, Box, Button, ButtonGroup, Grid, Link, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import produce from "immer"
import React, { useEffect, useRef, useState } from "react"
import { confirmAlert } from "react-confirm-alert"
import { CSVLink } from "react-csv"
import { Importer, ImporterField } from "react-csv-importer"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import { useReactToPrint } from "react-to-print"
import { deleteTirage, getFightsOrder, getPouleForUI, getRepartition, getTableauForSuivi, importTirage, invalideTirage, listFightersCompetition, postCreateDraw, postRefaireTirage, readTirage, valideTirage } from "../../Backend/ApiDrawing"
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel"
import { IApiDrawingFighter, IApiDrawingRepartition, IApiDrawingRepartitions, IApiFightOrder, IApiImportRow, IApiReadTirage } from "../../Model/ApiDrawingModel"
import { invertRepartition, refreshFighters } from "../../util/ApiDraw"
import { getSimulation2Svg } from "../../util/ApiFollowing"
import { IAuthentication } from "../Auth/auth"
import { useCompetitionLoader } from "../Competition/CompetitionLoader"
import { useSelectCompetition } from "../Use/UseSelectCompetition"
import { IServiceTableNotification, useErrorMessage } from "../Util/ErrorMessage"
import { DrawingForm, IDrawingForm } from "./DrawingForm"

const countries = require("i18n-iso-countries");

const gridColumns: GridColDef[] = [
    {
        field: 'id', headerName: 'Draw ID', width: 100
        //, sortComparator: childrenSortComparator('id')
    },
    {
        field: 'competitionId',
        headerName: 'Competition ID',
    },
    {
        field: 'type',
        headerName: 'Type',
    },
];

type TDrawingPoolRepartition = Array<IApiDrawingRepartition>;

export interface DrawingTableRepartitionFighterDropItem {
    event: IApiDrawingRepartition
}

function DrawingTableRepartitionFighter(props: {
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    fighter: IApiDrawingRepartition,
    competitionId: number,
    onChange: () => void
}) {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "repartition",
        item: {
            event: props.fighter
        },
        collect: (monitor) => {
            //console.log(monitor.isDragging());
            return ({
                isDragging: !!monitor.isDragging()
            })
        }
    }), [props.fighter]);
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "repartition",
        drop: (item: DrawingTableRepartitionFighterDropItem, monitor) => {

            let e = { ...item.event };//do not change the original item
            if (e.idFighter !== props.fighter.idFighter) {
                invertRepartition(props.dispatchNotification,
                    props.competitionId,
                    e.idFighter,
                    props.fighter.idFighter,
                ).then(d => {
                    if (d.succeed) props.onChange();
                })
            }
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    }), [props.competitionId, props.fighter.idFighter]);

    return <div
        ref={drop}
        style={{
            opacity: isOver ? 0.5 : 1,
            backgroundColor: isOver ? 'red' : '',
        }}
    >
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: isDragging ? 'yellow' : '',
            }}
        >
            <span className='libLicencie'>{props.fighter.name}</span><br />
            <span className='libClub'>{props.fighter.club}</span><br />
        </div>
    </div>
}

function Poule(props: { tailleCellule: string, competition: IApiCompetitionModel, poule: TDrawingPoolRepartition, entetePoule: string, position: number }) {
    const { t, i18n } = useTranslation();


    return <div className="printPageDraw">
        <div className="column is-full is-centered">
            <table className="table-borderless" align="center" width="90%">
                <tbody>
                    <tr>
                        <td rowSpan={2} width="15%" className={`cnk-text-centered ${props.tailleCellule}`} >
                            <img src="/img/logo_print.png" width="100px" alt="logo" /></td>
                        <td rowSpan={2} className={`cnk-titre-2 cnk-text-centered cnk-text-middle ${props.tailleCellule}`}>{props.competition.fullname}</td>
                        <td width="15%" className={`cnk-text-centered ${props.tailleCellule}`}>{t("label.word.date")} : </td>
                        <td width="15%" className={`cnk-text-centered ${props.tailleCellule}`}>{props.competition.competitionDate}</td >
                    </tr >
                    <tr>
                        <td className="cnk-text-centered" >{t("label.competition.Category")} : </td>
                        <td className="cnk-text-centered" >{props.competition.category}</td>
                    </tr>
                    <tr>
                        <td colSpan={4} className={`cnk-text-centered  ${props.tailleCellule} cnk-titre-3`}><br /><br />{t("label.word.pool")} {props.position}</td>
                    </tr >
                    <tr>
                        <td colSpan={4} className={`cnk-text-left cnk-padding-left  ${props.tailleCellule}`}><b>{t("label.word.poule")}</b> : {props.entetePoule}</td>
                    </tr >
                </tbody>
            </table >

        </div >
        <div className='column is-full is-centered'>
            <table cellPadding={0} cellSpacing={0} width='90%' >
                <tbody>
                    <tr>
                        <td width='30%' className='cnk-text-centered' >{t("label.draw.club")}</td>
                        <td width='5%'></td>
                        {props.poule.map((f, k) => {
                            return <td key={k} width='10%' className={`${props.tailleCellule} cellule1111 cnk-text-centered cnk-padding-top-10`}>{k + 1}</td>
                        })}

                        <td width='10%' className={`${props.tailleCellule} cellule0101`}></td>
                        <td width='5%' className={`${props.tailleCellule} cellule0111`}></td>
                        <td width='10%' className={`${props.tailleCellule} cellule0111 cnk-text-centered cnk-padding-top-10`}>{t("label.word.ranking")}</td>
                    </tr>

                    {props.poule.map((f, k) => {
                        return <React.Fragment key={k}>
                            <tr>
                                <td className={`${props.tailleCellule} cellule1110`}></td>
                                <td className={`${props.tailleCellule} cellule0110`}></td>
                                {props.poule.map((f2, k2) => {
                                    if (k === k2) return <td key={k2} className={`${props.tailleCellule} cellule0010 celluleGrise`}></td>;
                                    else return <td key={k2} className={`${props.tailleCellule} cellule0010`}></td>;
                                })}

                                <td className={`${props.tailleCellule} cellule1111 cnk-text-centered cnk-padding-top-10`}>{t("label.word.ippon")}</td>
                                <td className={`${props.tailleCellule} cellule1111`}></td>
                                <td className={`${props.tailleCellule} cellule0010`}></td>
                            </tr>

                            <tr>

                                <td className={`${props.tailleCellule} cellule1010 libLicencie cnk-text-centered`}>{f.name}</td>
                                <td className={`${props.tailleCellule} cellule0010 cnk-text-centered`}>{f.position}</td>
                                {props.poule.map((f2, k2) => {
                                    if (k === k2) return <td key={k2} className={`${props.tailleCellule} cellule0010 celluleGrise`}></td>
                                    else return <td key={k2} className={`${props.tailleCellule} cellule0010`}></td>
                                })}
                                <td className={`${props.tailleCellule} cellule1111 cnk-text-centered cnk-padding-top-10`}>{t("label.word.victories")}</td>
                                <td className={`${props.tailleCellule} cellule1111`}></td><td className={`${props.tailleCellule} cellule0010`}></td>
                            </tr>
                            <tr>
                                <td className={`${props.tailleCellule} cellule1011 libClub cnk-text-centered`}>{f.club}</td><td className={`${props.tailleCellule} cellule0011`}></td>
                                {props.poule.map((f2, k2) => {
                                    if (k === k2) return <td key={k2} className={`${props.tailleCellule} cellule0011 celluleGrise`}></td>
                                    else return <td key={k2} className={`${props.tailleCellule} cellule0011`}></td>
                                })}
                                <td className='cellule1111 cnk-text-centered cnk-padding-top-10'>{t("label.word.points")}</td><td className={`${props.tailleCellule} cellule1111`}></td>
                                <td className={`${props.tailleCellule} cellule0011`}></td>
                            </tr >
                        </React.Fragment>
                    })}
                </tbody>
            </table >
        </div >
    </div>
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


interface IExportModel {
    numero: number,
    position: number,
    combattant: number,
    name: string,
    club: string,
    nationality: string
}

const rowsToExport = (repartition: IApiDrawingRepartitions): Array<IExportModel> => {
    return Object.values(repartition).map((r: IApiDrawingRepartition) => {
        return {
            numero: r.numero,
            position: r.position,
            combattant: r.idFighter,
            name: r.name,
            club: r.club,
            nationality: r.nationality
        }
    });
};

export function DrawingTable(props: {
    authentication: IAuthentication
}) {
    //sessionStorage.setItem("module", "drawing");
    const { t, i18n } = useTranslation();

    const [dispatchNotification, errorComponent] = useErrorMessage();

    const [selectionComponent, selectedCompetitionId, folllowingStatus, reload] = useSelectCompetition({
        dispatchNotification: dispatchNotification
    });

    const [repartitionInteractive, setRepartitionInteractive] = useState<boolean>(true);
    const [loaded, setLoaded] = useState<{
        pouleHtml: string | undefined,
        repartition: IApiDrawingRepartitions | undefined,
        tirage: IApiReadTirage | undefined,
        fightsOrder: IApiReadTirage | undefined
    } | undefined>(undefined);
    const svgRef = useRef(null);
    const svgRefPoolForm = useRef(null);
    const svgRefTree = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return svgRef.current ?? null
        },
        onBeforeGetContent: () => {

        },
        onAfterPrint: () => {

        }
    });
    const handlePrintPoolForm = useReactToPrint({
        content: () => {
            return svgRefPoolForm.current ?? null
        },
        onBeforeGetContent: () => {

        },
        onAfterPrint: () => {

        }
    });
    const handlePrintPoolTree = useReactToPrint({
        content: () => {
            return svgRefTree.current ?? null
        },
        onBeforeGetContent: () => {

        },
        onAfterPrint: () => {

        }
    });
    const [createDraw, setCreateDraw] = useState<IDrawingForm | undefined>(undefined);
    const [reloadTimeStamp, setReloadTimeStamp] = useState<number>(Date.now());
    const [selectedModalTab, setSelectedModalTab] = useState<number>(0);
    const [listFighter, setListFighter] = useState<{
        competitionId: number | undefined,
        fighters: Array<IApiDrawingFighter>
    }>({
        competitionId: undefined,
        fighters: []
    });
    const [tree, setTree] = useState<{
        competitionId: number | undefined,
        tree: string | undefined
    }>({
        competitionId: undefined,
        tree: undefined
    });
    const [importer, setImporter] = useState<{ key: number, rows: Array<IApiImportRow> }>({
        key: Date.now(),
        rows: []
    });
    const [competition, loadCompetitionId, reloadCompetition] =
        useCompetitionLoader(dispatchNotification, selectedCompetitionId, true);
    const showCountry = false;
    useEffect(() => {
        loadCompetitionId(selectedCompetitionId);
    }, [selectedCompetitionId, loadCompetitionId])

    //Loaded for combat pannel
    useEffect(() => {
        let mounted = true;
        if (selectedCompetitionId && selectedCompetitionId > 0 && [3, 4].includes(selectedModalTab)) {
            if (listFighter.competitionId !== selectedCompetitionId) {
                listFightersCompetition(dispatchNotification, selectedCompetitionId).then(list => {
                    if (mounted) {
                        setListFighter({
                            competitionId: selectedCompetitionId,
                            fighters: Object.values(list ?? {})
                        });
                    }
                })
            }
        }
        else {
            setListFighter({
                competitionId: undefined,
                fighters: []
            });
        }
        return () => {
            mounted = false;
        }
    }, [selectedCompetitionId, dispatchNotification, listFighter.competitionId, selectedModalTab]);

    //load tree
    useEffect(() => {
        let mounted = true;
        if (loaded?.repartition) {

            if (selectedCompetitionId && selectedCompetitionId > 0 && [1].includes(selectedModalTab)
                && loaded.tirage?.competition_id === selectedCompetitionId.toString()) {
                if (tree.competitionId !== selectedCompetitionId) {
                    getTableauForSuivi(dispatchNotification, selectedCompetitionId).then(d => {
                        if (d) {
                            getSimulation2Svg(dispatchNotification, d).then(simulationStr => {
                                if (simulationStr && mounted) {
                                    simulationStr = simulationStr.replaceAll("rgb(255,0,0)", "rgb(0,0,0)");
                                    simulationStr = simulationStr.replaceAll(" 92%;", " 100%;");
                                    simulationStr = simulationStr.replaceAll("font-size  : 10;", "font-size : 8px;");
                                    simulationStr = simulationStr.replaceAll("Pool ", "");
                                    simulationStr = simulationStr.replaceAll("fighterLabel ", "drawFighterLabel");
                                    simulationStr = simulationStr.replaceAll('class="test"', 'class="test" style="margin-top: 5px;"');

                                    if (competition?.rules === "DirectDrawing") {
                                        for (const repartition of (Object.values(loaded?.repartition ?? {})) as Array<IApiDrawingRepartition>) {
                                            const reg = new RegExp(`class="fighterLabel"> ?${repartition.numero}\\.${repartition.position} ?`, "g");
                                            //let re = new RegExp('Fighter' + fightTeam.id + '<', "g");
                                            if (reg.test(simulationStr)) {


                                                let country = showCountry ? repartition.nationality?.toLowerCase() ?? "" : undefined;
                                                if (country && country.length === 3) {
                                                    country = countries.alpha3ToAlpha2(country.toUpperCase())?.toLowerCase() ?? country;
                                                    console.error(country)
                                                }
                                                let fontSize = 18;
                                                if (repartition.name.length > 30) {
                                                    fontSize = 10;
                                                }
                                                else if (repartition.name.length > 20) {
                                                    fontSize = 13;
                                                }
                                                else if (repartition.name.length > 15) {
                                                    fontSize = 15;
                                                }

                                                const name =
                                                    `<tspan dy="-0.2em" style="font-size:${fontSize}px;">${repartition.name}</tspan>`;
                                                //let re = new RegExp('@Fighter' + fightTeam.id + '@', "g");

                                                //debug(`@Fighter${fightTeam.id}@`);

                                                //transformedSvg = transformedSvg?.replace(re, name + "<");

                                                if (country && country.length > 0 && repartition.name.length > 0 && repartition.name !== "nobody") {
                                                    const countryRe = new RegExp('class="' + repartition.numero + "." + repartition.position + ' _flag"', "g");
                                                    simulationStr = simulationStr.replace(countryRe, " xlink:href='img/flags/1x1/" + country + ".svg' ");
                                                }



                                                //re = new RegExp('@Fighter' + fightTeam.id + '@', "g");

                                                simulationStr = simulationStr.replaceAll(reg, `class="fighterLabel">${name}`);

                                            }
                                            else {
                                                console.warn(`Not found ${repartition.numero}.${repartition.numero}`);
                                            }
                                        }
                                    }
                                    simulationStr = simulationStr.replaceAll("nobody", "");

                                    setTree({
                                        competitionId: selectedCompetitionId,
                                        tree: simulationStr
                                    })
                                }
                                else {
                                    setTree({
                                        competitionId: undefined,
                                        tree: undefined
                                    });
                                }
                            })
                        }
                        else {
                            setTree({
                                competitionId: undefined,
                                tree: undefined
                            });
                        }
                    })
                }
            }
            else {
                if (selectedCompetitionId === undefined || selectedCompetitionId === 0
                    || loaded.tirage?.competition_id !== selectedCompetitionId.toString()) {
                    setTree({
                        competitionId: undefined,
                        tree: undefined
                    });
                }
            }

        }
        return () => {
            mounted = false;
        }
    }, [competition, selectedCompetitionId, dispatchNotification, listFighter.competitionId, selectedModalTab, tree.competitionId, loaded, showCountry]);

    //automaticaly change tab if direct drawing
    /*seEffect(() => {
        if (selectedModalTab === 0 && competition?.rules === "DirectDrawing") {
            setSelectedModalTab(1);
        }
    }, [competition?.rules, selectedModalTab])*/

    useEffect(() => {
        let mounted = true;
        if (selectedCompetitionId && (reloadTimeStamp || loaded?.tirage?.competition_id !== selectedCompetitionId.toString())) {
            //force tree recalculation
            setTree(t => ({
                ...tree
                , competitionId: -1
            }))

            readTirage(dispatchNotification, selectedCompetitionId).then(tirage => {
                if (tirage?.id) {
                    getPouleForUI(dispatchNotification, selectedCompetitionId).then(content => {
                        getRepartition(dispatchNotification, selectedCompetitionId).then(pools => {
                            (async () => {
                                if (tirage.valide) return getFightsOrder(dispatchNotification, selectedCompetitionId);
                                else return undefined
                            })().then(fightsOrder => {
                                if (mounted) {
                                    content = content?.replace("img/", "/img/")
                                    content = content?.replaceAll("width=\"100%\"", " style='width: 95%' ")
                                    content = content?.replaceAll("width='90%'", " style='width: 90%' ")
                                    setLoaded((c) => {
                                        return {
                                            pouleHtml: content,
                                            repartition: pools,
                                            tirage: tirage,
                                            fightsOrder: fightsOrder
                                        }
                                    });
                                }
                            });
                        });
                    })
                }
                else {
                    setLoaded((c) => {
                        return {
                            pouleHtml: undefined,
                            repartition: undefined,
                            tirage: undefined,
                            fightsOrder: undefined
                        }
                    });
                }
            }).catch(e => {
                setLoaded((c) => {
                    return {
                        pouleHtml: undefined,
                        repartition: undefined,
                        tirage: undefined,
                        fightsOrder: undefined
                    }
                });
            })
        }
        return () => {
            mounted = false;
        }
    }
        , [competition, selectedCompetitionId, dispatchNotification, reloadTimeStamp]);

    const printPoulesDiv = <div className='no-print'>
        <Button variant="contained" onClick={() => handlePrintPoolForm()}>{t("action.draw.printPoolForm")}</Button>
    </div>
    const printRepartition = <div className='no-print'>
        <Button variant="contained" onClick={() => handlePrint()}>{t("action.draw.printRepartition")}</Button>
    </div>

    const pouleList: Array<TDrawingPoolRepartition> =
        Object.keys(loaded?.repartition ?? {})
            .map(r => (loaded?.repartition ?? {})[r as any])
            .reduce((poolList, repartition) => {
                if (!poolList[repartition.numero]) poolList[repartition.numero] = [];
                poolList[repartition.numero].push(repartition)
                return poolList;
            }, [] as Array<TDrawingPoolRepartition>)

    const editeableDraw = loaded?.tirage && competition?.status === "RegistrationClosed";
    const canCreateDraw = competition?.status === "RegistrationClosed";



    let taille = '100%';
    if (pouleList.length === 1) taille = "100%";
    else if (pouleList.length === 2) taille = "50%";
    else taille = "33%";

    //from draw, index begin at 1
    const groupedPouleList = pouleList.reduce((reduced, currentValue, currentIndex) => {

        return produce(reduced, draft => {
            draft[Math.floor((currentIndex - 1) / 3)].push(currentValue);
        });
    },
        new Array(1 + Math.floor(pouleList.length / 3)).fill([]) as Array<Array<TDrawingPoolRepartition>>);

    return <>
        {createDraw && <DrawingForm {...createDraw} />}
        <Grid container spacing={2}>
            <Grid item md={12}>
                {errorComponent}
                {selectionComponent}
                <Link href={(window as any)['TIRAGES_UI_URL'] + '/?event=' + selectedCompetitionId ?? 0}>Ancien écran des tirages</Link>
            </Grid>


            <Grid container item md={12}>
                <Paper elevation={2} style={{ width: "100%", height: "100%" }}>
                    <Grid container item md={12} paddingRight={5}>
                        {loaded?.tirage && <Grid item md={6}>
                            <b>Date du tirage</b>: {loaded?.tirage.created}<br />
                            <b>Type de tirage</b> : {loaded?.tirage.type}<br />

                            <b>Validation</b>  : {loaded?.tirage.valide ? t("label.draw.valid") : t("label.draw.invalid")}<br />
                            {loaded?.tirage.valide ? t("label.draw.validdescription") : t("label.draw.invaliddescription")}<br />
                        </Grid>}
                        {!loaded?.tirage && <Grid item md={6}>
                            {t("label.draw.noDraw")}
                        </Grid>}
                        <Grid item md={6}>
                            <b>{competition?.fullname}</b><br />
                            <b>Date</b> : {competition?.competitionDate}<br />
                            <b>Categorie</b> : {competition?.category}<br />
                            <b>Statut</b> : {t("label.competition.status." + (competition?.status ?? "unknown"))}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {!editeableDraw && <Grid container item md={12}>
                <Alert color="warning">{t("label.draw.notEditeableDraw")}</Alert>
            </Grid>
            }
            <Grid container item md={12}>

                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button disabled={loaded?.tirage === undefined} color={loaded?.tirage?.valide ? "warning" : "success"}
                        onClick={() => {
                            loaded?.tirage?.valide ? invalideTirage(dispatchNotification, parseInt(loaded?.tirage?.competition_id ?? '0'))
                                .then(d => {
                                    reloadCompetition();
                                    reload();
                                })
                                : valideTirage(dispatchNotification, parseInt(loaded?.tirage?.competition_id ?? '0'))
                                    .then(d => {
                                        reloadCompetition();
                                        reload();
                                    });
                        }}>{loaded?.tirage?.valide ? "Invalider le tirage" : "Valider le tirage"}</Button>
                    <Button disabled={!canCreateDraw} onClick={() => {
                        if (competition) setCreateDraw({
                            competition: competition,
                            dispatchNotifications: dispatchNotification,
                            display: true,
                            actionLabel: t("action.draw.launchDraw"),
                            onAction: (params) => {
                                postCreateDraw(dispatchNotification, {
                                    ecartclub: params.ecart === 1 ? 1 : 0,
                                    ecartregion: params.ecart === 3 ? 1 : 0,
                                    ecartpays: params.ecart === 2 ? 1 : 0,
                                    idcomp: competition.id,
                                    ecarttete: params.ecartTete,
                                    nb: params.size,
                                    nobody: 0, //MAJ du 25-10-2021 : Retrait de la dropdown //$_POST['nobody'];
                                    poule: params.type,
                                    tete: [params.tete1, params.tete2, params.tete3, params.tete4].join('-'),
                                    preferedlastsize: params.preferedlastsize
                                }).then(r => {
                                    reloadCompetition();
                                })
                                setCreateDraw(undefined);
                            },
                            onCancel: () => setCreateDraw(undefined),
                            open: true
                        })
                    }
                    }>{t("action.draw.createDraw")}</Button>
                    <Button disabled={!editeableDraw} onClick={() => {
                        if (loaded?.tirage) {
                            postRefaireTirage(dispatchNotification, loaded?.tirage.id).then(d => {
                                reloadCompetition();
                            })
                        }
                    }} >{t("action.draw.reDraw")}</Button>
                    <div>
                        <Link
                            className="button is-link"
                            href={`file/Feuille_combat_equipes.xls`}>
                            Feuille vierge combat equipes
                        </Link>
                    </div>
                    {loaded?.repartition && <CSVLink
                        className="button is-link"
                        //style={{ backgroundColor: '#4CAF50', padding: "5px", marginLeft: "10px" }}
                        style={{ height: "100%" }}
                        data={rowsToExport(loaded?.repartition ?? {})}
                        separator=";"
                        filename={`draw_${selectedCompetitionId}.csv`}
                    >Export </CSVLink>


                    }

                    <Button disabled={!editeableDraw} color="warning" onClick={() => {
                        if (editeableDraw) {
                            confirmAlert({
                                title: "Suppression du tirage",
                                message: "Voulez-vous supprimer ce tirage ?",
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () => {
                                            deleteTirage(dispatchNotification, competition.id).then(d => {

                                                reloadCompetition();
                                            })
                                        }
                                    },
                                    {
                                        label: 'No',
                                        onClick: () => {

                                        }
                                    }
                                ],
                            });

                        }
                    }} >{t("action.draw.deleteDraw")}</Button>
                </ButtonGroup>
            </Grid>
            <Grid container item md={12} className="draw_importer">
                <Importer
                    key={importer.key}
                    dataHandler={async (rows, { startIndex }) => {
                        setImporter(i => {
                            return {
                                ...i,
                                rows: [...i.rows, ...(rows as Array<IApiImportRow>)]
                            }
                        });
                    }}
                    defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={true} // optional, lets user choose to upload another file when import is complete

                    onStart={({ file, preview, fields, columnFields }) => {
                        setImporter(i => { return { ...i, rows: [] } })
                    }}
                    onComplete={({ file, preview, fields, columnFields }) => {
                        if (selectedCompetitionId) {
                            importTirage(dispatchNotification, selectedCompetitionId, importer.rows as Array<IApiImportRow>).then(d => reloadCompetition());
                        }
                        else return false;
                    }}
                    onClose={({ file, preview, fields, columnFields }) => {
                        // optional, if this is specified the user will see a "Finish" button after import is done,
                        // which will call this when clicked
                        //goToMyAppNextPage();

                        setImporter(i => {
                            return {
                                key: Date.now()
                                , rows: []
                            }
                        })



                    }}
                    skipEmptyLines={true}
                // CSV options passed directly to PapaParse if specified:
                // delimiter={...}
                // newline={...}
                // quoteChar={...}
                // escapeChar={...}
                // comments={...}
                // skipEmptyLines={...}
                // delimitersToGuess={...}
                // chunkSize={...} // defaults to 10000
                // encoding={...} // defaults to utf-8, see FileReader API

                >
                    <ImporterField name="numero" label="numero" optional={false} />
                    <ImporterField name="position" label="position" optional={false} />
                    <ImporterField name="combattant" label="combattant" optional={false} />

                </Importer>
            </Grid>

            <Grid container item md={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedModalTab} onChange={(event: React.SyntheticEvent, newValue: number) => {
                        setSelectedModalTab(newValue);
                    }} aria-label="basic tabs example">
                        <Tab label="Poules" {...a11yProps(0)} />
                        <Tab label="Arbre" {...a11yProps(1)} />
                        <Tab label="Repartition" {...a11yProps(2)} />
                        <Tab label="Fight Order" {...a11yProps(3)} />
                        <Tab label="Combattants" {...a11yProps(4)} />

                    </Tabs>
                </Box>
            </Grid>
            {selectedModalTab === 0 && <>
                <Grid container item md={12}>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        {printPoulesDiv}
                        {printRepartition}
                        <Button variant="contained" color="secondary" onClick={() => setRepartitionInteractive(!repartitionInteractive)}>
                            {repartitionInteractive ? t("action.draw.disableInteractive") : t("action.draw.enableInteractive")}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => {
                            if (selectedCompetitionId) {
                                refreshFighters(dispatchNotification, selectedCompetitionId).then(d => {
                                    if (d.succeed) {
                                        setReloadTimeStamp(Date.now());
                                    }
                                });
                            }
                        }}>
                            {t("action.draw.refreshFighters")}
                        </Button>
                    </ButtonGroup>
                </Grid>
                {repartitionInteractive === true && <Grid item md={12}>
                    <Alert color="info">
                        {t("label.draw.interactiveDescription")}
                    </Alert>
                </Grid>}
                <Grid item md={12} ref={svgRef} className="printPagesDraw">
                    <style type="text/css" media="print">{"@page { size: portrait; }"}</style>
                    {repartitionInteractive === false && <div className="draw_view " dangerouslySetInnerHTML={{ __html: loaded?.pouleHtml ?? "" }} />}
                    {repartitionInteractive === true && <div className="draw_view">
                        <div className="cnk-print">
                            <div className="column is-full">
                                <table className="table-borderless" align="center" width="100%">
                                    <tbody>
                                        <tr>
                                            <td rowSpan={2} width="15%" className="cnk-text-centered cellule35" ><img src="img/logo_print.png" width="100px" /></td>
                                            <td rowSpan={2} className="cnk-titre-3 cnk-text-centered cnk-text-middle cellule35">{competition?.fullname}</td>
                                            <td width="15%" className="cnk-text-centered cellule35" >{t("label.competition.Date")}</td>
                                            <td width="15%" className="cnk-text-centered cellule35" >{(new Date(competition?.competitionDate ?? '')).toLocaleDateString()}</td>
                                        </tr>
                                        <tr>
                                            <td className="cnk-text-centered" >{t("label.competition.Category")} : </td>
                                            <td className="cnk-text-centered" >{competition?.category}</td>
                                        </tr>
                                    </tbody>
                                </table><br /><br />
                            </div>
                            <div className="columns is-multiline">
                                <DndProvider backend={HTML5Backend}>
                                    <table className="table-borderless" align="center" style={{ width: "95%" }}>
                                        <tbody>
                                            {
                                                groupedPouleList.map((poolGroup: Array<TDrawingPoolRepartition>, k) => {
                                                    return <React.Fragment key={k}>
                                                        <tr>
                                                            {poolGroup.map((pool: TDrawingPoolRepartition, k2) => {
                                                                return <td width={taille} align='center' key={k2}>
                                                                    <table cellPadding='0' cellSpacing='0' className='table is-bordered' style={{ width: "90%" }}>
                                                                        <tbody>
                                                                            <tr ><td align='center' className='celluleGrise' ><b>Poule {k * 3 + k2 + 1}</b></td></tr>
                                                                            {pool.map((fighter, k3) => {
                                                                                return <tr key={k3}>
                                                                                    <td align='center'
                                                                                    >
                                                                                        {selectedCompetitionId && <DrawingTableRepartitionFighter
                                                                                            fighter={fighter}
                                                                                            competitionId={selectedCompetitionId}
                                                                                            dispatchNotification={dispatchNotification}
                                                                                            onChange={() => setReloadTimeStamp(Date.now())}
                                                                                        />}
                                                                                    </td>
                                                                                </tr>
                                                                            })}
                                                                        </tbody>

                                                                    </table>
                                                                </td>
                                                            })}
                                                        </tr>
                                                        {/* {k > 0 && k % 2 === 0 && <td width={taille} align='center'></td>} */}

                                                    </React.Fragment>
                                                })}
                                        </tbody>
                                    </table>
                                </DndProvider>

                            </div>
                        </div>
                    </div>
                    }
                    {/* 
                        
        $printRecap = '';
        $printRecap .= '
            ';

        //Calcul du nombre de poule
        foreach ($resultRepartition as $value) {
            $nbPoule = $value['numero'];
        }
        if ($nbPoule == 1) $taille = "100%";
        else if ($nbPoule == 2) $taille = "50%";
        else $taille = "33%";

        $pouleTmp = 0;
        $iCpt = 0;
        foreach ($resultRepartition as $value) {

            //debug($value);
            $poule = $value['numero'];

            if ($poule != $pouleTmp) {
                if ($pouleTmp > 0) {
                    $printRecap .= "</table><br /></td>";
                    $iCpt++;
                }
                if ($iCpt == 3) $printRecap .=  "</tr><tr>";

                if ($iCpt == 6) {
                    $printRecap .=  "<div class='sautPage'></div></tr><tr>";
                    $iCpt = 0;
                }

                $printRecap .=  "<td width='" . $taille . "' align='center'>";
                $printRecap .=  "<table cellpadding='0' cellspacing='0' class='table is-bordered' width='90%'>";
                $printRecap .=  "<tr ><td align='center' class='celluleGrise' ><b>Poule " . $poule . "</b></td></tr>";
            }
            $printRecap .=  "<tr>
                                <td align='center'>
                                    <span class='libLicencie'>" . $value['name'] . "</span><br />
                                    <span class='libClub'>" . (($value['club']) ? $value['club'] : "<br/>") . "</span></td></tr>";
            $pouleTmp = $poule;
        }
        $printRecap .=  "";
        return $printRecap;
                        */}
                </Grid>

                <Grid item md={12}>
                    <div ref={svgRefPoolForm}>
                        <style type="text/css" media="print">{"@page { size: landscape; }"}</style>
                        {competition && pouleList.map((poule, k) => {
                            let entete = ""
                            let tailleCellule = ""
                            if (poule.length === 3) {
                                entete = `1x2 - 1x3 - 2x3`;
                                tailleCellule = 'cellule35';
                            }
                            if (poule.length === 4) {
                                entete = `1x2 - 3x4 - 1x4 - 1x3 - 2x3 - 2x4`;
                                tailleCellule = 'cellule30';
                            }
                            if (poule.length === 5) {
                                entete = `1x2 - 3x4 - 1x5 - 2x3 - 4x5 - 1x3 - 2x5 - 1x4 - 3x5 - 2x4`;
                                tailleCellule = 'cellule25';
                            }

                            return <React.Fragment key={k}>
                                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                    {printPoulesDiv}{printRepartition}
                                </ButtonGroup>
                                <Poule
                                    competition={competition}
                                    entetePoule={entete}
                                    poule={poule}
                                    tailleCellule={tailleCellule}
                                    position={poule[0].numero}
                                    key={k}
                                />
                            </React.Fragment>
                        })}
                    </div>
                </Grid>
            </>
            }
            {selectedModalTab === 2 && <>
                <Grid item md={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Numéro de poule</TableCell>
                                    <TableCell >Position</TableCell>
                                    <TableCell >Nom</TableCell>
                                    <TableCell >Nationnalité</TableCell>
                                    <TableCell >Club</TableCell>
                                    <TableCell >Identifiant</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {Object.values(loaded?.repartition ?? {}).map((repartition: IApiDrawingRepartition) => {
                                    return <TableRow key={`${repartition.numero}.${repartition.position}`}>
                                        <TableCell >{repartition.numero}</TableCell>
                                        <TableCell >{repartition.position}</TableCell>
                                        <TableCell >{repartition.name}</TableCell>
                                        <TableCell >{repartition.nationality}</TableCell>
                                        <TableCell >{repartition.club}</TableCell>
                                        <TableCell >{repartition.id}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>
            }
            {selectedModalTab === 3 && <>
                <Grid item md={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Order</TableCell>
                                    <TableCell >Rouge</TableCell>
                                    <TableCell >Blanc</TableCell>
                                    <TableCell >Type</TableCell>
                                    <TableCell >Numéro</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loaded?.tirage?.valide !== 1 && <TableRow>
                                    <TableCell colSpan={5}>{t('label.draw.fightOrderOnlyIfValid')}</TableCell>
                                </TableRow>}
                                {Object.values(loaded?.fightsOrder ?? {}).map((fo: IApiFightOrder) => {
                                    return <TableRow key={fo.numero + "." + fo.ordre}>
                                        <TableCell >{fo.ordre}</TableCell>
                                        <TableCell className="has-text-white has-background-danger-dark">{listFighter ? listFighter.fighters.find(f => f.id_fighter === fo.idFighterRed)?.name : fo.idFighterRed}</TableCell>
                                        <TableCell className="has-text-danger-dark has-background-white-bis">{listFighter ? listFighter.fighters.find(f => f.id_fighter === fo.idFighterWhite)?.name : fo.idFighterRed}</TableCell>
                                        <TableCell >{fo.type}</TableCell>
                                        <TableCell >{fo.numero}</TableCell>

                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>
            }
            {selectedModalTab === 4 && <>
                <Grid item md={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Identifiant tirage</TableCell>
                                    <TableCell >Identifiant fighter</TableCell>
                                    <TableCell >Nom</TableCell>
                                    <TableCell >Nationalité</TableCell>
                                    <TableCell >Club</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {listFighter.fighters.map((fighter: IApiDrawingFighter) => {
                                    return <TableRow>
                                        <TableCell >{fighter.id}</TableCell>
                                        <TableCell >{fighter.id_fighter}</TableCell>
                                        <TableCell >{fighter.name}</TableCell>
                                        <TableCell >{fighter.nationality}</TableCell>
                                        <TableCell >{fighter.club}</TableCell>

                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>}
            {selectedModalTab === 1 && <>
                {tree.tree && <>
                    <Grid item md={12}>
                        <Button variant="contained" onClick={() => handlePrintPoolTree()}>{t("action.draw.printtree")}</Button>
                    </Grid>
                    <Grid item md={12}>
                        <div ref={svgRefTree} className="draw_view " dangerouslySetInnerHTML={{ __html: tree.tree ?? "" }} />
                    </Grid>
                </>}
            </>
            }
        </Grid >
    </>
}