import { apidelete, apiget, apipatch, apipost, apiput, IApiError, IApiResult, IApiResultStatus, manageApiError } from "../../util/ApiCall";
import { IServiceTableNotification } from "../Util/ErrorMessage";

export function deleteApiObject<T extends IApiResult>(root: string, id: number | null, emptyErrorResult: T,
  dispatchNotification?: React.Dispatch<IServiceTableNotification>,
  host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost", errorlabel = "", token?: string) {

  return apidelete<T>(root + (id ? "/" + id : ""), host, undefined, token)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ operation: "delete", type: 'success', message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {
      if (dispatchNotification) dispatchNotification({
        operation: "delete",
        type: 'error', message: (errorlabel ? errorlabel + "." : "") + res.errorLabel,
        default: res.errorMessage
      });
      return emptyErrorResult;
    });
}

export function getApiObject<T extends IApiResult>(root: string, id: number | null, emptyErrorResult: T,
  dispatchNotification?: React.Dispatch<IServiceTableNotification>,
  host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost", errorlabel = "", token?: string, checkJson?: "string" | "json" | "file", authorization?: boolean): Promise<T> {

  return apiget<T>(root + (id ? "/" + id : ""), host, undefined, undefined, token, checkJson, authorization)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ operation: "read", type: 'success', message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {

      if (dispatchNotification) {
        if (res.status === 401 || res.status === 403) dispatchNotification({ operation: "read", type: 'error', message: (errorlabel ? errorlabel + "." : "error.") + "authorization" });
        else dispatchNotification({
          operation: "read",
          type: 'error', message: (errorlabel ? errorlabel + "." : "") + (res.errorLabel !== "undefined" ? res.errorLabel : (res.errorMessage)),
          default: res.errorMessage
        });
      }
      return emptyErrorResult;
    })
}

export function putApiObject<T extends IApiResult>(root: string, id: number | null, data: any, emptyErrorResult: T,
  dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined,
  host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost", errorlabel = "", token?: string): Promise<T> {

  return apiput<T>(root + (id ? "/" + id : ""), data, host, undefined, token)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed && dispatchNotification) {
        dispatchNotification({ operation: "update", type: 'success', message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {

      if (dispatchNotification) dispatchNotification({
        operation: "update",
        type: 'error',
        message: (errorlabel ? errorlabel + "." : "") + res.errorLabel,
        default: res.errorMessage
      });
      return emptyErrorResult;
    })
}



export function postApiObject<T extends IApiResultStatus>(path: string, data: any, emptyErrorResult: T,
  dispatchNotification?: React.Dispatch<IServiceTableNotification>,
  host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost", errorlabel = "", token?: string): Promise<T> {

  return apipost<T>(path, data ? data : {}, host, undefined, token)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ operation: "create", type: 'success', message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {
      if (dispatchNotification) dispatchNotification({
        operation: "create",
        type: 'error', message: (errorlabel ? errorlabel + "." : "") + res.errorLabel,
        default: res.errorMessage
      });
      return {
        ...emptyErrorResult,
        status: res.status,
      }
    })
}

export function postApiFormData(path: string, data: FormData, emptyErrorResult: { data: string, succeed: boolean, filename:string },
  dispatchNotification?: React.Dispatch<IServiceTableNotification>,
  host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost", errorlabel = "", token?: string, timeout?: number)
  : Promise<{ data: string, succeed: boolean, filename:string }> {

  return apipost<{ data: string, succeed: boolean, filename:string }>(path, data, host, undefined, token, "file", timeout)
    .then((res: { data: string, succeed: boolean, filename:string } | IApiError) => {
      return manageApiError<{ data: string, succeed: boolean, filename:string }>(res)
    })
    .then((res: { data: string, succeed: boolean, filename:string }) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ operation: "create", type: 'success', message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {
      if (dispatchNotification) dispatchNotification({
        operation: "create",
        type: 'error', message: (errorlabel ? errorlabel + "." : "") + res.errorLabel,
        default: res.errorMessage
      });
      return {
        ...emptyErrorResult,
        status: res.status,
      }
    })
}


export function patchApiObject<T extends IApiResult>(path: string, data: any, emptyErrorResult: T,
  dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined,
  host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost", errorlabel = "", token?: string): Promise<T> {

  return apipatch<T>(path, data !== undefined ? data : {}, host, undefined, token)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ operation: "update", type: 'success', message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {
      if (dispatchNotification) dispatchNotification({
        operation: "update",
        type: 'error', message: (errorlabel ? errorlabel + "." : "") + res.errorLabel,
        default: res.errorMessage
      });
      return emptyErrorResult;
    })
}