import { Grid } from '@mui/material';
import React from 'react';
import { useErrorMessage } from '../Util/ErrorMessage';
import { BackendConfig } from './BackendConfig';




export function ParametreTable() {
    //sessionStorage.setItem("module", "parametre");
    const [dispatchNotification, errorComponent] = useErrorMessage();
    return <>
        <Grid container spacing={2}>
            <Grid item md={12}>
                {errorComponent}
            </Grid>
            <Grid item md={12}>
                <h2>Paramètres de notification</h2>
            </Grid>
            <Grid item md={12}>
                <BackendConfig />
            </Grid>
        </Grid>
    </>
}