import DeleteIcon from '@mui/icons-material/Delete';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChronoState, TChronoCommand } from '../Util/Chronometer';
import { Fighter } from './Fighter';
import { Ippons } from './Ippons';
import { RoundAction } from './RoundAction';
import { EFighterColor, IActionEvent } from './Shiajo';

import { HelpOutline } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import { useDrop } from "react-dnd";
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiDataSyncCall } from '../../util/ApiDataSync';
import { ERoundEventTypeModel, IApiRoundEventCreationModel, IApiRoundEventModel, IApiRoundEventResult } from '../../util/ApiModel/ApiEventModel';
import { EFightType, IApiFightModel } from '../../util/ApiModel/ApiFightModel';
import { IApiFighterModel } from '../../util/ApiModel/ApiFighterModel';
import { ERoundStatus, IApiRoundModel, IApiRoundResult } from '../../util/ApiModel/ApiRoundModel';
import { addEvent } from '../Operation/EventOperation';
import { deleteApiObject, getApiObject } from '../Operation/GenericOperation';
import { TSizeType, useScreenSize } from '../Use/UseScreenSize';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { StrikeDropItem, StrikeType } from '../Util/IpponSvg';
import { shouldReloadFight } from '../Util/ReloadRules';
import { IFightActions } from './Fight';
import { RoundEventList } from './RoundEventList';
import { RoundStatus } from './RoundStatus';
var debug = require('debug')('ServiceTablesMinimalFrontend:Round')

var moment = require('moment');

export interface IRoundEventProps {
  fighterId?: number;
  type: ERoundEventTypeModel;
  date: Date;
  id: number;
  roundId: number;
  points: number;
}

export interface IRoundEventState extends IRoundEventProps, IApiDataSyncCall {

}



export interface IRoundProps {
  data: IApiRoundModel,
  selectRound(roundId: number): Promise<IApiFightModel>,
  roundSelectLock: boolean,
  fightActions: IFightActions,
  dispatchNotification: React.Dispatch<IServiceTableNotification>,
  dispatchChrono: (command: TChronoCommand, dateStart?: Date, datePause?: Date, run?: boolean, duration?: number, message?: string) => void,
  //dispatchModal: React.Dispatch<IModalAction>,
  //checkUpdateFight: (newRoundState: IApiRoundModel) => Promise<IApiFightModel>,
  keyOrder: number,
  active: boolean,
  fightActive: boolean,
  fightType: EFightType,
  withEncho: boolean,
  editeable?: boolean,
  redFighterSelecteableList?: Array<IApiFighterModel>,
  whiteFighterSelecteableList?: Array<IApiFighterModel>,
  competition: IApiCompetitionModel,
  chronoState?: ChronoState,
  simpleActions: boolean,
  descDetails: boolean
}


export function chronoAction(event: IActionEvent, dispatchChrono: (command: TChronoCommand, date?: Date, run?: boolean) => void) {
  switch (event.type) {
    case ERoundEventTypeModel.PAUSE:
      dispatchChrono("PAUSE");
      break;
    case ERoundEventTypeModel.START:
      dispatchChrono("START");
      break;
    case ERoundEventTypeModel.END:
      dispatchChrono("END");
      break;
  }
}

function createEventData(round: IApiRoundModel, event: IActionEvent
  , dispatchNotification: React.Dispatch<IServiceTableNotification>): IApiRoundEventCreationModel | undefined {
  let data = undefined;
  switch (event.type) {
    case ERoundEventTypeModel.DO:
    case ERoundEventTypeModel.MEN:
    case ERoundEventTypeModel.KOTE:
    case ERoundEventTypeModel.SUNE:
    case ERoundEventTypeModel.ASHI:
    case ERoundEventTypeModel.IPON:
    case ERoundEventTypeModel.FUSENGACHI:
    case ERoundEventTypeModel.TSUKI:
    case ERoundEventTypeModel.HANSOKU:
    case ERoundEventTypeModel.HANTEI:


      data = {
        type: event.type,
        //apiSync: EApiSync.pending,
        date: new Date()
      }

      let fighterId = (event.fighterColor === EFighterColor.white ? round.whiteFighterId : round.redFighterId);
      debug("Event creation : " + JSON.stringify(event) + " for round " + JSON.stringify(round) + ", fighterId : " + fighterId);
      if (fighterId) {
        data = Object.assign(data, { fighterId: fighterId });
      }

      break;
    case ERoundEventTypeModel.PAUSE:
    case ERoundEventTypeModel.START:
    case ERoundEventTypeModel.END:
    case ERoundEventTypeModel.ENCHO:
      data = {
        type: event.type,
        //apiSync: EApiSync.pending,
        date: new Date()
      }
      break;
    default:
      dispatchNotification({ operation: "create", type: "error", message: "label.error.functionNotCoveredYet" })
      break;
  }
  debug("Event data to be send : " + JSON.stringify(data));
  return data;
}

async function deleteEvent(
  round: IApiRoundModel
  , eventId: number
  , reloadRound: () => void
  , dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<IApiRoundModel> {

  return new Promise<IApiRoundModel>((resolve, reject) => {
    let result = { ...round };
    return deleteApiObject<IApiRoundEventResult>('/RoundEvents', eventId, {
      data: {
        type: ERoundEventTypeModel.UNDEFINED,
        date: new Date(),
        id: 0,
        roundId: 0,
        points: 0
      },
      succeed: false,
      status: 400
    }, dispatchNotification).then((eventResult) => {
      if (eventResult.succeed) {
        result.events = result.events?.filter((event) => { return event.id !== eventId });
        reloadRound();
        resolve(result);
      }
      else {
        confirmAlert({
          title: "Forcer la suppression ?",
          message: 'Impossible de supprimer l\'évènement, peut être le round est il terminé ? Voulez vous forcer la suppression ?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                deleteApiObject<IApiRoundEventResult>('/RoundEvents/' + eventId + "?noCheck=true", null, {
                  data: {
                    type: ERoundEventTypeModel.UNDEFINED,
                    date: new Date(),
                    id: 0,
                    roundId: 0,
                    points: 0,
                  },
                  succeed: false,
                  status: 400
                }).then((eventResult) => {
                  if (eventResult.succeed) {
                    result.events = result.events?.filter((event) => { return event.id !== eventId });
                    reloadRound();
                    resolve(result);
                  }
                });
              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        })

      }
    })
  });
}

async function addRoundEvent(
  round: IApiRoundModel
  , event: IActionEvent
  //, dispatchFight: React.Dispatch<IFightAction>
  , fightActions: IFightActions
  , dispatchChrono: (command: TChronoCommand) => void
  , dispatchNotification: React.Dispatch<IServiceTableNotification>
  , allowFightReload: boolean = true): Promise<[IApiRoundModel, boolean]> {


  let data: IApiRoundEventCreationModel | undefined = createEventData(round, event, dispatchNotification);
  let succeed = false;
  //data is prepared, transform it as event data
  if (data) {
    //add event
    if (await addEvent(round, data, dispatchNotification)) {
      //add event succeed, reload round
      let newround = (await getApiObject<IApiRoundResult>('/rounds', round.id, {
        data: round,
        succeed: false
      }, dispatchNotification)).data;

      //dispatchChrono
      chronoAction(event, dispatchChrono);
      //console.log("Round : " + JSON.stringify(round));
      //trigger fight reload
      if (allowFightReload && shouldReloadFight(round, newround)) {
        //dispatchFight({ type: "POST_ROUND_UPDATE", data: newround });
        fightActions.postRoundUpdate();
        //checkUpdateFight(round);
      }
      round = newround;
      succeed = true;
    }

    /*
    //new state should have events because we had the new one before
    if (newstate.events.length > 0) {
      //on remplace l'evenement du state par 
      //@ts-ignore
      let lastEvent: IRoundEventState = newstate.events.pop();
      let newLastEvent = Object.assign(lastEvent, result);
      newstate.eventPushList.pop();

      if (result.succeed) {
        newstate.events.push(newLastEvent);
      }
      if (!result.succeed) {
        //newstate.eventPushList.push(newLastEvent);
      }

      this.persistStorage('eventPushList', newstate.eventPushList);
    }

    if (result.succeed) {
      //update round state
      //@ts-ignore
      let roundResult: IRoundState = result.round;
      console.log("After api call for event " + this.props.id + " : " + roundResult);
      this.setState(Object.assign(newstate, roundResult));

      //it will impact fight group, reload
      if (event.type === ERoundEventTypeModel.END) {
        this.props.reload()
      }

    }
  }
);*/

  }
  return [round, succeed];
}



export function Round(props: IRoundProps) {
  const { t, i18n } = useTranslation();
  const [round, setRound] = useState(props.data);
  const [chronoSetted, setChronoSettet] = useState<boolean>(false);
  const sizeType: TSizeType = useScreenSize();


  //delete event drop
  const [{ isOver }, deleteDropRef] = useDrop(() => ({
    accept: StrikeType.STRIKE,
    drop: (item: StrikeDropItem, monitor) => {
      let e = { ...item.event };//do not change the original item
      deleteEvent(round, e.id, reloadRound, props.dispatchNotification);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      source: monitor.getItem()
    }),
  }), []);

  const reloadRound = useCallback(() => {
    debug("Reload round");
    getApiObject<IApiRoundResult>('/rounds', round.id, {
      data: round,
      succeed: false
    }, props.dispatchNotification).then((result) => {
      if (result.succeed) {
        //console.log("Round : " + JSON.stringify(round));
        if (shouldReloadFight(round, result.data)) {
          //props.dispatchFight({ type: "POST_ROUND_UPDATE", data: round });
          props.fightActions.postRoundUpdate();
          //checkUpdateFight(round);
        }
        //note : fight will only reload fight status, but won't change round if already loaded
        //ensure sort
        result.data.events?.sort((r1, r2) => (r1.date > r2.date ? 1 : (r1.date < r2.date ? -1 : 0)))
        setRound(result.data);
        setChronoSettet(false);
      }
    })
  }, [round, props, setRound, setChronoSettet]);



  //this.handleAddEvent = this.handleAddEvent.bind(this);
  //let eventPushList: IRoundEventState[] = this.loadStorage('eventPushList', []);
  //this.state = Object.assign({}, props, { eventPushList: eventPushList });


  /*
    componentDidUpdate() {
      if (round.id !== round.id) {
        this.setState((state, props) => {
          return Object.assign(state, props);
        })
      }
    }*/


  let roundEventAction: JSX.Element | null = null;
  let roundMainAction = null;

  const active = props.active;
  const dispatchChrono = props.dispatchChrono;

  useEffect(() => {
    debug("UseEffect");
    if (round) {
      if (round.events && active && !chronoSetted) {

        let running = false;
        let pauseDate: number = Date.now();
        let startDate: number = Date.now();
        round.events.forEach((e: IApiRoundEventModel) => {
          let d = new Date(e.date).getTime();
          switch (e.type) {
            case ERoundEventTypeModel.START:
              startDate = pauseDate ? (startDate + d) - pauseDate : d;
              running = true;
              break;
            case ERoundEventTypeModel.PAUSE:
              pauseDate = d;
              running = false;
              break;
          }
        });
        let message = "";
        let duration: number | undefined = round.combatTime;
        if (round.events.find(e => e.type === ERoundEventTypeModel.ENCHO)) {
          message = "Encho";
          duration = undefined;
        }
        dispatchChrono("SET", new Date(startDate), new Date(pauseDate), running, duration, message);
        setChronoSettet(true);
      }
    }
  }, [round, active, dispatchChrono, chronoSetted, props, props.data.reloadKey, round.events]);

  if (props.fightActive && props.editeable === false) {//editeable mean not ready
    if (props.active) {
      debug("Round status" + round.status);
      //ALLOW ACTIONS
      switch (round.status) {
        case ERoundStatus.INPROGRESS:
        case ERoundStatus.EXTENSION:
        case ERoundStatus.PAUSE:
        case ERoundStatus.WAIT:
          //let roundEventActionProps: RoundActionProps = Object.assign({}, round, { handleAddEvent: handleAddEvent })
          //addEvent
          roundEventAction =
            <RoundAction round={round}
              simpleActions={props.simpleActions}
              dispatchNotification={props.dispatchNotification}
              competition={props.competition}
              fightType={props.fightType}
              withEncho={props.withEncho}
              reload={reloadRound}
              chronoState={props.chronoState}
              addEvent={(event: IActionEvent, loadRound: boolean = true) => {
                return addRoundEvent(round, event, props.fightActions, props.dispatchChrono
                  , props.dispatchNotification, loadRound).then(([round, succeed]) => {
                    if (loadRound) setRound(round);
                    return [round, succeed];
                  })
              }}
            />
          break;
        case ERoundStatus.FINISHED:
        default:
          break;
      }
      roundMainAction = <span>Round {round.order + 1}
        <Tooltip title={`${round.id}`}>
          <HelpOutline />
        </Tooltip>
      </span>;
    } else {
      //round not active
      if (!props.roundSelectLock) {
        //round is not locked, go on
        switch (round.status) {
          case ERoundStatus.INPROGRESS:
          case ERoundStatus.EXTENSION:
          case ERoundStatus.WAIT:
          case ERoundStatus.PAUSE:
            roundMainAction = <Button variant='contained' size='small' onClick={() => props.selectRound(round.id)}>
              {t('action.round.select')} - {t('label.word.round')} {round.order}
            </Button>;
            break;
          default:
            roundMainAction = <div>{t('label.round.finished')}<Tooltip title={`${round.id}`}>
              <HelpOutline />
            </Tooltip></div>
        }
      }
      else {
        //round is locked
        roundMainAction = <div>{t('label.round.cantSelectRound')} <Tooltip title={`${round.id}`}>
          <HelpOutline />
        </Tooltip></div>
      }

    }
  } else {
    //round is not active
    roundMainAction = <p className="text-secondary">{t('label.fight.noactive')}<Tooltip title={`${round.id}`}>
      <HelpOutline />
    </Tooltip></p>
  }


  let eventList = null;

  if (props.active) {
    //list of event for edit and delete
    eventList = <RoundEventList
      dispatchNotification={props.dispatchNotification}
      round={round}
      reloadRound={reloadRound}
      /*dispatchModal={props.dispatchModal}*/
      deleteEvent={(eventId: number) => {
        return deleteEvent(round, eventId
          , reloadRound
          , props.dispatchNotification).then((round) => {
            setRound(round);
            return round;
          });
      }} />
  }

  const roundContentCells = <>
    {/*Red fighter cell*/}
    <td className='red'><Fighter data={round.redFighter}
      changeFighter={(fighterId: number, previousFighterId: number) => {
        //props.dispatchFight({ ...action, id: props.data.id })
        props.fightActions.changeFighter(props.data.id, fighterId, previousFighterId);
      }}
      reload={props.fightActions.reload}
      editeable={props.editeable}
      fighterSelecteableList={props.redFighterSelecteableList} /></td>

    {/*Ippons display*/}
    <td className="ippon shiajo_round">
      {roundEventAction}

      <DeleteIcon
        ref={deleteDropRef}
        style={isOver ? {
          opacity: 0.5,
          backgroundColor: 'yellow',
        } : undefined}
      />

      <Ippons
        dispatchNotification={props.dispatchNotification}
        showEncho={props.fightType !== EFightType.ADDITIONAL}
        showAllWarns={true}
        round={round}
        reload={reloadRound}
        competition={props.competition}
        interactive={true}
      />
    </td>
    {/*White fighter cell*/}
    <td className='white'>
      <Fighter
        data={round.whiteFighter} editeable={props.editeable}
        changeFighter={(fighterId: number, previousFighterId: number) => {
          //props.dispatchFight({ ...action, id: props.data.id })
          props.fightActions.changeFighter(props.data.id, fighterId, previousFighterId);
        }}
        reload={props.fightActions.reload}
        fighterSelecteableList={props.whiteFighterSelecteableList} /></td>
  </>

  return <>

    <tr key={round.id}>
      {/*Round ID*/}
      <td colSpan={((sizeType < 15) ? 1 : 2) + (props.descDetails ? 0 : 1)}>
        {roundMainAction}
      </td>
      {/*Round Status*/}
      <td>
        <RoundStatus status={round.status} />
      </td>
      {(sizeType >= 17) && <>
        {roundContentCells}
        <td className="border">
          {eventList}
        </td>
      </>}
    </tr >
    {/*Small screen display*/}
    {(sizeType === 15) && <>
      <tr>
        {roundContentCells}
      </tr>
      <tr >
        {/*Event list*/}
        <td className="border" colSpan={3}>
          {eventList}
        </td>
      </tr>
    </>
    }

  </>;
}