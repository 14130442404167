import { Grid } from '@mui/material';
import React from 'react';
import { IAuthentication } from '../Auth/auth';
import { MainScheduller } from '../Scheduller/MainScheduller';
import { useErrorMessage } from '../Util/ErrorMessage';




export function CentraleTable(props:{authentication: IAuthentication}) {
    //sessionStorage.setItem("module", "tablecentrale");
    const [dispatchNotification, errorComponent] = useErrorMessage();
    return <>
        <Grid container spacing={2}>
            <Grid item md={12}>
                {errorComponent}
            </Grid>
            {/* <Grid item md={12}>
                <h2>Table Cenrale</h2>
            </Grid> */}
            <Grid item md={12}>
                <MainScheduller
                    authentication={props.authentication}
                />
            </Grid>
        </Grid>
    </>
}