import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { EFightType, IApiFightModel } from '../../util/ApiModel/ApiFightModel';
import { IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { FightStatus } from '../Table/FightStatus';
import { convertEvents } from '../Util/roundUtil';
import { CountryFlagScreen } from './CountryFlagScreen';
import { FightNotReadyScreen } from './FightNotReadyScreen';
import { RoundScreen } from './RoundScreen';
import { IShiajoScreenConfig } from './ShiajoScreen';

var debug = require('debug')('ServiceTablesMinimalFrontend:FightScreen')

export function FightScreen(props: { fight: IApiFightModel, active: boolean, config: IShiajoScreenConfig, competition: IApiCompetitionModel }) {
    const { t, i18n } = useTranslation();

    let redEvents = props.fight.rounds?.map((r, k) => {
        return <React.Fragment key={k}>
            {convertEvents(r.redFighterId, r.whiteFighterId, r.events ?? []).redEvents.ippons?.map((i, k) => {
                return <React.Fragment key={k}>&nbsp;{i.label}&nbsp;</React.Fragment>
            })}
        </React.Fragment>
    })

    let whiteEvents = props.fight.rounds?.map((r, k) => {
        return <React.Fragment key={k}>
            {convertEvents(r.redFighterId, r.whiteFighterId, r.events ?? []).whiteEvents.ippons?.map((i, k) => {
                return <React.Fragment key={k}>&nbsp;{i.label}&nbsp;</React.Fragment>
            })}
        </React.Fragment>
    })

    //if not active or not round loaded, display summary
    return <>
        {//is additionnal, display it
            props.fight.type === EFightType.ADDITIONAL && <tr className="shiajo-additional">
                <td colSpan={3}></td>
                <td colSpan={1}>
                    {t("label.fight.additionnal")}
                </td>
                <td colSpan={3}></td>
            </tr>}


        {//if rounds a re not loaded (summary display), or if fight is not the current one
            (props.active === false || (!props.fight.rounds)) && <>
                {
                    //if more thant one round, summmary
                    ((!props.fight.rounds) || (props.fight.rounds && props.fight.rounds?.length > 1)) && <>
                        <tr>
                            <td colSpan={3} className={props.active ? "fighterteamName" : "fighterteamName-Small"}>
                                {props.config.countryFlag && <CountryFlagScreen style={{}} country={props.config.whiteLeft ? props.fight.whiteFightTeam.nationality : props.fight.redFightTeam.nationality} />}
                                {props.config.whiteLeft ? props.fight.whiteFightTeam?.name : props.fight.redFightTeam?.name}
                            </td>
                            <td colSpan={1} className={"fightResult"}>
                                <Row>
                                    <Col md={3}>

                                        {props.config.whiteLeft ? whiteEvents : redEvents}
                                    </Col>
                                    <Col md={6}>
                                        <FightStatus status={props.fight.status} />
                                        {props.fight.type === EFightType.ADDITIONAL && " - " + t("label.fight.additionnal")}
                                    </Col>
                                    <Col md={3}>
                                        {props.config.whiteLeft ? redEvents : whiteEvents}
                                    </Col>
                                </Row>
                            </td>
                            <td colSpan={3} className={props.active ? "fighterteamName" : "fighterteamName-Small"}>
                                {props.config.countryFlag && <CountryFlagScreen style={{}} country={props.config.whiteLeft ? props.fight.redFightTeam.nationality : props.fight.whiteFightTeam.nationality} />}
                                {props.config.whiteLeft ? props.fight.redFightTeam?.name : props.fight.whiteFightTeam?.name}
                            </td>
                        </tr>
                    </>
                }

                {//not active and only on round, display
                    props.fight.rounds?.length === 1 && <>

                        {!props.fight.ready && <FightNotReadyScreen fight={props.fight} config={props.config} />}
                        {props.fight.ready && <RoundScreen additionnal={props.fight.type === EFightType.ADDITIONAL} {...props.fight.rounds[0]} active={false} config={props.config} competition={props.competition} />}
                    </>}

            </>
        }

        {//fight is active and rounds are loaded
            props.active && !props.fight.ready && <FightNotReadyScreen fight={props.fight} config={props.config} />}
        {
            props.active && props.fight.ready && props.fight.rounds?.sort((a: IApiRoundModel, b: IApiRoundModel) => {
                //if (a.id === fight.currentRoundId) return -1;
                //if (b.id === fight.currentRoundId) return 1;
                if (a.order > b.order) return 1;
                if (b.order > a.order) return 1;
                return -1;
            }).map((round: IApiRoundModel, k: number) => {
                return <RoundScreen
                    additionnal={props.fight.type === EFightType.ADDITIONAL}
                    {...round}
                    key={round.id}
                    active={round.id === props.fight.currentRoundId}
                    config={props.config}
                    competition={props.competition} />
            })
        }
    </>
}
