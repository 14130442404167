import ReorderIcon from '@mui/icons-material/Reorder';
import { RowSelectionState } from '@tanstack/table-core';
import produce from 'immer';
import { MRT_ColumnDef, MRT_Updater, MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { ICompetitionTableData, getCompetitionGroupList } from '../Util/CompetitionOperations';

var debug = require('debug')('ServiceTablesMinimalFrontend:configuration')


interface IUseCompetitionTableSelection {

    competitionGroupList: Array<IApiCompetitionModel>,
    selection: Array<IApiCompetitionModel>

}



const calculSelectionBaseOnGroup = (
    competitionGroupList: Array<ICompetitionTableData>,
    initSelection: Array<number>) => {
    return competitionGroupList
        .reduce((a, v, i) =>
            produce(a, (draft) => {
                if (initSelection.includes(v.id)) {
                    draft[`${i}`] = true;
                }
                for (const [i2, c] of v.competitions?.entries() ?? []) {
                    if (initSelection.includes(c.id)) {
                        draft[`${i}.${i2}`] = true;
                    }
                }
                /*{ ...a, 
                    [`${v.parentId ? v.parentId + '.' : ''
                
                }${i.toString()}`]: true })*/
            })
            , {} as { [key: string]: boolean })
}


export type RowSelectionTableState = {
    selections: Record<string, boolean>,
    newSelection: boolean
}

export function useCompetitionTable(pselection: {
    competitionList: IApiCompetitionModel[]
    , initSelection: number[]
}): [JSX.Element, IUseCompetitionTableSelection | undefined] {



    const [competitionGroupList, setCompetitionGroupList] = useState(getCompetitionGroupList(pselection.competitionList));
    const [previousSelection, setPreviousSelection] = useState({});
    //const [newSelection, setNewSelection] = useState<boolean>(false);
    const [rowSelection, setRowSelection] = useState<RowSelectionTableState>({
        selections: calculSelectionBaseOnGroup(
            competitionGroupList,
            pselection.initSelection
        ),
        newSelection: false
    }
    );
    const [selection, setSelection] = useState<IUseCompetitionTableSelection | undefined>(undefined);

    const columns = useMemo<MRT_ColumnDef<ICompetitionTableData>[]>(() => {
        return [
            {
                accessorKey: 'id', header: 'id',

                //, width: 100, sortComparator: childrenSortComparator('id')
            },
            {
                accessorKey: 'type', header: 'type'
                /*, minWidth: 150, editable: true
                , sortComparator: childrenSortComparator('type')
                , renderCell: (c) => {
                    const competitionRow = c.row;
                    const competition = competitionConfiguration.selection.selectedCompetition.find(c => c.id === competitionRow.id);
                    const isAdvancedDefined = competition && (!!competition.advancedTables?.configurationDone);
                    if (competition !== undefined) {
                        return <>
                            {competition['type']}
                            <Tooltip title={t('action.configuration.configurationRun')}>
                                <SettingsIcon onClick={() => {
     
                                    if (competition) {
                                        //Configuration has been seen once
                                        if (!competition.advancedTables?.configurationDone) {
                                            competitionEdit.updateCompetitionTableAdvanced(competition.id, "configurationDone", "true");
                                        }
                                        competitionConfiguration.showCompetitionConfiguration(competition.id)
                                    }
                                }}
                                    color={isAdvancedDefined ? "primary" : "warning"}
                                />
                            </Tooltip>
                            {(!isAdvancedDefined) &&
                                <Tooltip title={t('action.configuration.configurationRunTodo')}>
                                    <WarningIcon color="warning" />
                                </Tooltip>
                            }
                        </>
     
                    }
                    else {
                        return <Button onClick={() => {
                            selectCompetition(competitionRow.id, true);
                        }}>{competitionRow['type']}</Button>
                    }
     
                }*/
            },
            {
                accessorKey: 'fullname', header: 'fullname'
                /*, minWidth: 200, editable: true,
            renderCell: (params: GridRenderCellParams) => {
                if (params.row.parentId) {
                    return <><Remove />{params.value}</>
                }
                else if (params.row.type === "group") {
                    if (competitionFilter.selectedGroup === params.row.id) {
                        return <><ArrowDropDown />{params.value}</>
                    }
                    else {
                        return <><ArrowRight />{params.value}</>
    
                    }
                }
                else {
                    return <>{params.value}</>
                }
            }
            , sortComparator: childrenSortComparator('fullname')*/
            },
            {
                accessorKey: 'category', header: 'category'/*, minWidth: 150, editable: true
            , sortComparator: childrenSortComparator('category')
            , renderCell: (params: GridRenderCellParams) => {
                const competitionRow = params.row;
                return <>{params.value} - {competitionRow['teamSize'] > 1 ? 'team' : 'indiv'}</>
            }*/
            },
            {
                accessorKey: 'martialArt', header: 'martialArt',
                /*minWidth: 150,
                sortComparator: childrenSortComparator('martialArt')*/
            },
            {
                accessorKey: 'place', header: 'place',
                /*minWidth: 150, editable: true*/
            },
            {
                accessorKey: 'status', header: 'status'/*, minWidth: 150, editable: true,
            renderCell: (c: any) => {
                const competition = c.row;
                return <>{t(`label.competition.status.${competition.status.toLowerCase()}`)}</>
                // {t(`label.competition.status.${competition.status.toLowerCase()}`)}
            }
            , sortComparator: childrenSortComparator('status')*/
            },
            {
                accessorKey: 'competitionDate', header: 'competitionDate'/*, minWidth: 150, editable: true
            , type: 'date'
            , sortComparator: childrenSortComparator('competitionDate')*/
            }
        ]
    }, []);



    //reload group
    useEffect(() => {
        setCompetitionGroupList(getCompetitionGroupList(pselection.competitionList))
    }, [pselection.competitionList])

    /** extend row selection when item of group is selected */
    useEffect(() => {
        const getCompetitionFromIndex = (tableIndex: string) => {
            let c: undefined | ICompetitionTableData = undefined;
            let l: undefined | ICompetitionTableData[] = competitionGroupList ?? []
            for (const i of (tableIndex as string).split(".").map(i => parseInt(i))) {
                if (l) {
                    c = l[i];
                    l = c?.competitions;
                }
                else {
                    c = undefined;
                }
            }
            return c;
        }

        let newRowSelection: undefined | RowSelectionTableState = undefined;
        const previousIndexes = Object.keys(previousSelection);
        const rowIndexes: Array<string> = Object.keys(rowSelection);
        if (rowIndexes.length === 0) {
            setRowSelection({
                selections: {},
                newSelection: false
            });
        }
        else {
            //Parcours des index selectionnés
            for (const index of rowIndexes as Array<string>) {
                //Si il n'était pas selectionné jusqu'ici
                if (!previousIndexes.includes(index)) {
                    let c = getCompetitionFromIndex(index);
                    //there is a parent group
                    if (c?.parentId) {
                        //if the parent group hasn't been selected, select it
                        let pindex = competitionGroupList.findIndex(c2 => c && c2.id === c.parentId);
                        if (pindex >= 0 && !rowIndexes.includes(pindex.toString())) {
                            if (!newRowSelection) newRowSelection = { ...rowSelection };
                            newRowSelection.selections[pindex] = true;
                        }
                    }
                }
            }
            //if we changed row selection, apply it
            if (newRowSelection) {
                setRowSelection(newRowSelection);
                setPreviousSelection(newRowSelection);
            }
        }
    }, [competitionGroupList, previousSelection, rowSelection]);

    /**
     * Update rows selection if init or group change
     */
    useEffect(() => {
        const newRowSelection = calculSelectionBaseOnGroup(
            competitionGroupList,
            pselection.initSelection
        );


        setRowSelection((r) => {
            if (JSON.stringify(r.selections) !== JSON.stringify(newRowSelection)) {
                return {
                    selections: newRowSelection as Record<string, boolean>, 
                    newSelection: false
                } as RowSelectionTableState;
            }
            else {
                return r;
            }
        })



    }, [competitionGroupList, pselection.initSelection])





    const triggerRowSelection = (selectionfct: MRT_Updater<RowSelectionState>) => {
        setRowSelection((s) => {
            return {
                selections: typeof selectionfct === 'function' ? selectionfct(s.selections) : selectionfct,
                newSelection: true
            };
        });
    }


    useEffect(() => {

        if (rowSelection && rowSelection.newSelection) {
            setRowSelection({
                selections: rowSelection.selections,
                newSelection: false
            });
            const competitionKeys = Object.keys(rowSelection.selections)
            const newSelection = {
                competitionGroupList: competitionGroupList.reduce((acc, group, i) => {
                    return acc.concat([group], group.competitions ?? [])
                }, [] as Array<IApiCompetitionModel>),
                selection: competitionGroupList
                    .reduce((acc, group, i) => {
                        return acc.concat(
                            competitionKeys.includes(i.toString()) ? [group] : [], //is in existing competitions, add group
                            (group.competitions?.filter(
                                (c, i2) => {
                                    return Object.keys(rowSelection.selections).includes(`${i.toString()}.${i2.toString()}`)
                                        && competitionKeys.includes(`${i.toString()}.${i2.toString()}`);
                                }
                            ) ?? [])
                        )
                    }, [] as Array<ICompetitionTableData>)
                    .reduce((acc, competition, i, origin) => {
                        if (competition.parentId && !origin.find(c => c.id === competition.parentId)) {
                            const parent = competitionGroupList.find(c => c.id === competition.parentId);
                            if (parent) acc = acc.concat([parent])
                        }
                        return acc.concat([competition]);
                    }, [] as Array<ICompetitionTableData>)
            }

            setSelection(newSelection);
        }
        else {
            setSelection(undefined)
        }
    }, [competitionGroupList, rowSelection])

    return [<>
        {/* Selection : {Object.keys(rowSelection).join(',')}<br /> */}
        <MaterialReactTable
            columns={columns}
            data={competitionGroupList ?? []}
            enableRowSelection //enable some features
            enableColumnOrdering
            //enableGlobalFilter={false} //turn off a feature
            enableExpanding
            getSubRows={(originalRow) => originalRow.competitions}
            paginateExpandedRows={false}
            filterFromLeafRows
            onRowSelectionChange={triggerRowSelection}
            state={{rowSelection:rowSelection.selections}}//{rowSelection.selections }
            initialState={{ expanded: true }} //expand all rows by default
            icons={{
                ExpandMoreIcon: (props: any) => {
                    return <ReorderIcon {...props} color={
                        props.style.transform === "rotate(-90deg)" ? undefined : "primary"
                    } />
                },
            }}
        />
    </>, selection]

}