import { Box, Modal, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { fgNameLabel, IApiFightGroupModel } from '../../util/ApiModel/ApiFightgroupModel';
import { IApiShiajoModel, TApiShiajosModel } from '../../util/ApiModel/ApiShiajoModel';
import { loadShiajos, updateShiajo } from '../Operation/ShiajoOperation';
import { fightGroupStatusLabel } from "../Table/FightGroup";
import { useErrorMessage } from '../Util/ErrorMessage';
import { FightGroupScreen } from './FightGroupScreen';
import { FightGroupSummaryScreen } from './FightGroupSummaryScreen';

import { AddCircleOutline, Flag, RemoveCircleOutline } from '@mui/icons-material';
import { getFollowingFightGroup, TSvgParam } from '../../util/ApiFollowing';
import { EFightStatus, IApiFightModel } from '../../util/ApiModel/ApiFightModel';
import { IApiCompetitionTreeModel } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { TreeGroupSvg } from '../Tree/TreeGroupSvg';
import { getConfigurationSelection } from '../Util/Configuration';
import { useSseSecureClient } from '../Util/SseSecureClient';
import { useShiajoScreenBroadcastEvent } from './ShiajoScreenBroadcastEvent';


var QRCode = require('qrcode.react');
var debug = require('debug')('ServiceTablesMinimalFrontend:ShiajoScreen')

export interface IShiajoScreenProps {
    fullscreen: boolean;
    shiaijoId?: number | undefined;
    eventMode: "SSE"// | "OFFLINE"
}

export interface IShiajoScreenConfig {
    whiteLeft: boolean,
    countryFlag: boolean,
    screenWidthScale: number
}



export function ShiajoScreenFightHeader(props: {
    screenConfig: IShiajoScreenConfig,
    shiajoSelected: IApiShiajoModel,
    currentFightGroup: IApiFightGroupModel | undefined,
    followFightGroup: IApiCompetitionTreeModel | undefined


}) {
    const { t, i18n } = useTranslation();
    const vstitle = (props.currentFightGroup === undefined ? ''
        : (props.screenConfig.whiteLeft ? `${props.currentFightGroup.currentFight?.whiteFightTeam.name} 
        ${t("label.word.versus")} ${props.currentFightGroup.currentFight?.redFightTeam.name}`
            : `${props.currentFightGroup.currentFight?.redFightTeam.name} ${t("label.word.versus")} ${props.currentFightGroup.currentFight?.whiteFightTeam.name}`));
    return <>
        {props.screenConfig.whiteLeft ? <th colSpan={2} className='team-white'>{t('label.word.white')}</th> : <th colSpan={2} className='team-red'>{t('label.word.red')}</th>}
        < th className={'shiaijoScreenTitle'} > {props.shiajoSelected.shortname}</th >
        <th className={'shiaijoScreenTitleDescription'}>

            {props.currentFightGroup && props.currentFightGroup.label ? `${t('label.fightgroup.' + fgNameLabel(props.currentFightGroup.label).label)} ${fgNameLabel(props.currentFightGroup.label).pos} - ` : ""}
            {props.currentFightGroup && <>{t(fightGroupStatusLabel(props.currentFightGroup?.status))}</>}
            <br />
            {(props.currentFightGroup?.currentFight) && <>{vstitle}</>}
            {props.followFightGroup && props.followFightGroup.nextFightGroupNodeIdColored.length === 1
            && <p className='shiaijoScreenTitleDescription-small'>{t("label.fightgroup.winnercolor")} : {t("label.word."+props.followFightGroup.nextFightGroupNodeIdColored[0].color)}</p>}
        </th>
        <th className={'shiaijoScreenTitle'}>{props.shiajoSelected.shortname}</th>
        {props.screenConfig.whiteLeft ? <th colSpan={2} className='team-red'>{t('label.word.red')}</th> : <th colSpan={2} className='team-white'>{t('label.word.white')}</th>}
    </>
}

export function ShiaijoScreenNextFights(props: {
    screenConfig: IShiajoScreenConfig,
    fightGroupsNotCommit: Array<IApiFightGroupModel> | undefined,
    currentFightGroup: IApiFightGroupModel | undefined,
}) {
    const { t, i18n } = useTranslation();
    return <tr className="shiajo-information">
        <td colSpan={1}>{t("label.fightgroup.next")}</td>
        <td colSpan={6} className={"teams"}>
            {props.fightGroupsNotCommit?.filter((fg) => (!props.currentFightGroup) || fg.id !== props.currentFightGroup.id).map((fg, key) => {
                return <FightGroupSummaryScreen key={fg.id} fg={fg} config={props.screenConfig} />
            })}
        </td>
    </tr>
}

const modalMessageStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export function ShiajoScreen(props: IShiajoScreenProps) {

    //sessionStorage.setItem("module", "screen");
    const { t, i18n } = useTranslation();
    const [shiajos, setShiajos] = useState<TApiShiajosModel | undefined>(undefined);
    const [currentFightGroup, setCurrentFightgroup] = useState<IApiFightGroupModel | undefined>(undefined); //selected fightgroup
    const [currentFollowingFG, setCurrentFollowingFG] = useState<IApiCompetitionTreeModel | undefined>(undefined); //selected fightgroup
    const [fightGroupsNotCommit, setFightGroupsNotCommit] = useState<Array<IApiFightGroupModel> | undefined>(undefined); //list of fightgroup on table

    const [dispatchNotification, errorComponent] = useErrorMessage(false);
    const [shiajoSelected, setShiaijoSelected] = useState<IApiShiajoModel | undefined>(undefined);



    const onMessage = useCallback((shiaijo: IApiShiajoModel) => {
        setCurrentFightgroup(shiaijo.currentFightGroup ? shiaijo.currentFightGroup : undefined);
        setFightGroupsNotCommit(shiaijo.fightGroups ? shiaijo.fightGroups.sort((f1, f2) => f1.fightOrder - f2.fightOrder) : undefined);
        if (shiajoSelected) setShiaijoSelected(shiaijo)
    }, [shiajoSelected]);
    const [eventMode, setEventMode] = useState<"SSE" | "OFFLINE">("SSE");

    const [setSseFilter, sseConnected, connectedIcon, sseFilter] = useSseSecureClient(
        "shiaijoScreen",
        "shiajos",
        onMessage,
        dispatchNotification,
        eventMode === "SSE"
    )

    const channel = useShiajoScreenBroadcastEvent<IApiShiajoModel>({
        shiajoId: shiajoSelected?.id ?? 'OFFLINE',
        onMessage: onMessage
    });



    /*const [screenConfig, setScreenConfig] = useState<IShiajoScreenConfig>({
        whiteLeft: true,
        countryFlag: false
    });*/
    const screenConfig = useMemo(() => {
        if (shiajoSelected) {
            return {
                countryFlag: shiajoSelected.countryFlag,
                whiteLeft: shiajoSelected.whiteLeft,
                screenWidthScale: shiajoSelected.screenWidthScale > 0 ? shiajoSelected.screenWidthScale : 100

            } as IShiajoScreenConfig
        }
        else {
            return {

            } as IShiajoScreenConfig
        }
    }, [shiajoSelected])

    /*useEffect(() => {
        switch (props.eventMode) {
            case 'OFFLINE':
                setEventMode("OFFLINE");
                break;
            case 'SSE':
            default:
                setEventMode("SSE");
        }
    }, [props.eventMode])*/

    useEffect(() => {
        if (props.shiaijoId) {
            setShiaijoSelected(shiajos?.find(s => s.externalId === props.shiaijoId));
        }
    }, [props.shiaijoId, shiajos])

    useEffect(() => {
        let isDisplayed = true;
        if (currentFightGroup) {
            if (currentFightGroup.fights.length === 1) {
                getFollowingFightGroup(currentFightGroup.fightGroupNodeId, dispatchNotification).then(fg => {
                    if (isDisplayed) setCurrentFollowingFG(fg);
                });
            }
        }
        else {
            setCurrentFollowingFG(undefined);
        }

        return () => {
            isDisplayed = false;
        }
    }, [currentFightGroup, dispatchNotification])

    /**
     * Load shiaijos
     */
    useEffect(() => {
        if (shiajos === undefined) {
            const list = getConfigurationSelection()?.selectedCompetition.map(id => ({ id: id })) ?? [];
            if (list.length > 0) {
                loadShiajos(list, dispatchNotification)
                    .then(apiData => {
                        if (apiData.succeed) {
                            setShiajos(apiData.data);
                        }
                    });
            }
            else {
                setShiajos(undefined);
            }
        }
    }, [shiajos, dispatchNotification]);



    /**
     * No doc if shiaijo is selected
     */
    useEffect(() => {
        if (shiajoSelected === undefined && sseFilter !== undefined) {
            setSseFilter(undefined);

        }
        else {
            if (shiajoSelected) {
                setSseFilter(shiajoSelected.id.toString())
            }

        }
        return () => {
            //setSseFilter(undefined);

        }
    }, [setSseFilter, shiajoSelected, sseFilter])




    let shiajoListContent = null;

    if (shiajos) {
        shiajoListContent = <>
            <Button onClick={() => {
                setShiaijoSelected(undefined);
            }}>Competition</Button>
            {shiajos.map((shiajo: IApiShiajoModel, key) => {
                return <Button key={key} onClick={() => {
                    setShiaijoSelected(shiajo);
                }}>{shiajo.fullname}</Button>;
            })
            }
        </>
    }

    let fightGroupElement = null;
    let waitingMessage = null;
    /**Button to change red position */
    ;



    const currentRows: number = currentFightGroup?.currentFight?.status !== EFightStatus.WAIT ?
        (currentFightGroup?.currentFight?.rounds?.length ?? (currentFightGroup?.fights?.length ?? 0))
        : (currentFightGroup?.fights?.length ?? 0); //number of rows of the current fight
    const nextFightGroupNotCommitNotCurrent = (fightGroupsNotCommit?.filter(fg => {
        return (currentFightGroup === undefined || fg.id !== currentFightGroup.id) && getConfigurationSelection()
            ?.selectedCompetition.find(c => c === fg.competitionId)
    }) ?? []).sort((a, b) => {
        if (a.competitionId !== b.competitionId) {
            return a.competitionId - b.competitionId
        }
        else if (a.depth !== b.depth) {
            return (b.depth ?? 99) - (a.depth ?? 99);
        } else {
            const pos = /[0-9]+/.exec(a.label);
            const pos2 = /[0-9]+/.exec(b.label);
            return (pos ? parseInt(pos[0]) : 0) - (pos2 ? parseInt(pos2[0]) : 0);
        }
    });
    let nextFightGroupForSlice = 0;
    let lineCountForSlice = -1;
    //increase lineCountForSlice until current fight rows add to fights of the next group is more tha 6
    while (
        (lineCountForSlice + currentRows) < 6
        && (nextFightGroupForSlice ) < (nextFightGroupNotCommitNotCurrent.length ?? 0)) {
        lineCountForSlice += nextFightGroupNotCommitNotCurrent[nextFightGroupForSlice].fights.length;
        if ((lineCountForSlice + currentRows) < 6) {
            nextFightGroupForSlice++;
        }
    }
    const isNextFightgroups = nextFightGroupNotCommitNotCurrent.length > 0;

    const onParamChanged = useCallback((params: TSvgParam) => {
        localStorage.setItem("shiaijoScreenParams", JSON.stringify(params));
    }, []);

    const params: TSvgParam = localStorage.getItem("shiaijoScreenParams") ? JSON.parse(localStorage.getItem("shiaijoScreenParams") ?? "") : {
        displayType: 2,
        displayLevel: true,
        countryFlag: false,
        displayIppons: true,
        displayPools: true,
        resizeHeight: true
    };

    //Si il y a un combat actif ou des combats à venir
    if (shiajoSelected) {
        const shiaijoTableHeader = <><colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="50%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
        </colgroup>
            {/* Header fight in progress */}
            <thead>
                <tr>
                    <ShiajoScreenFightHeader
                        currentFightGroup={currentFightGroup}
                        followFightGroup={currentFollowingFG}
                        screenConfig={screenConfig}
                        shiajoSelected={shiajoSelected}
                    />
                </tr>

            </thead>
        </>;
        if (shiajoSelected.displayMode === "welcome") {
            fightGroupElement = <Col md={12} className='shiaijo-screen-welcome'>
                <h1>Shiaijo {shiajoSelected.shortname}</h1>
                <h2>{t("label.screen.welcomeTitle")}</h2>
                <p>{t("label.screen.welcomeMesssage")}</p>
            </Col>;
        }
        else if (shiajoSelected.displayMode === "poolsTree") {
            fightGroupElement = <Col md={12} className='shiaijo-screen-welcome'>
                <h2>Shiaijo {shiajoSelected.shortname}</h2>
                <TreeGroupSvg fullscreen={props.fullscreen}
                    onParamChanged={onParamChanged}
                    params={params}
                    status={"Ongoing"}
                />
            </Col>;
        }
        else if (shiajoSelected.displayMode === "closing") {
            fightGroupElement = <Col md={12} className='shiaijo-screen-welcome'>
                <h1>Shiaijo {shiajoSelected.shortname}</h1>
                <h2>{t("label.screen.closingTitle")}</h2>
                <p>{t("label.screen.closingMesssage")}</p>

            </Col>;
        }
        else if (shiajoSelected.displayMode === "noFightEmpty"
            || (currentFightGroup
                || !(nextFightGroupNotCommitNotCurrent === undefined
                    || nextFightGroupNotCommitNotCurrent?.length === 0))) {
            fightGroupElement =
                <><Col md={12}>
                    <Table striped bordered hover className={'shiajo-screen-' + screenConfig.screenWidthScale + ' text-center'} >
                        {shiaijoTableHeader}
                        <tbody>
                            {/* Current fight group */}
                            {currentFightGroup && <FightGroupScreen fg={currentFightGroup} config={screenConfig} active={true} />}
                            {//Next fights
                                isNextFightgroups && <>
                                    {nextFightGroupForSlice > 0 && <>
                                        <tr className="shiajo-information">
                                            <td colSpan={7}>{t("label.fightgroup.beReady")}</td>
                                        </tr>
                                        {nextFightGroupNotCommitNotCurrent.slice(0, nextFightGroupForSlice).map(fg => {
                                            //Prevent currentFight displaying
                                            return <FightGroupScreen
                                                key={fg.id}
                                                fg={{
                                                    ...fg,
                                                    currentFightId: 0,
                                                    currentFight: undefined,
                                                    fights: fg.fights.map(f => {
                                                        return {
                                                            ...f,
                                                            rounds: undefined
                                                        } as IApiFightModel
                                                    })
                                                }}
                                                config={screenConfig}
                                                active={false} />
                                        })}
                                    </>}

                                    <ShiaijoScreenNextFights
                                        currentFightGroup={currentFightGroup}
                                        fightGroupsNotCommit={nextFightGroupNotCommitNotCurrent.slice(nextFightGroupForSlice)}
                                        screenConfig={screenConfig}
                                    />
                                </>
                            }
                        </tbody>

                    </Table>
                </Col >

                </>
        }
        else {
            fightGroupElement = <Col md={12}>
                <Table striped bordered hover className='shiajo-screen'>
                    {shiaijoTableHeader}

                    <tbody>
                        <tr className="shiajo-information">
                            <td colSpan={7}>{t('label.shiajo.noCurrentFightGroup')}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>;
            waitingMessage = <>

                <TreeGroupSvg fullscreen={props.fullscreen}
                    onParamChanged={onParamChanged}
                    params={params}
                    status={"Ongoing"}
                />
            </>
        }
    } else {
        waitingMessage = <>

            <TreeGroupSvg fullscreen={props.fullscreen}
                onParamChanged={onParamChanged}
                params={params}
                status={"Ongoing"}
            />
        </>
    }

    let content = null;



    content = <>
        {shiajoSelected && connectedIcon}
        {(shiajoSelected?.displayMessage?.length ?? 0) > 0 && <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalMessageStyle}>
                <Typography id="modal-modal-description" variant="h2" component="h2">
                    {shiajoSelected?.displayMessage}
                </Typography>
            </Box>
        </Modal>}
        {!props.fullscreen && <Row>
            {errorComponent}
        </Row>}
        {!props.fullscreen && <Row>
            <Col md={6}>
                <Alert variant="secondary">
                    {t("info.screen.actionToGoFullScreen")}
                    <Button onClick={() => document.documentElement.requestFullscreen()}>
                        {t("action.screen.actionToGoFullScreen")}
                    </Button>
                </Alert>
            </Col>
            {shiajoSelected && <Col md={4} >
                <SwapHorizIcon sx={{ fontSize: 80 }} onClick={() => {

                    if (shiajoSelected) updateShiajo(shiajoSelected.externalId, {
                        whiteLeft: !screenConfig.whiteLeft
                    }, dispatchNotification).then(s => {
                        setShiaijoSelected(s);
                    })

                }} />
                <Tooltip title="Screen size">

                    <div style={{
                        display: 'inline-block'
                    }}>
                        <RemoveCircleOutline onClick={() => {
                            if (shiajoSelected) updateShiajo(shiajoSelected.externalId, {
                                screenWidthScale: screenConfig.screenWidthScale - 10
                            }, dispatchNotification).then(s => {
                                setShiaijoSelected(s);
                            })
                        }} />
                        {screenConfig.screenWidthScale ?? 100}
                        <AddCircleOutline onClick={() => {
                            if (shiajoSelected) updateShiajo(shiajoSelected.externalId, {
                                screenWidthScale: screenConfig.screenWidthScale + 10
                            }, dispatchNotification).then(s => {
                                setShiaijoSelected(s);
                            })
                        }} /></div>
                </Tooltip>
                <Tooltip title="Display country">
                    <Flag sx={{ fontSize: 80 }} color={screenConfig.countryFlag ? 'primary' : 'disabled'} onClick={() => {
                        if (shiajoSelected) updateShiajo(shiajoSelected.externalId, {
                            countryFlag: !screenConfig.countryFlag
                        }, dispatchNotification).then(s => {
                            setShiaijoSelected(s);
                        })

                    }} />
                </Tooltip>
            </Col>}
        </Row>
        }
        {
            !props.fullscreen && <Row>
                {shiajoListContent}
            </Row>
        }
        <Row>
            {fightGroupElement}
        </Row>
    </>

    if (channel) {

        channel.onmessage = (event) => {
            alert(event);
        }
    }



    return <>

        <Container fluid>
            {content}
        </Container >
        {waitingMessage}
        {/*<ShiajoScreenCompetitionInfo dispatchNotification={dispatchNotification} display={shiajoSelected === undefined || isNextFightgroups === false} />*/}
    </>;
}