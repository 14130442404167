import React, { useEffect, useState } from 'react';

import CableIcon from '@mui/icons-material/Cable';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { getApiObject } from '../Operation/GenericOperation';
import { IServiceTableNotification } from './ErrorMessage';

const debug = require('debug')('ServiceTablesMinimalFrontend:SseSecureClient')

export interface ISSEState {
    eventSource: EventSource | undefined,


}
//
//
export type TuseSseSecureClient = [(filter: string | undefined | null) => void, boolean, JSX.Element, string | undefined | null];

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

const connectSse = (filter: string | null, domain: string, token: string,
    onOpen: (eventSource: EventSource) => void,
    onDisconnect: () => void
) => {
    if (filter !== undefined) {
        const pathRoot = (window as any)['REACT_APP_API_SSE'].replace("-sse.", `-sse-${getRandomInt(10)}.`);

        //connect to the event source
        const eventSource = new EventSource(pathRoot + `/sse/${token}/${domain}${filter ? `?id=${filter}` : ''}`, {
            withCredentials: false
        });

        //on open message
        eventSource.onopen = () => {
            console.log('connected');
            onOpen(eventSource);
        };

        //on message
        eventSource.onmessage = e => {
            console.warn("Untreated sse message :" + JSON.parse(e.data));
            debug(e.data);
            /*
            
            ;*/
        }

        //on error
        eventSource.onerror = function (e) {
            console.log("Connection error");
            //if (eventSource.readyState === EventSource.CONNECTING) {
            onDisconnect();

            //}
        }
        return eventSource;
    }
    return undefined;
}

interface ISseConnection {
    connected: boolean,
    eventSource: EventSource | undefined,
    filter: string | undefined | null,
    token: string | undefined,
    lastTry: Date | undefined,
}

const tokenCheckFrequency = 10000;
const tokenReconnectFrequency = 10000;

export function useSseSecureClient<T>(
    context: string,
    domain: "FightGroups" | "shiajos" | "competitions",
    onMessage: (data: T) => void,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    enabled: boolean
): TuseSseSecureClient {

    const [filter, setFilter] = useState<string | undefined | null>(undefined);
    const [sseConnection, setSseConnection] = useState<ISseConnection>({
        connected: false,
        eventSource: undefined,
        filter: undefined,
        token: undefined,
        lastTry: undefined,
    });
    //const [sseTokenTimeout, setSseTokenTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
    const [sseTimeout, setSseTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (sseConnection.eventSource) {
            sseConnection.eventSource.onmessage = (e) => onMessage(JSON.parse(e.data) as T);
        }
    }, [onMessage, sseConnection.eventSource]);

    /*Token reload if token change or newtry*/
    useEffect(() => {
        let e: EventSource | undefined = undefined;
        let mounted = true;

        if (enabled) {

            (async () => {
                let token = sseConnection.token;

                if (token === undefined) {
                    console.log(`token is undefined, get anew token`);
                    try {
                        const tokenResult = await getApiObject<{
                            data: {
                                token: string
                            } | undefined,
                            succeed: boolean
                        }>("/ssetoken", null, { data: undefined, succeed: false }, dispatchNotification);

                        if (mounted) {
                            if (tokenResult.succeed && tokenResult.data) {
                                token = tokenResult.data.token;
                            } else {
                                console.debug("Failed to get a token, next try in 10 sec");
                                /*dispatchNotification({
                                    operation: "read",
                                    type: "error",
                                    message: "error.shiajo.ssefailed",
                                    context: e
                                });*/
                                setTimeout(() => {
                                    if (mounted) setSseConnection((s) => {
                                        return {
                                            ...s,
                                            token: undefined,
                                            lastTry: new Date()
                                        }
                                    });
                                }, tokenReconnectFrequency)

                            }
                        }//end mount check
                    }
                    catch (e) {
                        console.debug("Error while getting a token, next try in 5 sec");
                        setTimeout(() => {
                            if (mounted) setSseConnection((s) => {
                                return {
                                    ...s,
                                    token: undefined,
                                    lastTry: new Date()
                                }
                            });
                        }, tokenReconnectFrequency);
                    }
                }
                if (token !== undefined) {
                    //CHECK TOKEN TIMEOUT
                    console.log(`Token exist, configure check token timeout`);
                    setTimeout(() => {
                        if (sseConnection.filter) {
                            if (mounted) {
                                console.log(`Checking token`);
                                getApiObject<{
                                    succeed: boolean,
                                    data: Array<string>
                                }>(`/sse/${token}/check`, null, {
                                    succeed: false,
                                    data: []
                                }, dispatchNotification, /*(window as any)['REACT_APP_API_SSE']
                        , undefined, undefined, undefined, false*/).then(r => {
                                    if (mounted) {
                                        if (r.succeed) {
                                            if (!r.data.includes(domain.toLowerCase())) {
                                                console.log(`Token valid, but doesn't include domain ${domain.toLowerCase()}, reset token`);
                                                setSseConnection((s) => {
                                                    return {
                                                        ...s,
                                                        token: undefined,
                                                        lastTry: new Date()
                                                    }
                                                })
                                            }
                                            else {
                                                console.log(`Token valid, set lastTry to trigger a new check`);
                                                setSseConnection((s) => {
                                                    return {
                                                        ...s,
                                                        lastTry: new Date()
                                                    }
                                                })
                                            }
                                        }
                                        else {
                                            console.log(`Token check failed`);
                                            setSseConnection((s) => {
                                                return {
                                                    ...s,
                                                    token: undefined,
                                                    lastTry: new Date()
                                                }
                                            })
                                        }
                                    }
                                    else {
                                        console.log(`Token not valid, reset token`);
                                        setSseConnection((s) => {
                                            return {
                                                ...s,
                                                token: undefined,
                                                lastTry: new Date()
                                            }
                                        })
                                    }
                                }).catch((e) => {
                                    console.log(`Not mounted, reset token`);
                                    setSseConnection((s) => {
                                        return {
                                            ...s,
                                            token: undefined,
                                            lastTry: new Date()
                                        }
                                    });
                                })
                                    .finally(() => {
                                        //TODO : new timestamp
                                    })
                            }
                        }
                        else {
                            //not mounted
                            console.log(`SSE not Mounted`);
                            // setSseConnection((s) => {
                            //     return {
                            //         ...s,
                            //         token: undefined,
                            //         lastTry: new Date()
                            //     }
                            // })
                        }
                    }, tokenCheckFrequency);
                }
                if (token !== undefined) {
                    console.debug("Token exist, check sse connection")
                    //USE TOKEN
                    //OPEN CONNECTION

                    //si on a pas de filtre, on ne se connecte pas
                    if (sseConnection.filter === undefined) {
                        console.debug("No filter set, not connecting to sse");
                    } else {

                        //si on est pas connect
                        if (sseConnection.eventSource === undefined || sseConnection.eventSource?.readyState === 2) {
                            if (mounted) {
                                console.log("SSE connection doesn't exist, create it")

                                e = connectSse(sseConnection.filter ?? null, domain, token ?? '',
                                    (e) => {
                                        console.debug(`Sse connection established for ${sseConnection.filter}.${domain}`);
                                    },
                                    () => {
                                        console.debug(`Sse connection error for ${sseConnection.filter}.${domain}, Reconnect in 5 secs`);
                                        if (sseConnection.eventSource) sseConnection.eventSource?.close();
                                        if (mounted) setSseConnection(s => {
                                            return {
                                                ...s,
                                                token: undefined,
                                                lastTry: new Date(),
                                                connected: false
                                            }
                                        });
                                    });
                                setSseConnection(s => {
                                    return {
                                        ...s,
                                        eventSource: e,
                                        token: token,
                                        lastTry: undefined,
                                        connected: true
                                    }
                                });
                            }
                        }
                        else if (token === undefined) {
                            if (mounted) {
                                console.debug(`Not connected and no token, closing existing sse connection`);
                                //on est pas connecté et on a pas de token
                                //on ferme le sses s'il est actif
                                if (sseConnection.eventSource) sseConnection.eventSource?.close()
                                setSseConnection(s => {
                                    return {
                                        ...s,
                                        connected: false,
                                        eventSource: undefined
                                    }
                                });
                            }
                        }
                    }
                }
            }
            )();
        }
        else {
            if (sseConnection.connected) {
                console.log(`sseSession disabled, clearing sseConnection`);
                if (sseConnection.eventSource) sseConnection.eventSource?.close()
                setSseConnection(s => {
                    return {
                        ...s,
                        connected: false,
                        eventSource: undefined
                    }
                });
            }
        }
        return () => {
            //if (sseConnection.eventSource?.readyState === 1) sseConnection.eventSource?.close()
            mounted = false;
        }
    }, [dispatchNotification, domain, sseConnection.connected, sseConnection.eventSource, sseConnection.filter, sseConnection.token, sseConnection.lastTry, enabled]);

    //Connection check
    useEffect(() => {
        if (sseConnection.filter !== undefined && domain.length > 0) {
            console.debug(`SSE client configured for ${context}.${domain}.${sseConnection.filter}`)
        }
    }, [context, domain, sseConnection.filter])

    //Connection filter update
    useEffect(() => {
        if (sseConnection.filter !== filter) {
            console.log(`Filter changed from ${sseConnection.filter} to ${filter}, reset sseConnection`);
            if (sseConnection.eventSource) sseConnection.eventSource?.close()
            setSseConnection((s) => {
                return {
                    ...s,
                    connected: false,
                    eventSource: undefined,
                    filter: filter
                }
            })
        }
    }, [filter, sseConnection])

    //Connection clearing when token is undefined
    useEffect(() => {
        let t: undefined | NodeJS.Timeout = undefined;
        if (sseConnection.token === undefined && sseConnection.connected) {
            console.log(`sseSession token became undefined, clearing sseConnection`);
            if (sseConnection.eventSource) sseConnection.eventSource?.close()
            setSseConnection(s => {
                return {
                    ...s,
                    connected: false,
                    eventSource: undefined
                }
            });
        }

        t = setInterval(() => {
            if (sseConnection.token && sseConnection.eventSource && sseConnection.connected) {
                if (sseConnection.eventSource.readyState === sseConnection.eventSource.CLOSED) {
                    console.log(`sseSession closed, clearing sseConnection`);
                    setSseConnection(s => {
                        return {
                            ...s,
                            token: undefined,
                            connected: false,
                            eventSource: undefined
                        }
                    });
                }
            }
        }, 5000);
        return () => {
            if (t) clearTimeout(t);
            t = undefined;
        }
    }, [sseConnection.connected, sseConnection.eventSource, sseConnection.token]);



const connectedIcon: JSX.Element = <>
    {sseConnection.connected && <CableIcon style={{
        position: "fixed",
        right: 0,
        top: 0,
        backgroundColor: 'white'
    }} />}
    {(!sseConnection.connected) && <ReportProblemIcon style={{
        position: "fixed",
        right: 0,
        top: 0,
        backgroundColor: 'white'
    }} />}
</>

return [
    setFilter,
    sseConnection.connected,
    connectedIcon,
    filter
]
}