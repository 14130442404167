import { deleteApiObject, postApiFormData, postApiObject } from "../components/Operation/GenericOperation";
import { IServiceTableNotification } from "../components/Util/ErrorMessage";
import { IApiDrawingFighters, IApiDrawingPoule, IApiDrawingRepartitions, IApiDrawingTableauForSuivi, IApiImportRow, IApiReadTirage, IDrawCreateParams } from "../Model/ApiDrawingModel";
import { getCompApiObject, putCompApiObject } from "../UtilEvent/GenericOperation";



export async function readTirage(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IApiReadTirage | undefined> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiReadTirage | undefined
    }>("/readTirage", competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data ? {
            ...result.data,
            valide: parseInt(result.data?.valide?.toString() ?? "0")
        } : undefined;
    });
}

export async function getFightsOrder(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IApiReadTirage | undefined> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiReadTirage | undefined
    }>("/getFightsOrder", competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}



export async function valideTirage(dispatchNotification: React.Dispatch<IServiceTableNotification>, tirageId: number, host?: string, token?: string): Promise<any | undefined> {
    return putCompApiObject<{
        succeed: boolean,
        data: IApiReadTirage | undefined
    }>("/valideTirage", tirageId, undefined, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}

export async function invalideTirage(dispatchNotification: React.Dispatch<IServiceTableNotification>, tirageId: number, host?: string, token?: string): Promise<any | undefined> {
    return putCompApiObject<{
        succeed: boolean,
        data: IApiReadTirage | undefined
    }>("/invalideTirage", tirageId, undefined, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}



/**
 * @Deprecated
 * @param dispatchNotification 
 * @param competitionId 
 * @param host 
 * @param token 
 * @returns 
 */
export async function getTableauForSuivi(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IApiDrawingTableauForSuivi | undefined> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiDrawingTableauForSuivi | undefined
    }>("/getTableauForSuivi", competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}

//
export async function getRepartition(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IApiDrawingRepartitions | undefined> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiDrawingRepartitions | undefined
    }>("/readRepartition", competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}


export async function getPouleForUI(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IApiDrawingPoule | undefined> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiDrawingPoule | undefined
    }>("/getPouleForUI", competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}

export async function listFightersCompetition(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IApiDrawingFighters | undefined> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiDrawingFighters | undefined
    }>("/competitionFighters", competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}

export async function postCreateDraw(dispatchNotification: React.Dispatch<IServiceTableNotification>, params: IDrawCreateParams, host?: string, token?: string): Promise<IApiDrawingFighters | undefined> {
    return postApiObject<{
        succeed: boolean,
        status: number,
        data: IApiDrawingFighters | undefined
    }>("/createTirage", params, {
        succeed: false,
        status: 500,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}

export async function postRefaireTirage(dispatchNotification: React.Dispatch<IServiceTableNotification>, tirageId: number, host?: string, token?: string): Promise<IApiDrawingFighters | undefined> {
    return postApiObject<{
        succeed: boolean,
        status: number,
        data: IApiDrawingFighters | undefined
    }>("/refaireTirage/" + tirageId, undefined, {
        succeed: false,
        status: 500,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}



export async function deleteTirage(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IApiDrawingFighters | undefined> {
    return deleteApiObject<{
        succeed: boolean,
        status: number,
        data: IApiDrawingFighters | undefined
    }>("/deleteTirage", competitionId, {
        succeed: false,
        status: 500,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}


export async function importTirage(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, rows: Array<IApiImportRow>, host?: string, token?: string): Promise<IApiDrawingRepartitions | undefined> {
    const data = {
        idcomp: competitionId,
        fichier: rows.map(r => {
            return [
                r.combattant //0 - fighterId
                , 0 //1 - name
                , 0 //2 - club
                , 0 //3 - nationnality
                , r.numero //4 - num
                , r.position //5 - position
            ];
        })
    }
    return postApiObject<{
        succeed: boolean,
        status: number,
        data: IApiDrawingRepartitions | undefined
    }>("/importTirage", data, {
        succeed: false,
        status: 500,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], token).then((result) => {
        return result.data
    });
}


export async function postDrawFile(dispatchNotification: React.Dispatch<IServiceTableNotification>, file: File, host?: string, token?: string, timeout?: number) {
    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("file", file);
    formData.append("id", file.name);

    return postApiFormData("/simpleTirage", formData, {
        succeed: false,
        data: "",
        filename: ""
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], undefined, token, timeout).then((result) => {
        return {data: result.data, filename: result.filename}
    });
}

