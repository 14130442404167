import React, { useCallback, useEffect, useState } from 'react';
import { loadShiajos } from './components/Operation/ShiajoOperation';
import { Shiajo } from "./components/Table/Shiajo";
import { useErrorMessage } from './components/Util/ErrorMessage';
import './css/App.scss';

import { Alert, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IShiajoSelection, ShiajoTabs } from './components/Shiaijo/ShiajoTabs';
import { useSelectedCompetitions } from './components/Use/UseSelectCompetitions';
import { getConfigurationSelection } from './components/Util/Configuration';


var debug = require('debug')('ServiceTablesMinimalFrontend:ServiceTable')

export interface IServiceTableProps {
  fullscreen: boolean
}




export interface IApiClientError {
  message: string | null,
  error: Error | null
}

export type TLoading = "loaded" | "loading" | "unload" | "error";

export interface IApiClientState {
  loading: TLoading;
}



export interface IServiceTableState {
  data: null | IShiajoSelection,
  succeed?: boolean,
  loading: TLoading
}


/**
 * A fight
 */
export function ScoreKeeperScreen(props: IServiceTableProps) {

  //sessionStorage.setItem("module", "table");
  const { t, i18n } = useTranslation();
  const [dispatchNotification, errorComponent] = useErrorMessage();

  const [tableState, setTableState] = useState<IServiceTableState>({
    data: null,
    loading: "unload"
  });
  const [selectedCompetitionsComponent, selectedCompetitions] = useSelectedCompetitions({ dispatchNotification: dispatchNotification, size: 'small', label: "" });


  let shiajoListHeader = null;

  const selectShiaijo = (shiaijoId: number | undefined) => {
    let newState = { ...tableState };
    debug("Shiajo selected : " + shiaijoId);
    if (newState.data === null) {
      dispatchNotification({
        operation: "read",
        type: "error", message: "info.shiajo.notloaded"
      });
    }
    else {
      dispatchNotification({
        operation: "read",
        type: "success", message: "info.shiajo.loaded"
      });
      localStorage.setItem("shiajoSelected", String(shiaijoId ?? ''));
      newState.data.shiajoSelected = (shiaijoId ? shiaijoId : null);
      setTableState(newState);
    }
  }

  const loadShiaijos = useCallback(async (displayed: boolean) => {
    let newState = { ...tableState };
    if (newState.loading === "unload") {
      const selection = getConfigurationSelection();
      if (selection && selection.selectedCompetition.length > 0) {

        const apiData = await loadShiajos(selection.selectedCompetition.map(c => ({ id: c })), dispatchNotification);
        if (apiData.succeed) {
          dispatchNotification({
            operation: "read",
            type: "success", message: "info.shiajo.loaded"
          });
          //debug("Shiajos Loaded : " + JSON.stringify(apiData));
        }

        const defaultSelect = localStorage.getItem("shiajoSelected");
        let shiajoSelected = defaultSelect ? apiData.data.find(s => String(s.externalId) === defaultSelect) : undefined;
        let shiajoSelectedId = shiajoSelected?.externalId ?? null;


        newState.data = {
          shiajos: apiData.data,
          shiajoSelected: shiajoSelectedId,
        };
        newState.succeed = apiData.succeed;
        if (newState.succeed) {
          newState.loading = 'loaded';
        }
        else {
          newState.loading = 'error';
        }
      } else {
        newState.data = {
          shiajos: [],
          shiajoSelected: null,
        };
        newState.succeed = true;
        newState.loading = 'loaded';
      }
      if (displayed) {
        setTableState(newState);
      }
    }
  }, [dispatchNotification, tableState]);

  /**
   * Loading state
   */
  useEffect(() => {
    let displayed = true;
    debug("Rendering");
    loadShiaijos(displayed)
    return () => { (displayed = false) }
  }, [loadShiaijos]);


  const isShiaijoSelected: boolean = !(tableState.data === null || tableState.data?.shiajoSelected === null);

  //Display shiaijo status
  if (tableState.loading === "error") {
    shiajoListHeader = <Grid item xs={8}>Erreur de chargement</Grid>;
  }
  else if (tableState.loading === "loading") {
    shiajoListHeader = <Grid item xs={8}>Chargement…</Grid>;
  } else if (!tableState.data) {
    shiajoListHeader = <Grid item xs={8}>No data loaded</Grid>;
  } else {

    //debug("List of shiajos : " + JSON.stringify(state.data.shiajos) + ", selected : " + state.data.shiajoSelected);
    //Build shiaijo content
    shiajoListHeader = <>
      {/*Display to select a shiaijo */}
      {isShiaijoSelected === false && <Grid item xs={12}>
        <>{t(`label.shiajo.selectShiaijo`)}</>
      </Grid>}

      <Grid item xs={2} style={{ color: '#FFF', padding: '12pt' }}>
        Shiaijos
      </Grid>
      <Grid item xs={10}>
        {/* List of shiaijos tabs */}
        <ShiajoTabs
          selectShiaijo={selectShiaijo}
          shiajosSelection={tableState.data}
        />
      </Grid>
    </>


  }

  return (
    <>

      <Grid container spacing={0}>
        {/* Error component */}
        <Grid item xs={12}>
          {errorComponent}
        </Grid>
        {/* Error First header */}
        <Grid container xs={12} style={{ backgroundColor: '#27348B' }}>
          <Grid item xs={4}>
            {!props.fullscreen && <p style={{ color: 'white' }}>{t("info.screen.actionToGoFullScreen")}</p>} {selectedCompetitionsComponent}
          </Grid>
          {/* Shiaijo header tabs */}
          <Grid container item xs={8}>
            {!props.fullscreen && shiajoListHeader}
          </Grid>
        </Grid>
        {/* Shiaijo selected div*/}
        {isShiaijoSelected && tableState.data &&
          <Grid container item xs={12}>
            <Shiajo
              fullscreen={props.fullscreen}
              competitions={selectedCompetitions}
              dispatchNotification={dispatchNotification}
              externalId={tableState.data.shiajoSelected}
            />
          </Grid>
        }
        {/* Footer */}
        {props.fullscreen && <Grid item xs={4}>
          <Alert color="info">
            {t("info.screen.actionToGoOutFullScreen")}
          </Alert>
        </Grid>
        }
      </Grid>
    </>
  );
}

export default ScoreKeeperScreen;
