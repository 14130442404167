import { Alert, Box, Button, Link, Paper } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { postDrawFile } from "../../Backend/ApiDrawing";
import { IAuthentication } from "../Auth/auth";
import { IServiceTableNotification } from "../Util/ErrorMessage";

export function DrawingExcel(props: { dispatchNotification: React.Dispatch<IServiceTableNotification>, authentication: IAuthentication, login: () => void }) {
    const downloadRef = useRef<null | HTMLAnchorElement>(null);
    const [selectedFile, setSelectedFile] = useState<null | File>(null);
    const [downloadFile, setDownloadFile] = useState<{ data: string, filename: string } | undefined>(undefined);
    const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
    const { t, i18n } = useTranslation();
    const inputRef = useRef(null);
    useEffect(() => {
        if (downloadRef && downloadFile) {
            downloadRef.current?.click();
        }
    }, [downloadRef, downloadFile]);

    return <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
            m: 1,
            width: '90%',
            height: '100%',
        },
    }}>
        {props.authentication.message && <Paper elevation={1}>
            <Alert color="error">
                {props.authentication.message}
            </Alert>
        </Paper>}
        <Paper elevation={1}>
            <h2 className="title">Créer un tirage excel pour ma compétition</h2>
            {props.authentication.authenticated !== true && <Alert color='warning'>
                Vous devez être authentifiés pour utiliser la generation de tirage.
                <br /><a title={t('label.title.authenticate')} href="#" onClick={() => props.login()}>
                    <i className="fas fa-sign-in-alt"></i><span className="nav-item-name">&nbsp;Se connecter</span>
                </a>
            </Alert>}
            {props.authentication.authenticated && <>
                <p>Vous pouvez génerer un tirage à partir de ce modèle : <Link href={`${(window as any)['REACT_APP_API_DRAW_URL']}/simpleTirage`}>Modèle</Link></p>
                <p>Vous devez renseigner les pages setting et combattants (seul le nom des combattants est obligatoire), puis renvoyer le fichier via le lien ci dessous</p>
                <p>Le fichier vous sera retourné avec le tirage généré, ainsi que les poules et tableaux pour dérouler votre compétition</p>
                <p>Pour les compétitions par équipe, vous aurez aussi besoin des feuilles de combat par équipe :
                    <Link href={`file/Feuille_combat_equipes.xls`}>Feuille combat equipes</Link></p>
                <Paper elevation={4}>
                    <h2 className="subtitle"><u>Generation du tirage</u></h2>
                    <form>
                        <input
                            ref={inputRef}
                            accept=".xlsx"
                            type="file"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedFile(e.target.files ? e.target.files[0] : null)}
                        />
                    </form><br />
                    <Button variant="contained" disabled={!selectedFile} onClick={() => {
                        setLoadingMessage("Envoi du fichier en cours...");
                        if (selectedFile) postDrawFile(props.dispatchNotification, selectedFile, undefined, undefined, 120000).then(url => {
                            if (url.data.length > 0) {
                                setDownloadFile(url);
                                setLoadingMessage("Le fichier a été généré et est disponible en téléchargement");
                            } else {
                                setLoadingMessage("Erreur lors de la generation du fichier");
                            }
                        }).catch(() => {
                            setLoadingMessage("Erreur lors de l'envoi du fichier");
                        }).finally(() => {
                            if (inputRef && inputRef.current) (inputRef.current as any).value = null;
                        });
                    }
                    }>Envoyer le fichier</Button>
                    {downloadFile && <a ref={downloadRef}
                        href={downloadFile.data}
                        download={downloadFile.filename.length > 0 ? downloadFile.filename : "competition_draw.xlsx"} onClick={() => setDownloadFile(undefined)}>Download</a>}
                    {loadingMessage && <p>{loadingMessage}</p>}

                </Paper>
            </>}
        </Paper>
    </Box >
}

{/*file/Modele_tirage_simple.xlsx*/ }