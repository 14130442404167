import { jwtDecode } from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
export type TProfile = "none" | "commissaire" | "admin";

enum E_SSO_LEVEL {
  SSO_LEVEL_USER = 1,
  SSO_LEVEL_COACH = 2,
  SSO_LEVEL_REFEREE = 3,
  SSO_LEVEL_SCOREKEEPER = 4,
  SSO_LEVEL_ORGANIZER = 5,
  SSO_LEVEL_ADMIN = 6,
  SSO_LEVEL_SUPERADMIN = 7,
}

export let SSO_SCOPES: Map<string, number> = new Map();
SSO_SCOPES.set("cnkdr_user", 1);
SSO_SCOPES.set("cnkdr_coach", 2);
SSO_SCOPES.set("cnkdr_referee", 3);
SSO_SCOPES.set("cnkdr_scorekeeper", 4);
SSO_SCOPES.set("cnkdr_organizer", 5);
SSO_SCOPES.set("cnkdr_admin", 6);
SSO_SCOPES.set("cnkdr_superadmin", 7);

export interface IAuthentication {
  authenticated: boolean;
  loaded: boolean;
  scopes: Array<string>;
  name: '';
  message:undefined | string
}

export function useAuthentication(props: { t: (message: string) => string }): [IAuthentication, () => void, () => void] {
  const [authentication, setAuthentication] = useState<IAuthentication>({
    authenticated: false,
    loaded: false,
    scopes: [],
    name: '',
    message: undefined
  });
  const { t, i18n } = useTranslation();

  const loadUser: () => Promise<void> = useMemo(() => {
    return async () => {
      //@ts-ignore
      let user = await cnkdrSsoLoadUser();
      let message: undefined | string = undefined;
      if (user) {
        try {
          const idToken = user.id_token;


          const decoded = jwtDecode(idToken);
          if (((decoded.iat  ?? 0) * 1000) > Date.now()) {
            console.error('Authentication JWT date error, iat before current date !');
            message = props.t("error.auth.date");
            user = undefined;
          }
          if (decoded.exp && ((decoded.exp ?? 0) * 1000)  < Date.now()) {
            console.error('Authentication JWT date error, exp before current date !');
            message = props.t("error.auth.date");
            user = undefined;
            logOut();
          }
        }
        catch (e) {
          console.error(e);
          user = e;
        }
      }

      if (user) {
        //@ts-ignore
        cnkdrOnAccessTokenExpiring(() => {
          setAuthentication({
            authenticated: false,
            loaded: false,
            scopes: [],
            name: '',
            message: message
          });
        });
        //@ts-ignore
        let userScopes: Array<string> = user.profile.scope; //await cnkdrSsoGetScopes();
        let calculatedScope: Array<string> = [];
        for (let userScope of userScopes) {
          if (SSO_SCOPES.has(userScope)) {
            for (let scope of Array.from(SSO_SCOPES.keys())) {
              if (calculatedScope.find((s) => s === scope) === undefined) {
                //@ts-ignore
                if (SSO_SCOPES.get(userScope) >= SSO_SCOPES.get(scope)) {
                  calculatedScope.push(scope);
                }
              }
            }
          }
        }

        setAuthentication({
          authenticated: true,
          loaded: true,
          //@ts-ignore
          scopes: calculatedScope,
          name: user.profile.name,
          message: message
        });
      } else {
        setAuthentication({
          authenticated: false,
          loaded: true,
          //@ts-ignore
          scopes: [],
          name: '',
          message: message
        });
      }
    };
  }
    , [props]);

  useEffect(() => {
    if (!authentication.loaded) {
      loadUser();
    }
  }, [authentication.loaded, loadUser, props.t]);

  let logIn = () => {
    //@ts-ignore
    cnkdrSsoLogIn();
  };

  let logOut = () => {
    //@ts-ignore
    cnkdrSsoLogOut().then(
      setAuthentication({
        authenticated: false,
        loaded: true,
        //@ts-ignore
        scopes: [],
        name: '',
        message: "Logout"
      })
    );
  };

  return [authentication, logIn, logOut];
}
