import { SwapVert } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogContent, TextField } from '@mui/material';
import React, { useRef } from "react";
import { useTranslation } from 'react-i18next';
import { renameFightersFighter } from '../../Backend/ApiFighter';
import { IApiFighterModel } from "../../util/ApiModel/ApiFighterModel";
import { IFightTeamState } from '../../util/ApiModel/ApiFightModel';
import { getApiObject } from '../Operation/GenericOperation';
import { IFightTeamStateApiResult } from '../Table/Fight';
import { emptyFightTeamState } from '../Team/FightTeam';
import { useErrorMessage } from '../Util/ErrorMessage';
import { FighterLabel } from "./FighterLabel";

export function FighterEditeable(props: {
    fighter: IApiFighterModel | undefined,
    id?: boolean,

    onEdit: (fighter: IFightTeamState) => void
}) {
    const { t } = useTranslation();
    const [editMode, setEditMode] = React.useState(false);
    const refFirstname = useRef(null);
    const refLastname = useRef(null);
    const [dispatchNotification, errorComponent] = useErrorMessage(false);

    const handleClose = () => {
        setEditMode(false);

    };

    return <>
        {editMode && props.fighter && <>
            <Dialog onClose={handleClose} open={editMode}>
                <DialogContent>
                    {errorComponent}<br />
                    {t('label.fighter.firstname')} <TextField inputRef={refFirstname}
                        defaultValue={props.fighter?.firstName} /><br />
                    <SwapVert onClick={()=> {
                        const firstname = (refFirstname.current as any)?.value;
                        (refFirstname.current as any)!.value = (refLastname.current as any)?.value;
                        (refLastname.current as any)!.value = firstname;
                    }}/>
                    {t('label.fighter.lastname')} <TextField inputRef={refLastname}
                        defaultValue={props.fighter?.lastName} /><br />

                    <Button variant='contained' color='success' onClick={() => {
                        renameFightersFighter(props.fighter!.externalId
                            , {
                                firstname: (refFirstname.current as any)?.value ?? props.fighter!.firstName
                                , lastname: (refLastname.current as any)?.value ?? props.fighter!.lastName
                            }
                            , dispatchNotification).then((res) => {
                                if (res) {
                                    if (props.onEdit) {
                                        getApiObject<IFightTeamStateApiResult>("/FightTeam",
                                            res.team, { succeed: false, data: emptyFightTeamState }
                                            , dispatchNotification).then((res) => {
                                                if (res.succeed && res.data) {
                                                    props.onEdit(res.data);
                                                    setEditMode(false);
                                                };
                                            })

                                    }

                                }

                            })


                    }}>{t("label.word.save")}</Button>
                    <Button variant='contained' color='error' onClick={() => {
                        setEditMode(false);
                    }}>{t("label.word.cancel")}</Button>

                </DialogContent>
            </Dialog>

        </>}
        <FighterLabel {...props} />
        {props.fighter?.id &&
            < EditIcon onClick={() => setEditMode(true)} />
        }
    </>
}
