import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Alert, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Link, Paper, Tooltip } from '@mui/material';
import produce from 'immer';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCompetitions } from '../../Backend/ApiCompetition';
import { IApiCompetitionModel, TCompetitionStatus } from '../../Model/ApiCompetitionModel';
import { useCompetitionModal } from '../Competition/CompetitionModal';
import { getConfigurationSelection, storeSelection } from '../Util/Configuration';
import { IServiceTableNotification } from '../Util/ErrorMessage';

export function useSelectedCompetitions(props: {
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    //showCompetitionConfiguration?: (id: number) => void,
    selectionName?: string,
    init?: Array<number>,
    label?: string,
    maxSelect?: number,
    status?: TCompetitionStatus,
    size?: 'small' | 'normal',
    listlink?: boolean,
}): [JSX.Element, Array<IApiCompetitionModel>, string] {

    //List des ids de competition selectionnés pour cette page
    const [selectedCompetitions, setSelectedCompetitions] = useState<Array<number>>(
        (getConfigurationSelection(props.selectionName, true)?.selectedCompetition ?? (props.init ?? []))
    );
    const [extended, setExtended] = useState<boolean>(false);

    const { t, i18n } = useTranslation();

    //liste des compétitions disponibles chargées
    const [loadedAllowedCompetitions, setLoadedAllowedCompetitions] = useState<{
        selectList: Array<number>,
        loaded: Array<IApiCompetitionModel>,
        isLoaded: boolean
    }>({
        selectList: [],
        loaded: [],
        isLoaded: false
    });

    //la modale pour les informations
    const [modal, setCompetitionModalId] = useCompetitionModal(props.dispatchNotification);

    //chargement de la liste des competitions disponibles
    useEffect(() => {
        let display = true;
        const allowedCompetitionsList = getConfigurationSelection()?.selectedCompetition;
        if ([...loadedAllowedCompetitions.selectList].sort().join(';') !== [...allowedCompetitionsList ?? []].sort().join(';'))
            getCompetitions(props.dispatchNotification, false, {
                competitionIds: allowedCompetitionsList,
                withClosed: true,
                type: 'competitionANDgroup'
            }).then(d => {
                if (display) setLoadedAllowedCompetitions({
                    selectList: allowedCompetitionsList ?? [],
                    loaded: d,
                    isLoaded: true
                });
                if ([...d].map(c => c.id).sort().join(';') !== [...allowedCompetitionsList ?? []].sort().join(';')) {
                    console.error(`Loading incomplete : got ${[...d].map(c => c.id).join(',')} for selected ${[...allowedCompetitionsList ?? []].sort().join(';')}`)
                }
            })
        return () => { display = false }
    }, [loadedAllowedCompetitions, props.dispatchNotification])

    //Refresh selectedCompetitions if needed
    useEffect(() => {
        if (loadedAllowedCompetitions.isLoaded) {
            const allowedSelectionFiltered = loadedAllowedCompetitions.loaded.filter(c => c.type === "competition").map(c => c.id);
            const unselected = selectedCompetitions.filter(s => allowedSelectionFiltered.find(s2 => s2 === s) === undefined); //elements selectionnés mais que ne fond pas parti de ceux autorisés

            if (props.maxSelect && selectedCompetitions.length > props.maxSelect) {
                setSelectedCompetitions(selectedCompetitions.slice(0, 6));
            }
            else if (props.selectionName && unselected.length > 0) {
                //veritifer que les element selectionnés font bien parti de la selection globale, les enlever sinon
                setSelectedCompetitions(selectedCompetitions.filter(s => allowedSelectionFiltered.find(s2 => s2 === s)));
            }
            else if (getConfigurationSelection(props.selectionName, true)?.selectedCompetition === undefined
                || selectedCompetitions.join() !== getConfigurationSelection(props.selectionName)?.selectedCompetition.join()) {
                //si il y a une différence entre la liste des compétitions sélectionnées et la liste stockée en cache
                //on met a jour le cache
                storeSelection({
                    selectedCompetition: selectedCompetitions.filter((v, i, a) => a.indexOf(v) === i),
                }, props.selectionName);
            }
        }
    }, [loadedAllowedCompetitions, props.maxSelect, props.selectionName, selectedCompetitions]);


    //liste des competitions autorisées à l'affichage (ongoing)
    const allowedSelection = loadedAllowedCompetitions.loaded.filter(c => /*props.status === undefined || c.status === props.status*/
        c.type === 'competition');

    const filtered = useMemo(() => {
        return loadedAllowedCompetitions.loaded.filter(c => selectedCompetitions.includes(c.id))
    }, [loadedAllowedCompetitions.loaded, selectedCompetitions]);


    const element = <>
        {modal}
        {/* {selectedCompetitions.join(',')} */}
        <Paper elevation={2}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" margin='none' className="hatanado-select-competition">
                <FormLabel component="legend">
                    {props.label ?? t(`label.competition.selectedCompetitions`)}{props.maxSelect ? `( ${t(`label.competition.maxSelectedCompetitions`)} ${props.maxSelect} )` : ""}
                    {props.label && <>&nbsp;:&nbsp;</>}
                    {props.listlink !== false && <Link href="/competitions">Competitions selectionnées : {allowedSelection.length}</Link>}
                    {!extended && <><ExpandMore onClick={() => setExtended(true)}/></>}
                    {extended && <ExpandLess onClick={() => setExtended(false)}/>}
                </FormLabel>
                <FormGroup row>
                    {allowedSelection.length === 0 && <Alert color='error'>{t(`label.competition.noSelection`)}</Alert>}
                    {allowedSelection.length > 0 && <>
                        
                        {extended && allowedSelection.map((c) => {
                            const checked: boolean = selectedCompetitions.find(s => s === c.id) !== undefined;
                            if (props.size === 'small') {
                                return <React.Fragment key={c.id}>
                                    <Tooltip title={c.fullname}>
                                        <div>&nbsp;{c.id} {c.fullname} ; </div>
                                    </Tooltip>
                                </React.Fragment>
                            }
                            else {
                                return <React.Fragment key={c.id}><FormControlLabel
                                    control={
                                        <Checkbox key={c.id}
                                            checked={checked}
                                            onChange={() => {
                                                const newSelect = produce(selectedCompetitions, (draft) => {
                                                    if (draft.find(c2 => c2 === c.id)) {
                                                        draft.splice(draft.findIndex(c2 => c2 === c.id), 1);
                                                    }
                                                    else {
                                                        draft.push(c.id);
                                                    }
                                                    if (props.maxSelect) draft.slice(0, props.maxSelect);
                                                }).filter((v, i, a) => a.indexOf(v) === i);
                                                storeSelection({
                                                    selectedCompetition: newSelect,
                                                }, props.selectionName)

                                                setSelectedCompetitions(newSelect,);
                                            }} name={c.toString()} />
                                    }
                                    label={c.fullname}

                                />
                                    {<p>
                                        <SettingsIcon
                                            fontSize="small"
                                            style={{ verticalAlign: "bottom" }}
                                            onClick={() => {
                                                setCompetitionModalId(c?.id)
                                            }}
                                            color="primary"
                                        />
                                    </p>}
                                </React.Fragment>
                            }

                        })}
                    </>}
                </FormGroup>
                {/* <FormHelperText>Selected competitions</FormHelperText> */}
            </FormControl>
        </Paper>
    </>;
    return [element, filtered, selectedCompetitions.join()];
}