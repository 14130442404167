import { Button, ButtonGroup, Dialog, Grid, TextField } from '@mui/material';
import produce from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { LangageContext } from '../../App';
import { now } from 'moment';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiClientState } from '../../ScoreKeeperScreen';
import { ERoundEventTypeModel } from '../../util/ApiModel/ApiEventModel';
import { IApiFightGroupResult } from '../../util/ApiModel/ApiFightgroupModel';
import { emptyShiajoResult, IApiShiajoModel, IApiShiajoResult } from '../../util/ApiModel/ApiShiajoModel';
import { putApiObject } from '../Operation/GenericOperation';
import { loadShiajoNoCommit, selectFightGroup } from '../Operation/ShiajoOperation';
import { ShiaijoFightGroupsHeader, ShiaijoFightGroupsHeaderFG } from '../Shiaijo/ShiaijoFightGroupsHeader';
import { useChronometer } from '../Util/Chronometer';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { LoadingIndicator } from '../Util/LoadingIndicator';
import { Requirement } from '../Util/Requirements';
import { FightGroup } from './FightGroup';

var debug = require('debug')('ServiceTablesMinimalFrontend:Shiajo')

const REFRESH_TIMEOUT = 30000;

/*
export interface ShiajoState extends IApiClient {

  name: string;
  currentFightGroupId: number | null;
  id: number;
  fightGroups: Array<{
    id: number;
  }>;
}*/

export interface ShiajoProps {
  externalId: number | null,
  dispatchNotification: React.Dispatch<IServiceTableNotification>,
  fullscreen: boolean,
  competitions: Array<IApiCompetitionModel>
}

export enum EFighterColor {
  white = "white",
  red = "red"
}

export interface IActionEvent {
  fighterColor: EFighterColor | null;
  type: ERoundEventTypeModel;
}

export interface IServiceShiajoReducerAction {
  type: "LOAD" | "SELECT_FIGHTGROUP" | "CHRONOMETER_INIT" | "CLEAR",
  id?: number
}

export interface IServiceShiajoState extends IApiClientState {
  shiajo: IApiShiajoModel,
  timestamp: number
}



/**
 * A fight
 */
export function Shiajo(props: ShiajoProps) {
  const { t, i18n } = useTranslation();
  const [chronoDispatch, chronoState] = useChronometer();
  const [reloadLock, setReloadLock] = useState(false);
  const [shiajoDialogOpen, setShiajoDialogOpen] = useState(false);
  const [state, setState] = useState<IServiceShiajoState>({
    loading: "unload",
    shiajo: emptyShiajoResult.data,
    timestamp: 0
  });

  /**
   * Load Shiaijo without commited fightgroups
   */
  let load = useCallback(async (externalId: number, force: boolean) => {
    let newState: IServiceShiajoState = {
      loading: "unload",
      shiajo: emptyShiajoResult.data,
      timestamp: force ? now() : 0
    };
    if (externalId) {
      let apiData: IApiShiajoResult = await loadShiajoNoCommit(externalId, props.dispatchNotification);
      debug("Shiajo Loaded : " + JSON.stringify(apiData));
      newState.loading = "loaded";
      newState.shiajo = apiData.data;
    }
    setState(newState);
  }, [props.dispatchNotification])

  /**
   * Select Fight Group
   * @param pid 
   */
  let selectShiajoFightGroup = async (pid: number) => {
    let newState = { ...state };
    if (newState.shiajo && pid) {
      let apiData: IApiFightGroupResult = await selectFightGroup(newState.shiajo.externalId, pid, props.dispatchNotification);
      if (apiData.data.id > 0) {
        debug("Shiajo updated : " + JSON.stringify(apiData));
        newState.shiajo.currentFightGroupId = apiData.data.id;
      }
    }
    setState(newState);
  }

  /**
   * TODO : replace with sse
   * Refresh shiaijo
   */
  useEffect(() => {
    debug("UseEffect");
    //LOAD TIMEOUT
    const toutt = setInterval(() => {
      if (props.externalId && !reloadLock) {
        if (state.loading !== "loading") {
          //dispatch({ type: "LOAD" });
          setState({
            loading: "loading",
            shiajo: state.shiajo,
            timestamp: 0
          });
          load(props.externalId, false);
        }
      }
    }, REFRESH_TIMEOUT);
    return () => {
      if (toutt) {
        clearInterval(toutt);
      }
    }
  }, [load, props.externalId, state]);

  /**
   * Chrono stop if not currentFightGroupId
   */
  useEffect(() => {
    if (!state.shiajo.currentFightGroupId && chronoState.startDate !== chronoState.pauseDate) {
      debug("Shiajo effect load");
      chronoDispatch("END");
      //debug("end" + JSON.stringify(state.data));
    }
  }, [chronoDispatch, chronoState.pauseDate, chronoState.startDate, state.shiajo.currentFightGroupId])

  /**
   * Reload on externalId change
   */
  useEffect(() => {
    if (props.externalId) load(props.externalId, false);
    setShiajoDialogOpen(false);
  }, [load, props.externalId])

  const reloadFromFG = useCallback(async (force: boolean) => {
    if (props.externalId) load(props.externalId, force)
  }, [props.externalId, load])


  let shiajoContent = null;
  /** Loading message */
  if (state.loading === "unload") {
    /**If shiaijo is selected */
    if (props.externalId) {
      shiajoContent = <div>Chargement…</div>;
      debug(props.externalId + " " + (state.shiajo ? state.shiajo.id : "undefined"));
    }
    /**Else propose to chose a shiaijo */
    else {
      shiajoContent = <div>{t('label.fight.select')}</div>;
    }
  } else if (!state.shiajo) {
    //LOADE with non data (error)
    shiajoContent = <div>No data</div>;
  }
  else {
    //Loaded with data
    shiajoContent = <>
        <Grid item xs={1} style={{ padding: 0, paddingRight: "3px" }}>
          <ShiaijoFightGroupsHeaderFG
            shiaijo={state.shiajo}
            dispatchNotification={props.dispatchNotification}
            fullscreen={props.fullscreen}
            reload={reloadFromFG}
            setReloadLock={setReloadLock}
            selectShiajoFightGroup={selectShiajoFightGroup}
            state={state}
            competitions={props.competitions}
            showShiajoDialog={() => setShiajoDialogOpen(true)}
          />
        </Grid>
        <Grid item xs={11} style={{ padding: 1 }}>
          {/* List of fightgroup not current for this shiaijo */}
          <ShiaijoFightGroupsHeader
            shiaijo={state.shiajo}
            dispatchNotification={props.dispatchNotification}
            fullscreen={props.fullscreen}
            reload={reloadFromFG}
            setReloadLock={setReloadLock}
            selectShiajoFightGroup={selectShiajoFightGroup}
            state={state}
            competitions={props.competitions}
            showShiajoDialog={() => setShiajoDialogOpen(true)}
          />


          {/* Current fightgroup for this shiajo */}
          {
            state.shiajo.currentFightGroupId && <>
              <FightGroup
                timestamp={state.timestamp}
                shiajoLoad={(force?: boolean) => {
                  if (props.externalId) load(props.externalId, force ?? false)
                }}
                dispatchNotification={props.dispatchNotification}
                dispatchChrono={chronoDispatch}
                chronoState={chronoState}
                fullscreen={props.fullscreen}
                /*dispatchModal={dispatchModal}*/
                id={(state.shiajo.currentFightGroupId ? state.shiajo.currentFightGroupId : 0)} />
            </>
          }
        </Grid>
    </>;
  }

  return <>
    <Dialog open={shiajoDialogOpen} fullWidth={true}>
      <h3 className="title is-3">{t("label.shiaijo.displayedMessage")}</h3>
      <TextField
        label={t("label.shiaijo.message")}
        multiline

        minRows={4}
        onChange={(e) => {
          const newState = produce(state, (draft) => {
            draft.shiajo.displayMessage = e.target.value;
          })
          setState(newState);
        }}
        value={state.shiajo.displayMessage}
      >
      </TextField>
      <ButtonGroup
        orientation='vertical'
      >
        <Button style={{ margin: "0.5em" }} variant='contained' size='small' onClick={() => {
          putApiObject("/Shiajos", state.shiajo.externalId, {
            displayMessage: state.shiajo.displayMessage
          }, { succeed: false }, props.dispatchNotification);
        }}>{t('label.shiaijo.apply')}</Button>
        <Button style={{ margin: "0.5em" }} variant='contained' size='small' onClick={() => {
          putApiObject("/Shiajos", state.shiajo.externalId, {
            displayMessage: ""
          }, { succeed: false }, props.dispatchNotification).then(d => {
            if (d.succeed) {
              const newState = produce(state, (draft) => {
                draft.shiajo.displayMessage = "";
              })
              setState(newState);
            }
          })
        }}>{t('label.word.clear')}</Button>
        <Button style={{ margin: "0.5em" }} variant='contained' size='small' onClick={() => {
          setShiajoDialogOpen(false);
        }}>{t('label.word.close')}</Button>
      </ButtonGroup>
    </Dialog>
    <Grid item xs={12} style={{ padding: 0 }}>
      <Requirement />

      <LoadingIndicator />
    </Grid>
    {shiajoContent}
  </>;
}