import { Checkbox, FormControlLabel, InputLabel, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { addUserGlobalProfile, deleteUserGlobalProfile, getUser, saveUser } from '../../Backend/ApiUser';
import { IApiUserModel } from '../../Model/ApiUserModel';

import { SSO_SCOPES, useAuthentication } from '../../components/Auth/auth';
import { useUserSearch } from '../User/useUserSearch';
import { useErrorMessage } from '../Util/ErrorMessage';
import { RegisterTable } from './registerTable';




export function AdminUsers() {
    //sessionStorage.setItem("module", "user");
    
    const { t, i18n } = useTranslation();
    const [authentication] = useAuthentication({t:t});
    const [dispatchNotification, errorComponent] = useErrorMessage(true);
    const [userSearch, userSearched] = useUserSearch({
        dispatchNotification: dispatchNotification
    });
    const [editUser, setEditUser] = useState<IApiUserModel>({
        name: '',
        email: '',
        password: '',
        profiles: [],
        try: undefined
    });
    const [createUser, setCreateUser] = useState<IApiUserModel>({
        name: '',
        email: '',
        password: '',
        profiles: []
    });

    const isAdmin = authentication.scopes.includes('cnkdr_superadmin');
    const globalProfiles = editUser ? editUser.profiles
        .filter(p => p.competitionId === null)
        .map(p => p.profile)
        .filter((e, i, a) => a.indexOf(e) === i) : [];


    useEffect(() => {
        if (userSearched) {
            setEditUser({ ...userSearched, password: '' });
        }
        else if (isAdmin === false && editUser.name === '' && authentication.name) {
            getUser(authentication.name, dispatchNotification).then(user => {
                if (user) setEditUser(user);
            });
        }
    }, [userSearched, isAdmin, editUser.name, authentication.name, dispatchNotification])



    return <Grid container spacing={1}>
        <Grid item xs={12}>
            {errorComponent}
        </Grid>
        <Grid item xs={4}>
            <h1>User</h1>

            {isAdmin && <>

                <Grid item xs={12}>
                    {t('label.title.existingUser')}
                </Grid>
                <Grid item xs={12}>
                    {userSearch}
                </Grid>
            </>}
            {editUser.name.length > 0 && <>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-simple">Name</InputLabel>
                    <TextField
                        value={editUser.name}
                        onChange={(e) => setEditUser({ ...editUser, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-simple">Email</InputLabel>
                    <TextField
                        value={editUser.email}
                        onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor="component-simple">Password</InputLabel>
                    <TextField
                        type="password"
                        value={editUser.password}
                        onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
                    />
                </Grid>
                {isAdmin && <Grid item xs={12}>
                    <InputLabel htmlFor="component-simple">Try left</InputLabel>
                    <TextField
                        value={editUser.try}
                        onChange={(e) => setEditUser({ ...editUser, try: parseInt(e.target.value) })}
                    />
                </Grid>
                }
                <p>
                <Grid item xs={12}>
                    <Button className="button is-info"
                        onClick={() => {
                            saveUser(editUser, false, dispatchNotification);
                        }}
                    >Save</Button>
                </Grid></p>

                {isAdmin && <Grid item xs={12}>

                    <FormGroup>
                        <InputLabel htmlFor="component-simple">Scopes (real time change)</InputLabel>
                        {[...SSO_SCOPES.keys()].map((scope, key) => {
                            return <FormControlLabel
                                key={key}
                                control={<Checkbox checked={globalProfiles.includes(scope)} />}
                                onChange={(e) => {
                                    //@ts-ignore
                                    if (e.target.checked) {
                                        addUserGlobalProfile(editUser.name, scope, dispatchNotification).then(user => {
                                            if (user) setEditUser(user);
                                        })
                                    }
                                    else {
                                        deleteUserGlobalProfile(editUser.name, scope, dispatchNotification).then(user => {
                                            if (user) setEditUser(user);
                                        })
                                    }
                                }}
                                label={t(`label.profile.${scope}`)} />
                        })
                        }
                    </FormGroup>
                </Grid>}

            </>
            }
            {isAdmin && editUser.name.length === 0 && <>
                <Grid item xs={12}>
                    {t('label.title.createUser')}
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <TextField
                            label="name"
                            value={createUser.name}
                            onChange={(e) => setCreateUser({ ...createUser, name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="email"
                            label="email"
                            value={createUser.email}
                            onChange={(e) => setCreateUser({ ...createUser, email: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="password"
                            label="password"
                            value={createUser.password}
                            onChange={(e) => setCreateUser({ ...createUser, password: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => {
                                saveUser(createUser, true, dispatchNotification).then((user) => {
                                    if (user) {

                                        getUser(user.name, dispatchNotification).then(user => {
                                            if (user) {
                                                setEditUser(user);
                                            }
                                        });
                                        setCreateUser({
                                            name: '',
                                            email: '',
                                            password: '',
                                            profiles: []
                                        });
                                    }
                                });
                            }}
                        >Create</Button>
                    </Grid>
                </Grid>
            </>
            }
        </Grid >
        <Grid item xs={8}>
            <Grid item xs={12}>

                <h1>Registration</h1>
            </Grid>
            <Grid item xs={12}>
                <RegisterTable dispatchNotification={dispatchNotification}/>
            </Grid>

        </Grid>
    </Grid>

}